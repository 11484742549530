import Component from "../component";
import User from "../../models/user/user";

class ReferralLink extends Component {
	constructor() {
        // Buyapowa refer a friend link
        // Will target all 'js-buyapower-refer-link' links on the page
        // and change the link and URL based on logged in state
        
        super();

        this._referralLinks = Array.from(document.querySelectorAll('.js-buyapower-refer-link'));
        
        User.subscribe(
            User.actions.UPDATED_SUCCESSFUL,            
            this.render.bind(this)
        ); 
             
    }
    
    render() {
        this._referralLinks.forEach((btn) => {
            if (User.status === 'soft' || User.status === 'hard') {
                btn.innerHTML = 'Refer a friend';
                btn.href="/jsp/account/common/account_refer_friend.jsp"; 
            }
        });
    }
}

export default ReferralLink;
