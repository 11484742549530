import cellDataToTetrisData from '../../../utilities/contentful/tetrisCellTransformer';
import cellOneByOne from '../cell/cellOneByOne';
import cellOneByTwo from '../cell/cellOneByTwo';
import cellTwoByTwo from '../cell/cellTwoByTwo';
class TetrisLayoutOne {
  constructor(elementId, cellData) {
    this.id = elementId;
    this.data = cellData;
  }

  /**
   * Organises the tetris cells into the correct blocks for this layout
   *
   * @return {String} => The html string which will be placed into the tetris element
   */
  render() {
    const cellCount = this.data.length;
    if(cellCount !== 4) {
      console.warn(`tetrisLayoutOne.js: Incorrect number of cells. Expected 4 but received ${cellCount}`);
    }
    
    const [cellOne, cellTwo, cellThree, cellFour] = this.data.map((cellData) =>
      cellDataToTetrisData(this.id, cellData)
    );
    
    return `
      <div class="col-12 col-md-6 pr-md-2 pb-4 pb-md-0 mb-4 cell-container">
        ${cellTwoByTwo({ ...cellOne })}
      </div>
      <div class="col-12 col-md-6 pt-1 pt-md-0 pl-md-2">
        <div class="row no-gutters h-50 pb-md-2 pb-4 pb-md-0">
          <div class="col-12 cell-container">
            ${cellOneByTwo({ ...cellTwo })}
          </div>
        </div>
        <div class="row no-gutters pt-1 h-50">
          <div class="col-12 col-md-6 pr-md-2 pb-4 pb-md-0 mb-4 cell-container">
            ${cellOneByOne({ ...cellThree })}
          </div>
          <div class="col-12 col-md-6 pl-md-2 mb-4 cell-container">
            ${cellOneByOne({ ...cellFour })}
          </div>
        </div>
      </div>
    `;
  }
}

export default TetrisLayoutOne;
