import Component from '../../component';
import request from '../../../utilities/request';
import { Exception } from 'handlebars';

/**
 * Controls the e-voucher customisation, preview and submission to API.
 * @class  EVoucherView
 */
class EVoucherView extends Component {
    constructor(){
        super('section.e-vouchers');
        //Form validation min/max/regex/error messages in once place      
        this.formValidation = {
            amount: { min: { value: 0, message: "Please enter an amount" }, max: { value: 500, message: "Please enter an amount less than £500" }, whole: { value: true, message: "Please enter a whole number"} },
            name: { min: { value: 0, message: "Please enter a name" }, max: { value: 100, message: "Name provided cannot be longer than 100 characters" } },
            email: { min:  { value: 0, message: "Please enter an email address" }, max: { value: 100, message: "Email cannot be longer than 100 characters" }, regex: { value: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i , message: "Please enter a valid email address" } }, 
            message: { min: { value: 0, message: "Please enter a message" }, max: { value: 200, message: "Message cannot be longer than 200 characters" } }
        };
        //All targets in once place
        this.targets = {
            inputs: {
                amount: {
                    button1: 'input[id="value-1"]',
                    button2: 'input[id="value-2"]',
                    button3: 'input[id="value-3"]',
                    input: 'input[id="custom-value"]'
                },
                name: 'input[id="name"]',
                email: 'input[id="email"]',
                message: 'textarea[id="message"]'
            },
            form: 'form#evouchersform',
            inputAmountUpdate: '.js-e-voucher-price',
            messageCharCount: '.char-count',
            sections: {
                preview: '.e-vouchers-preview',
                form: '.e-vouchers'
            },
            previewFields: {
                email: '.js-email-address',
                name: '.for-name span',
                message: '.js-message',
                amount: '.js-price'
            },
            backButton: '.js-back',
            submitWholeForm: '.js-submit'
        };
        //Values that are populated (default values)
        this.values = [25,50,100];        
        this.setValuesOnFrontend(); //shows default values
        //Form values are input into this group
        this.group = {
            amount: null,
            email: null,
            message: null,
            name: null
        };
        //Form components
        let nameInput = document.querySelector(this.targets.inputs.name),
            emailInput = document.querySelector(this.targets.inputs.email),
            messageInput = document.querySelector(this.targets.inputs.message);
        //-- Events --
        //Amount
        this.addEvent(document.querySelector(this.targets.inputs.amount.button1), 'click', this.watchAmount.bind(this));
        this.addEvent(document.querySelector(this.targets.inputs.amount.button2), 'click', this.watchAmount.bind(this));
        this.addEvent(document.querySelector(this.targets.inputs.amount.button3), 'click', this.watchAmount.bind(this));
        this.addEvent(document.querySelector(this.targets.inputs.amount.input), 'keyup', this.watchAmount.bind(this));
        //Updating group
        this.addEvent(nameInput, 'change', this.watchName.bind(this));
        this.addEvent(emailInput, 'change', this.watchEmail.bind(this));
        this.addEvent(messageInput, 'change', this.watchMessage.bind(this));
        //Submission
        this.addEvent(document.querySelector(this.targets.form), 'submit', this.submitEVoucherForm.bind(this));
        //Char counter
        this.addEvent(document.querySelector(this.targets.inputs.message), 'keyup', this.messageCounter.bind(this));
        //Back button
        Array.from(document.querySelectorAll(this.targets.backButton)).forEach((btn) => {
            this.addEvent(btn, 'click', this.showForm.bind(this));
        });
        //Submit preview
        this.addEvent(document.querySelector(this.targets.submitWholeForm), 'click', this.submit.bind(this));

        //Check in case browser autofill/page state has repopulated the form fields
        if(nameInput.value.length > 0 ) this.group.name = nameInput.value;
        if(emailInput.value.length > 0 ) this.group.email =  emailInput.value;
        if(messageInput.value.length > 0 ) this.group.message =  messageInput.value;
    }
    //Run on load, sets the values (this.values) in the input boxes by default (max 3 values)
    setValuesOnFrontend(){
        //Loops through variable `values` and adds this to inputs for amounts
        for(var i in this.values){
            let n = parseFloat(i) + 1;
            document.querySelector('input[id="value-'+n+'"]').setAttribute('value',this.values[i]);
            document.querySelector('label[for="value-'+n+'"]').innerHTML = '&pound;'+this.values[i];          
        }

        //Set expire date on card
        var d = new Date();
        var year = d.getFullYear();
        var month = d.getMonth();
        var day = d.getDate();
        var newDate = new Date(year + 1, month, day);
        //Sets expiry date on preview step to today + 1 year
        document.querySelector('.js-expire-date').innerHTML = ("0" + (newDate.getDate())).slice(-2) + "/" + ("0" + (newDate.getMonth() + 1)).slice(-2) + "/" + newDate.getFullYear();
    }
    //Runs on change on this.targets.inputs.amount(button1,button2,button3) and keyup of this.targets.inputs.amount.input
    watchAmount(e){
        let value = parseFloat(e.target.value);
        let amountValid = this.validateAmount(value);

        this.setAmountRadios(value);
        this.group.amount = value;

        if(amountValid){
            this.setValueOnCard(value);
        }
        else{
            this.setValueOnCard(null);
        }        
    }
    //Runs on change of this.targets.inputs.email
    watchEmail(e){
        let value = e.target.value;
        this.validateEmail(value);
        this.group.email = value;
    }
    //Runs on change of this.targets.inputs.name
    watchName(e){
        let value = e.target.value;
        this.validateName(value);
        this.group.name = value;
    }
    //Runs on change of this.targets.inputs.message
    watchMessage(e){
        let value = e.target.value;
        this.validateMessage(value);
        this.group.message = value;
    }
    //Will set a value (param) in the gift card image
    setValueOnCard(value){
        let v = value && value !== undefined ? "&pound;"+value : "";
        //Sets value on all gold cards
        Array.from(document.querySelectorAll(this.targets.inputAmountUpdate)).forEach((btn) => {
            btn.innerHTML = v;
        });
    }
    //Updates the counter for message (total value length) 200 is hard coded in html (jsp)
    messageCounter(e){
        let amt = e.target.value.length;
        //Sets the counter amount to the appropriate amount
        document.querySelector(this.targets.messageCharCount).children.item(0).innerHTML = amt;
    }
    //When run the amount (param) is validated, the message showing up is auto handled in here
    validateAmount(val){
        let msg = "";
        if(!val || isNaN(val) || val <= this.formValidation.amount.min.value){
            //Amount is too small
            msg = this.formValidation.amount.min.message;
        }
        else if(val > this.formValidation.amount.max.value){
            //Amount is too large
            msg = this.formValidation.amount.max.message;
        }
        else if(this.formValidation.amount.whole.value == true && !Number.isInteger(val)){
            //not number or wrong format
            msg = this.formValidation.amount.whole.message;
        }
        this.addMessage('.form-error[data-name="value"]', msg, null);
        if(msg !== ""){
            //Error
            document.querySelector(this.targets.inputs.amount.button1).classList.add('input-error');
            document.querySelector(this.targets.inputs.amount.button2).classList.add('input-error');
            document.querySelector(this.targets.inputs.amount.button3).classList.add('input-error');
            document.querySelector(this.targets.inputs.amount.input).classList.add('input-error');
        }
        else{
            //No error
            document.querySelector(this.targets.inputs.amount.button1).classList.remove('input-error');
            document.querySelector(this.targets.inputs.amount.button2).classList.remove('input-error');
            document.querySelector(this.targets.inputs.amount.button3).classList.remove('input-error');
            document.querySelector(this.targets.inputs.amount.input).classList.remove('input-error');
        }
        return msg === "" ? true : false;
    }
    //When run the email (param) is validated, the message showing up is auto handled in here
    validateEmail(val){
        let msg = "";
        if(val == null || val.length <= this.formValidation.email.min.value){
            //Too small
            msg = this.formValidation.email.min.message;
        }
        else if(val.length > this.formValidation.email.max.value){
            //Too large
            msg = this.formValidation.email.max.message;
        }
        else if(this.formValidation.email.regex.value.test(val) == false){
            //Failed regex
            msg = this.formValidation.email.regex.message;
        }
        
        this.addMessage('.form-error[data-name="email"]', msg, this.targets.inputs.email);
        return msg === "" ? true : false;
    }
    //When run the message (param) is validated, the message showing up is auto handled in here
    validateMessage(val){
        let msg = "";
        if(val == null || val.length <= this.formValidation.message.min.value){
            //Too small
            msg = this.formValidation.message.min.message;
        }
        else if(val.length > this.formValidation.message.max.value){
            //Too large
            msg = this.formValidation.message.max.message;
        }
        
        this.addMessage('.form-error[data-name="message"]', msg, this.targets.inputs.message);
        return msg === "" ? true : false;
    }
    //When run the name (param) is validated, the message showing up is auto handled in here
    validateName(val){
        let msg = "";
        if(val == null || val.length <= this.formValidation.name.min.value){
            //Too small
            msg = this.formValidation.name.min.message;
        }
        else if(val.length > this.formValidation.name.max.value){
            //Too large
            msg = this.formValidation.name.max.message;
        }
        
        this.addMessage('.form-error[data-name="name"]', msg, this.targets.inputs.name);
        return msg === "" ? true : false;
    }
    //This shows or hides the error messages.
    addMessage(target, msg, input){
        if(msg !== "" && msg !== undefined){
            document.querySelector(target).innerHTML = msg;
            document.querySelector(target).style.display = "block";
            if(input != null){
                document.querySelector(input).classList.add('input-error');
            }
        }
        else{
            document.querySelector(target).innerHTML = "";
            document.querySelector(target).style.display = "none";
            if(input != null){
                document.querySelector(input).classList.remove('input-error');
            }
        }
    }
    //Sets the radio buttons as active if the intered value (param) matches a button value
    setAmountRadios(inputValue){
        Array.from(document.querySelectorAll('input[name="value"]')).forEach((item) => {
            if(parseFloat(item.value) === inputValue){
                //checked
                item.checked = true;
                //matched a preset amount - clear the textbox of any custom amount
                if(document.querySelector(this.targets.inputs.amount.input).value != inputValue) document.querySelector(this.targets.inputs.amount.input).value = "";
            }
            else{
                //unchecked
                item.checked = false;
            }
        });
    }    
    //Validates the whole form
    validateForm(){
        let amount = this.validateAmount(this.group.amount);
        let email = this.validateEmail(this.group.email);
        let message = this.validateMessage(this.group.message);
        let name = this.validateName(this.group.name);
        if(!amount){
            //focus on amount
            document.querySelector(this.targets.inputs.amount.input).focus();
        }
        else if(!name){
            //focus on name
            document.querySelector(this.targets.inputs.name).focus();
        }
        else if(!email){
            //focus on email
            document.querySelector(this.targets.inputs.email).focus();
        }
        else if(!message){
            //focus on message
            document.querySelector(this.targets.inputs.message).focus();            
        }
        
        return (name && message && email && amount) ? true : false;
    }
    //Runs on form submission 
    submitEVoucherForm(e){
        e.preventDefault();

        let valid = this.validateForm();
        if(valid){
            //takes you to preview stage
            this.showPreview();
        }
    }
    //Shows the preview page and populates dynamic content from this.group
    showPreview(){
        //populates and shows preview, also makes sure youre scrolled to the top
        document.querySelector(this.targets.sections.preview).style.display = 'block';
        document.querySelector(this.targets.sections.form).style.display = 'none';
        document.querySelector(this.targets.previewFields.email).innerHTML = this.group.email;
        document.querySelector(this.targets.previewFields.name).innerHTML = this.group.name;
        document.querySelector(this.targets.previewFields.message).innerHTML = this.group.message.replace(/(?:\r\n|\r|\n)/g, '</p><p>');
        document.querySelector(this.targets.previewFields.amount).innerHTML = "&pound;" + this.group.amount;
        window.scroll({
            left: 0,
            top: document.querySelector('.top')
        });
    }
    //Shows the form page, removing the dynamic content in the preview page
    showForm(){
        //populates and shows form, also makes sure youre scrolled to the top
        document.querySelector(this.targets.sections.preview).style.display = 'none';
        document.querySelector(this.targets.sections.form).style.display = 'block';
        document.querySelector(this.targets.previewFields.email).innerHTML = '';
        document.querySelector(this.targets.previewFields.name).innerHTML = '';
        document.querySelector(this.targets.previewFields.message).innerHTML = '';
        document.querySelector(this.targets.previewFields.amount).innerHTML = '';
        window.scroll({
            left: 0,
            top: document.querySelector('.top')
        });
    }
    //Submits complete form
    submit(){
        let itemCode = '05748VC';
        if ((window.location.href.indexOf("localhost") > -1) || (window.location.href.indexOf("webdev") > -1) || (window.location.href.indexOf("uat") > -1)) {
            itemCode = '00126VC';
        }
        
        document.querySelector('.error-block').style.display = 'none';
        //Sends off API request, if an error is shown an error message is shown.
        return request.voucher.add({
            amount: this.group.amount.toString(),
            itemCode,
            message: this.group.message,
            quantity: "1",            
            recipientEmail: this.group.email,
            recipientName: this.group.name,
            salesCode: null,
            verifyEmail: this.group.email
        }).then(res => {
            if(res.status == 200){
                //Success, go to basket
                console.log("added to cart");
                //window.location.href = "/jsp/checkout/common/shoppingcart.jsp"
            }
            else{
                //Failed, log the status and throw an exception 
                console.log('Unexpected response code', res.status);
                throw new Exception("Unexpected response code");
            }
        }).catch((e) => {
            //Execption thrown or something went wrong, show error block
            document.querySelector('.error-block').style.display = 'block';
        });
    }
}

export default EVoucherView;