import richTextToHtml from "./richText";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

const headingOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => `<strong>${text}</strong>`,
    [MARKS.ITALIC]: (text) => `<i>${text}</i>`,
    [MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => `<h2>${next(node.content)}</h2>`
  }
}

/**
 * Transforms the data from contentful into an easier to consume object
 * 
 * @param {Object} data => The data from contentful
 * @return {Object} => Properties of the partnership content after transformation
 */
const contentfulDataToPartnershipContentData = (data) => {
  const {
    heading,
    content,
    media,
  } = data || {};
  
  return {
    title: richTextToHtml(heading, headingOptions),
    content: richTextToHtml(content),
    imageUrl: media ? media.fields.asset.fields.file.url : null,
    imageAlt: media ? media.fields.accessibilityText : null
  };
}

export default contentfulDataToPartnershipContentData;