import Modal from "../../modal/modal";
import event from "../../../utilities/event";
import { templateForFTC } from '../templates';
import { addFTCToOrder, rejectUpsellToOrder } from '../request';
import getBrand from "dw-brand-identify";

const brandObj = getBrand();
const termsLink = (brandObj.id === 'law' || brandObj.id === 'bbc') ?
                    '/customer-services/customer-terms-and-conditions-of-sale-and-use#terms-and-conditions' :
                    '/customer-services/terms-and-conditions-of-sale-and-use#terms-and-conditions';

export const openFTCUpsellModal = (offerItems, transactionId, availableSpaces, preferences, upsell) => {
    let totalItem = 0;
    offerItems.map(item =>{
        item['qty'] = preferences.includes(item.itemCode )? 1: 0;
        totalItem = item['qty'] + totalItem;
    });
    
    const  FTCModal = new Modal({
        title: "FILL YOUR CASE FOR EXTRA SAVINGS",
        body: templateForFTC(offerItems, availableSpaces),
        containsForm: true,
        showFooter: false,
        largeModal: true,
        id: 'postUpsellFTC'
    });

    // Open the modal'/
    FTCModal.open();
    event('Pop-ups', 'Post Upsell FTC', 'Post Upsell FTC opened');

    document.body.addEventListener( 'click', function ( e ) {
        if( e.target.id == 'signUp' ) {
            e.preventDefault();
            let upsellPayload =[];
            upsell.map(items => {
                offerItems.map(wines => {
                    if(items.itemCode === wines.itemCode && wines.qty > 0 ){
                        let itemDetails ={};
                        itemDetails["itemCode"] = wines.itemCode;
                        itemDetails["quantity"] = wines.qty;
                        let upsellOfferDetails = {
                            "type" : items["type"],
                            "rule" : items["rule"]
                        }
                        itemDetails["upsellOfferDetails"]= upsellOfferDetails;
                        upsellPayload.push(itemDetails);
                    }
                });
            });
            const errorContainer = document.getElementById('error-container');
            if (document.querySelector('input#futurePayments').checked) {
                errorContainer.style.display = "none";
                addFTCToOrder(transactionId, upsellPayload);
            } else {
                errorContainer.innerHTML = `Please confirm you are happy to be charged for future payments by checking the box above.`;
                errorContainer.style.display = "block";
            }
        };
    })

    const termsButton = document.getElementById('termsLink');
    if (typeof (termsButton) !== 'undefined' && termsButton !== null) {
        document.getElementById('termsLink').addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell FTC', 'Clicked through from Post Upsell FTC to terms page');
            window.open(termsLink);
        });
    }
    document.getElementById('noThanks-mob').addEventListener('click', (e) => {
        rejectUpsellToOrder(transactionId);
        FTCModal.close();
        sessionStorage.setItem('PostUpsellRejected', 'true');
    });
    document.body.addEventListener( 'click', function ( e ) {
        if(( e.target.className == 'accordion') || ( e.target.className == 'fa fa-chevron-down pull-right') ) {
            e.preventDefault();        
            this.classList.toggle("active");
            /* Toggle between hiding and showing the active panel */
            SwapDisplays();
        }
    });

    document.getElementById('discard').addEventListener("click", function(e){
        e.preventDefault();
        SwapDisplays();
    });

    function SwapDisplays(){
        const panel = document.querySelector('.panel');
        const selectedItem = document.querySelector('.selected-items.only-mobile-view');
        const buttonDiv = document.querySelector('.button-div');
        const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        
        panel.style.display === "block" ? panel.style.display = "none": panel.style.display = "block";
        if(panel.style.display === "block"){
            screenWidth < 767 ? selectedItem.style.display = "none" : selectedItem.style.display = "none";
            buttonDiv.style.display = 'none';
        }else{
            screenWidth < 767 ? selectedItem.style.display = "block" : selectedItem.style.display = "none";
            buttonDiv.style.display = 'block';
        }
    }
   
    document.body.addEventListener( 'click', function ( e ) {
        if( e.target.id == 'saveWines' ) {
            e.preventDefault();
            var qtyList = document.querySelectorAll('.form-control.text-center.quantity')
            totalItem = 0
            offerItems.map(item =>{
                for (var i = 0; i < qtyList.length; i++) {
                    if (item.itemCode === qtyList[i].getAttribute('data-item')){
                        item['qty'] = parseInt(qtyList[i].value);
                    }
                }
                totalItem = item['qty'] + totalItem;
            });
            FTCModal.update(templateForFTC(offerItems, availableSpaces));
            if(totalItem > availableSpaces){
                $('#error-container').css('display','block');
                $('#error-container').html('The number of bottles selected is greater than the available space');
                $('#signUp').addClass('disabled');
            }else{
                $('#signUp').removeClass('disabled');
            }
        }
        if((e.target.id == 'noThanks' ) || (e.target.parentElement.className == 'close')) {
            rejectUpsellToOrder(transactionId);
            FTCModal.close();
            sessionStorage.setItem('PostUpsellRejected', 'true');
        };
    });
}