import Component from '../component';
/**
 * Controls the gift card options, taking the user to the appropriate page on submission.
 * @class  GiftCardView
 */
class GiftCardView extends Component{
    constructor(){
        super('section.gift-cards');
        //URLs for each type
        this.urls = {
            giftCard: "/jsp/offer/cm/common/offer.jsp?name=gift-cards&menu=gifts&swpimg=1&icamp=navtest-gifts-giftcards&_requestid=5714431",
            eVoucher: "/jsp/content.jsp?pg=gift-e-vouchers&menu=gifts"
        };
        //State for user
        this.isEVoucher = null;
        //Binds all buttons to changeType
        Array.from(document.querySelectorAll('.js-choose-gift-type')).forEach((btn) => {
			this.addEvent(btn, 'click', this.changeType.bind(this));
        });
        //Submit button bound to submitForm
        this.addEvent(document.querySelector('.js-submit-voucher-form'), 'click', this.submitForm.bind(this));
    }
    //Sets the type of isEVoucher ready for formSubmit method 
    changeType(e){
        let parent = e.target.closest('.js-choose-gift-type');
        let className = 'active';
        document.querySelector('.js-submit-voucher-form').removeAttribute('disabled');
        this.isEVoucher = parent.classList.contains('evoucher');
        this.clearClassesFromList(document.querySelectorAll('.js-choose-gift-type'), className);
        parent.classList.add(className);
    }
    //User is submitting form, will redirect the user to the appropriate page
    submitForm(){
        if(this.isEVoucher == true){
            window.location.replace(this.urls.eVoucher);
        }
        else if(this.isEVoucher == false){
            window.location.replace(this.urls.giftCard);
        }
    }
    //Clears all classes from a list based on a class name
    clearClassesFromList(list, className){
        Array.from(list).forEach((item) => {
			item.classList.remove(className);
        });
    }
}

export default GiftCardView;