import Component from '../component';
import User from '../../models/user/user';
import Template from '../../utilities/template';
import getBrand from 'dw-brand-identify';
import event from '../../utilities/event';
import Modal from "../modal/modal";
import {clickEventDL } from 'dw-global/apps/dataLayer';

/**
 * @class  LoginModal
 */
class PasswordReset extends Component {
	
	constructor() {
		
		super('section#password-reset');

		this._brandObj = getBrand();

		this.formatting = {
			contactDetails: {
				alignLeft: false,
				alignCenter: false,
				alignRight: false,
			},
		}
		
		this._steps = this.el.querySelector('#password-reset #steps');
		this._template = this.el.querySelector('#password-reset #template');
		this._currentStep = 1;
		
		this._setStep(this._currentStep);
		
		this.render();

		User.fetch();
		
		// listen for updates
		User.subscribe(User.actions.LOGIN_START, this.loginStart.bind(this));
		User.subscribe(User.actions.LOGIN_FAILURE, this.loginFailure.bind(this));
		User.subscribe(User.actions.LOGIN_SUCCESSFUL, this.loginSuccess.bind(this));

		User.subscribe(User.actions.UPDATED_SUCCESSFUL, () => {

			if (User.status === 'hard') {

				this._getFormTemplate(true, this._brandObj.telephone, this._brandObj.id)
					.then((res) => {
						
						this._template.innerHTML = res;
						
						// bind for the login dialog to appear
						this.addEvent(document.querySelector(".js-advice-overlay"), "click", e => {
							e.preventDefault();
							this.modal = new Modal({
								title: 'Password Advice',
								body: this._passwordAdvice(),
								containsForm: false,
								showFooter: false,
								id: 'passwordAdvice',
							});
							let data = {
                                name : 'new password:advice', 
                                clickText : 'advice'
                            }
                            clickEventDL('forgettenNewAdvice', data);
							// open the modal
							this.modal.open();
						});
						
						// Add event listener to capture iframe height changes
						this.addEvent(window, 'message', e => {

							let scroll_height = e.data;

							let container = document.querySelector('.embed-responsive-16by9');
							
							container.style.height = scroll_height + 'px';	
							
						});

					}).catch((e) => {

						let err = `
							<div class="container">
								<div class="row">
									<div class="col-lg-8 col-md-offset-2">
										<div class="alert-danger p-4 mb-5">
											We've encounted an unexpected error, please try again.
											If you continue to experience this message please
											<a href="/jsp/customerservice/common/contactus.jsp" target="_blank">contact us</a>.
										</div>
									</div>
								</div>
							</div>
							`;

						this._button.classList.remove('loading');
						event('Handlebars Failure', err, e);
						this._template.innerHTML = err;

					});
				
				this._currentStep = 2;
				this._setStep(this._currentStep);

			}

		});
		// to trigger the forgetten password
        document.addEventListener("event-add-voucher", (e) => {
            clickEventDL(e.detail.event, e.detail);
        });

	}

	/**
	 * Increments steps taken
	 * @param Integer current step counter
	 */
	_setStep(step) {
		
		const s = document.querySelectorAll('#steps .step');
		
		s.forEach( el => {
			el.classList.remove("active");
		});
		
		this._steps.find(`.step-${step}`).classList.add('active');

	}
	
	/**
	 * Returns password advice content
	 * @return String
	 */
	_passwordAdvice() {

		return (`
		<div class="passwordadvice">
			<span class="tooltip-text">
				<span>
					<ul>
						<span class="hide-mobile">Setting an effective and secure password for each web site you use is an important security measure.<br /><br /></span>
						<span class="hide-mobile"><b>We recommend you:</b></span>
						<li>Use a different password for <b>each</b> online account.</li>
						<li>Use a mix of <b>numbers</b> and characters. Try to mix upper- and lower- case letters and numbers or symbols in your password.</li>
						<li>Avoid using obvious phrases like 'password', or your name or phone number.</li>
						<li>Never disclose your password to anyone else. If you think that someone else knows your password, change it immediately.</li>
						<li><b>Do not send</b> your password by email, no reputable firm will ask you to do this.</li><br /><b>Hints and tips:</b>
						<li>To create a strong password, simply choose three random words. For example, try a line from a song that other people would not associate with you.</li>
						<li>An alternative to writing down passwords is to use an online password vault or safe. Seek recommendations and ensure the one you choose is secure and reputable.</li>
					</ul>
				</span>
			</span>
		</div>
		`);


	}

	/**
	 * Returns form template type based on login state.
	 * @param boolean confirming login sucess
	 * @param String phone number
	 * @param String brand id
	 * @return Promise
	 */
	async _getFormTemplate(loginSuccess = false, phoneNumber = '03330 148 168', brandId = 'law') {

		
		brandId == 'law' ? this.formatting.contactDetails.alignCenter = true 
		                 : this.formatting.contactDetails.alignCenter = false

		return await Template.get('/assets/components/homepage/tmpl/password-reset/form.handlebars', { loginState: loginSuccess, phoneNumber: phoneNumber, formatting: this.formatting });
		
	}

	/**
	 * Render initial form state
	 */
	render() {

		this._getFormTemplate(false, this._brandObj.telephone, this._brandObj.id)
			.then((res) => {
				this._template.innerHTML = res;
				this.addEvent(this.el.querySelector('#signIn'), 'click', this.login.bind(this));

				this._email = this.el.querySelector('input[name="emailAddress"]');
				this._password = this.el.querySelector('input[name="tempPassword"]');
				this._button = this.el.querySelector('#password-reset button#signIn');
				this._error = this.el.querySelector('#password-reset div#alertDanger');

			}).catch((e) => {
				let err = `
					<div class="container">
						<div class="row">
							<div class="col-lg-8 col-md-offset-2">
								<div class="alert-danger p-4 mb-5">
									We've encounted an unexpected error, please try again.
									If you continue to experience this message please
									<a href="/jsp/customerservice/common/contactus.jsp" target="_blank">contact us</a>.
								</div>
							</div>
						</div>
					</div>
					`;
				this._button.classList.remove('loading');
				event('Handlebars Failure', err, e);
				this._template.innerHTML = err;
			});

	}

	/**
	 * Login the user in using the information they have provided
	 */
	login(e) {
		
		e.preventDefault();
		
		// check to make sure the email is valid
		if (!this._validateEmail(this._email.value)) {
			this._email.classList.add('is-invalid');
			this._email.classList.remove('is-valid');
			event('Login Failure', 'Submit Email Address', 'Invalid Email Address');
			return;
		} 
		this._email.classList.add('is-valid');
		this._email.classList.remove('is-invalid');
		
		// check to make sure both fields are valid
		if (!this._password.value) {
			this._password.classList.add('is-invalid');
			this._password.classList.remove('is-valid');
			event('Login Failure', 'Submit Temporary Password', 'Empty Field');
			return;
		}
		this._password.classList.add('is-valid');
		this._password.classList.remove('is-invalid');
		
		// log the user in
		User.login(this._email.value, this._password.value);
	
	}
	
	/**
	 * Start login, add a loading spinner to the button
	 */
	loginStart() {

		this._button.classList.add('loading');

	}
	
	/**
	 * When the login is successful, close the login modal
	 */
	loginSuccess() {

		this._button.classList.remove('loading');
		User.fetch();

	}
	
	/**
	 * When the login fails show the error message
	 * @param  {Exception} e
	 */
	loginFailure(e) {

		// this is out generic catch all message
		let message = `
			We've encounted an unexpected error, please try again.
			If you continue to experience this message please
			<a href="/jsp/customerservice/common/contactus.jsp" target="_blank">contact us</a>.
			`;

		// if we get a response and a status code we should use it
		switch (e.statusCode) {
            case 422: {
                message = e.statusMessage;
                let data = {
                    name : 'temp password:error', 
                    clickText : 'submit',
                    errorMessage : message
                }
                clickEventDL('forgottenTempError', data);
                event('Login Failure', 'Validating Credentials', message);
                break;
            }
            default: {
                // log it out to the console for debugging
                console.error(e);
                let data = {
                    name : 'temp password:error', 
                    clickText : 'submit',
                    errorMessage : message
                }
                clickEventDL('forgottenTempError', data);
                event('Api Failure', 'Validating Credentials', e);
                break;
            }
        }
		
		// show the error, assumeing that the only error
		this._showError(message);
		
		// remove the loading spinner
		this._button.classList.remove('loading');

	}
	
	/**
	 * Show the error message
	 * @param  {string} msg The message you want to be displayed
	 */
	_showError(msg) {

		this._error.style.display = 'block';
		this._error.innerHTML = msg;

	}
	
	/**
	 * Hide the error message
	 */
	_hideError() {

		this._error.style.display = 'none';

	}
	
	/**
	 * Email validation
	 * 
	 * @param  {string} value
	 * @return {bool}   Whether or not the email address is valid
	 */
	_validateEmail(value) {

		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(value);

	}
}

export default PasswordReset;