import Component from '../component';
import contentfulDataToMixedCaseHighlightsData from '../../utilities/contentful/mixedCaseHighlightsTransformer'

class MixedCaseHighlights extends Component {
  constructor(elementId, data) {
    super();
    this.render(elementId, data);
  }

  /**
   * Initialises the layout of the mixed case highlights and changes
   * the inner HTML of any mixed case highlights component
   *
   * @param {String} elementId => The id of the highlights block to change
   * @param {Object} data => The data from contentful
   */
  render(elementId, data) {
    if (!data) return;

    const {
      title,
      highlightedProducts
    } = data;

    const mixedCaseHighlights = `
      <section class="mixed-case-highlights">
        <div class="container p-md-0">
          ${title 
            ? `
              <div class="row">
                  <div class="col-12">
                      <h2>${title}</h2>
                  </div>
              </div> 
            ` 
            : ''
          }
          <div class="row no-gutters content">
            ${highlightedProducts.map((product) => {
              const mixedCaseHighlightsData = contentfulDataToMixedCaseHighlightsData(product)
              const { title, content, imageUrl, imageAlt, buttonText, buttonUrl, buttonType } = mixedCaseHighlightsData;
              return `
                <div class="col-12 col-md-4 pr-md-2 details">
                    <a href="${buttonUrl}" data-section="Mixed Case Highlights" data-offer="${title}">
                        <img src="${imageUrl}" alt="${imageAlt}">
                    </a>
                    <div class="text"> 
                        <h4>${title}</h4>
                        ${content}                        
                    </div>
                    <div>
                      <a class="btn btn-${buttonType} transparent" href="${buttonUrl}" data-section="Mixed Case Highlights" data-offer="${title}">${buttonText}</a>
                    </div>
                </div>
              `;
            }).join('')}
          </div>
        </div>
      </section>
    `;
    const element = document.getElementById(elementId);
    element.innerHTML = mixedCaseHighlights;
	};
};

export default MixedCaseHighlights;
