class Model {

	constructor() {
		this.listeners = [];
	}

	dispatch(action, payload) {

		if (typeof this.listeners[action] !== 'undefined') {
			this.listeners[action].forEach((func) => {
				func(payload);
			});
		}
	}

	subscribe(action, func) {
		if (!this.listeners[action]) {
			this.listeners[action] = [];
		}

		this.listeners[action].push(func);
	}

	unsubscribe(action, func) {
	}
}

export default Model;