/**
 * # Template
 *
 * This class provides a very simple abstraction to a templating system. It can
 * be used with multiple templates and will conditionall load the templating library
 * to save initial download costs.
 *
 * This has already been instaniated.
 *
 * ## Usage
 *
 * Template.get('URL_TO_TEMPLATE', 'BACKUP_STRING', 'WHERE TO GET IT FROM')
 */
class Template {
	/**
	 * ## Stores
	 * Get a list of the stores available to you
	 *     WEB: The template is loaded via AJAX
	 * 
	 * @return {string} The name of the store
	 */
	get stores() {
		return {
			WEB: 'web'
		};
	}

	/**
	 * ## Get
	 * Returns the template
	 * 
	 * @param  {string} template Path/key of the template
	 * @param  {object} variables   Backup string if we can't fetch the template
	 * @param  {string} backup   Backup string if we can't fetch the template
	 * @param  {string} store    Where are we getting the template from
	 * @return {promise}         Promise object, once resolved you will get a string
	 */
	get(template, variables = {}, backup = null, store = this.stores.WEB) {
		// detect the type
		const extension = template.match(/\.[0-9a-z]+$/i)[0];
		// we are returning a promise because this could be async
		return new Promise((resolve, reject) => {
			// Ajax request
			fetch(template).then((response) => {
				return response.text();
			}).then((response) => {
				// copy the string
				const str = response;
				// determin the type
				switch (extension) {
					case '.handlebars': {
						// parse the response and turn it into a template string
						resolve(this._processHandleBars(str, variables));
						return;
					}
				}
			}).catch((e) => {
				reject(backup);
			});
		});
	}

	/**
	 * ## Process Handlebar Template
	 * Take a template string and uses handlebars to markup the string
	 *
	 * @private
	 * @param  {string} templateStr Template string
	 * @param  {object} variables   Object of variables to replace
	 * @return {promise}            Will return a promise of a string
	 */
	_processHandleBars(templateStr, variables) {
		// keep the comment below, otherwise the bundle will be unnamed
		return import(/* webpackChunkName: "handlebars" */ 'handlebars').then(({ default: Handlebars }) => {
			const template = Handlebars.compile(templateStr);
			return template(variables);
		});
	}
}

export default new Template();