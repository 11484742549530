import request from '../../utilities/request';
import Model from '../model';
import { dlProductUpdate, dlfavProduct } from 'dw-global/apps/dataLayer';

const TYPES = {
	subscriptionRecruitmentItem: 'SUBSCRIPTION_RECRUITMENT_ITEM',
	wineplaneRecruitmentItem: 'WINEPLAN_RECRUITMENT_ITEM',
	winerewardRecuitmentItem: 'WINEREWARD_RECRUITMENT_ITEM',
	subscriptionFreeItem: 'SUBSCRIPTION_FREE_ITEM',
	winerewardFreeItem: 'WINEREWARD_FREE_ITEM',
	freeGiftPromotionItem: 'FREEGIFT_PROMOTION_ITEM',
	presell: 'PRESELL_ITEM',
};

/**
 * @class  Product
 */
class ProductModel extends Model {

	constructor(item) {
		super();
		this._item = item;
	}

	/**
	 * List of actions associated with this model. They can be accessed via
	 * `User.actions.UPDATED_START`.
	 * 
	 * @return {Object}
	 */
	get actions() {
		return {
			HYDRATE_START: `product::${this._item.itemcode}::hydrate-start`,HYDRATE_SUCCESSFUL: `product::${this._item.itemcode}::hydrate-successful`,
			HYDRATE_FAILURE: `product::${this._item.itemcode}::hydrate-failure`,
		}
	}

	get thumbnail() {
		return this._item.product.thumbnailImage || '/images/uk/en/law/default_images/default_bottle.png';
	}

	get name() {
		return this._item.product.name;
	}

	get itemcode() {
		return this._item.product.itemCode;
	}

	get bottleCount() {
		return this._item.bottleCount;
	}

	get vintage() {
		return this._item.product.vintage;
	}

	get types() {

		let types = [];

		if (
			this._item.lineItemIdentification &&
			this._item.lineItemIdentification.itemClassifications &&
			this._item.lineItemIdentification.itemClassifications.contextClassificationDetails.length > 0
		) {
			this._item.lineItemIdentification.itemClassifications.contextClassificationDetails.forEach((classification) => {
				Object.keys(TYPES).forEach((key) => {
					if (classification === TYPES[key]) {
						types.push(classification);
					}
				});
			});
		}

		return types;
		//.length === 0 ? false : types.length === 1 ? types[0] : types; 
	}

	get isSubscriptionRecruitmentItem() {
		return this.types.indexOf(TYPES.subscriptionRecruitmentItem) >= 0 ? true : false;
	}

	get isWineplaneRecruitmentItem() {
		return this.types.indexOf(TYPES.wineplaneRecruitmentItem) >= 0 ? true : false;
	}

	get isWinerewardRecuitmentItem() {
		return this.types.indexOf(TYPES.winerewardRecuitmentItem) >= 0 ? true : false;
	}

	get isSubscriptionFreeItem() {
		return this.types.indexOf(TYPES.subscriptionFreeItem) >= 0 ? true : false;
	}

	get isWinerewardFreeItem() {
		return this.types.indexOf(TYPES.winerewardFreeItem) >= 0 ? true : false;
	}

	get isFreeGift() {
		return this.types.indexOf(TYPES.freeGiftPromotionItem) >= 0 ||
			this.types.indexOf(TYPES.subscriptionFreeItem) >= 0 || 
			this.types.indexOf(TYPES.winerewardFreeItem) >= 0;
	}

	get isUnlimited() {

	}

	get isPresell() {
		return this.types.indexOf(TYPES.presell) >= 0;
	}

	hydrate(itemcode) {
		if (itemcode) {
			return request.product.get({itemcode}).then((res) => {
				return res.json();
			}).then((json) => {
				if (json.statusCode >= 400 && json.statusCode < 600) {
					throw new Error(json.statusMessage);
				}
				if (json && json.response) {
					dlProductUpdate(json.response);
				}
			}).catch((e) => {
				return null
			});
		}
	}

	isFavourite(itemcode) {
		if (itemcode) {
			return request.user.favourites.list().then((res) => {
				return res.json();
			}).then((json) => {
				if (json.statusCode >= 400 && json.statusCode < 600) {
					throw new Error(json.statusMessage);
				}
				if (json && json.response) {
					let userItems = json.response?.userItems;
					if(userItems.length > 0){
						for (var key in userItems) {
							if(userItems[key].product.itemCode === itemcode){
								dlfavProduct(userItems[key].whereUsed?.inFavourites);
							}
						}
					}else{
						dlfavProduct('false');
					}
				}
			}).catch((e) => {
				return null
			});
		}
	}

}

export default ProductModel;
