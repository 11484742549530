import Component from "../component";
import request from '../../utilities/request';
import Cart from "../../models/cart/cart.js";
/**
 * Product Upsell
 * Creates a component to load the an upsell icon with links
 * This is based on the product item code having an upsell promotion
 * set up against it (via an API call), if this is the case, we check to see
 * to see if an HTML and image have been created in order to display the UI
 */
class OfferListingGrid extends Component {
    constructor() {
        super();

        Array.from(document.getElementsByClassName('offer-listing-grid')).forEach((offer) => {
            const template = offer.querySelector('.template');
            const items = offer.getAttribute('data-items');
            this.getAllItemData(items, template);
        });
    }

    async getAllItemData(items, template) {
        const arr = items.split(",").map(item => item.trim());

        const unresolved = arr.map(async (item) => {
            const data = await this.getItem(item);
            return data;
        })

        const resolved = await Promise.all(unresolved);

        template.innerHTML = resolved.map(item => this.template(item)).join('');

        // Look for all the case contents link, setup and click listener to dispatch
        // and event to load the case content component modal
        Array.from(template.querySelectorAll('.js-view-case-contents')).forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();

                const event = new CustomEvent('open-case-contents-modal', {
                    bubbles: true,
                    detail: e.target.getAttribute('data-itemcode')
                });
                // Dispatch the event.
                document.dispatchEvent(event);
            });
        });

        Array.from(template.querySelectorAll('.js-open-tasting-note')).forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
				// Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
				const event = new CustomEvent('open-tasting-note', {
					bubbles: true,
					detail: btn.getAttribute('data-itemcode')
				});
				// Dispatch the event.
				document.dispatchEvent(event);
            });
        });

        Array.from(template.querySelectorAll('button.js-add-item-to-cart')).forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();

                const finished = () => {
                    e.target.classList.remove('loading');
                    e.target.innerText = 'Added to basket';
                }

                const code = e.target.getAttribute('data-itemcode');
                const price = e.target.getAttribute('data-price');
                const quantity = e.target.getAttribute('data-quantity');

                // add the item to the cart, this will fire a render
                Cart.addItem(code, parseInt(quantity, 10), price);

                Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
                Cart.subscribe(Cart.actions.ADD_FAILURE, finished);

                e.target.classList.add('loading');
                e.target.innerText = 'Adding...';
            });
        });
    }

    getItem(itemcode) {
        return request.product.get({
            itemcode
        }).then((res) => res.json()).then((res) => {
            if (res.statusCode !== 0) {
                throw new Error(res.errorResponse.message);
            }
            return res.response;
        }).catch((error) => {
            console.log(error.message);
        });
    }

    formatCurrency(amount) {
        const value = amount.toFixed(2);
        return `£${value}`
    }

    displayCorrectPricing(data, savings) {
        let displayPricing = '';
        if (savings && savings !== '£0.00') {
            displayPricing = `
            <div class="price"><span class="case-price">${this.formatCurrency(data.skus[0].salePrice)}</span> <strike>${this.formatCurrency(data.skus[0].listPrice)}</strike></div>
            <div class="savings-block"><span class="savings-text">Save ${savings}</span> - just ${this.formatCurrency(data.skus[0].salePricePerBottle)} per bottle</div>`

        } else {
            displayPricing = `<div class="price"><span class="case-price">${this.formatCurrency(data.skus[0].salePrice)}</span></div>`
        }
        return displayPricing
    }

    displayCorrectItemDetailsModal(data, isMixed) {
        let displayDetailLink = '';
        if (isMixed) {
            displayDetailLink = `<div class="contents-link"><a class="js-view-case-contents" data-case="singlecase" data-price="${data.skus[0].salePrice}" data-itemcode="${data.itemCode}" href="#">View contents&nbsp;&nbsp; <i class="fa fa-chevron-right"></i></a></div>`

        } else {
            displayDetailLink = `<div class="contents-link"><a class="js-open-tasting-note" data-itemcode="${data.itemCode}" href="#">View details <i class="fa fa-chevron-right"></i></a></div>`
        }
        return displayDetailLink
    }

    template(data) {
        let webHeadline = '';
        let savings = this.formatCurrency(data.skus[0].listPrice - data.skus[0].salePrice);
        let name = `${data.name} ${data.vintage ? data.vintage : ''}`;
        let imgError = data.mixed ? '/images/uk/en/law/default_images/default_case.png' : '/images/uk/en/law/default_images/default_bottle.png';

        if (data.webHeadline) {
            webHeadline = `<p>${data.webHeadline}</p>`
        }

        if (data && data.summaryAvailabilityStatus !== 'out_of_stock' && data.skus.length > 0) {
            return (`
                <div>
                    <div class="image">
                        <a href="/product/${data.itemCode}"><img src="${data.smallImage}" onerror="this.onerror=null;this.src='${imgError}'" alt="${name}" title="${name}"/></a>
                    </div>

                    <a href="/product/${data.itemCode}"><h3 class="name">${name}</h3></a>

                    ${webHeadline}

                    ${this.displayCorrectItemDetailsModal(data, data.mixed)}
                    ${this.displayCorrectPricing(data, savings)}
                    
                    <div class="atb"><button data-qty="1" data-price="${data.skus[0].salePrice}" data-itemcode="${data.itemCode}" data-quantity="1" class="js-add-item-to-cart btn btn-primary btn-block">Add to basket</button></div>
                </div>
            `)
        }
    }
}
export default OfferListingGrid;

