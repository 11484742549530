// polyfills
import '@babel/polyfill';
import 'handlebars';
import 'es6-promise';
import closest from "element-closest";
closest(window);

// js
import PageController from './controllers/page';
// sass
import './index.scss';

new PageController();