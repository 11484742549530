import { $ } from '../utilities/min';

class Component {
	constructor(tag) {
		if (tag) {
			if (typeof tag === 'object') {
				this.el = tag;
			} else {
				this.el = $(tag);
			}
		}
		this._events = {};
	}

	get element() {
		return this.el;
	}

	set element(el) {
		this.el = el;
	}

	get nextEventId() {
		this._id = this._id && this._id > 0 ? this._id++ : 0;
	}

	loading() {
		if (this.el.classList.contains('loading')) {
			this.el.classList.remove('loading');
		} else {
			this.el.classList.add('loading');
		}
	}

	_eventOptionsSupported() {
		// work out if the passive event listener is supported
		let passiveSupported = false;	
		try {
			const options = Object.defineProperty({}, 'passive', {
				get: () => { passiveSupported = true }
			});
			window.addEventListener('test', options, options);
  			window.removeEventListener('test', options, options);
		} catch(e) {
			passiveSupported = false;
		}
		return passiveSupported;
	}

	/**
	 * Add Event
	 * Add an event for this component, calls addEventListener behind the seems
	 * but keeps a record of all the events which are called so they can be easily
	 * removed.
	 *
	 * @see https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener
	 * 
	 * @param {DOMElement}  element
	 * @param {string}  	action
	 * @param {function}  	func
	 * @param {object}  	options will most likely be used to pass `passive:true` for scroll events
	 * @param {Boolean} 	useCapture
	 */
	addEvent(element, action, func, options = null, useCapture = false) {
		// if the options flag is supported
		let event;
		if (element !== null) {
			if (Array.isArray(element)) {
				if (element.length > 0) {
					element.forEach(elem => {
						event = this._eventOptionsSupported() ?
							// pass the options in, and useCapture second
							elem.addEventListener(action, func, options, useCapture) :
							// useCapture is the 3rd argument in IE
							elem.addEventListener(action, func, useCapture);
					});
				}
			} else {
				event = this._eventOptionsSupported() ?
					// pass the options in, and useCapture second
					element.addEventListener(action, func, options, useCapture) :
					// useCapture is the 3rd argument in IE
					element.addEventListener(action, func, useCapture);
			}
		}
		// get this event ID
		const id = this.nextEventId;
		// store the event into the class, this is so we can delete it
		this._events[id] = {id, event, element, action, func, options, useCapture};
		// return the ID for tracking
		return id;
	}

	/**
	 * Remove Event
	 * The opposite of add event
	 * 
	 * @param  {[type]} id [description]
	 * @return {[type]}    [description]
	 */
	removeEvent(id) {
		const event = this._events[id];
		// is the options flag available
		const eventRemoved = this._eventOptionsSupported() ?
			// remove the event for modern browsers
			event.element.removeEventListener(event.action, event.func, event.options, event.useCapture) :
			// remove the event for older browsers (IE :@)
			event.element.removeEventListener(event.action, event.func, event.useCapture);

		if (eventRemoved) {
			// remove from this model
			delete this._events[id];
			return true;
		} else {
			return false;
		}
	}
}

export default Component;