import Component from '../component';
import getBrand from 'dw-brand-identify';

// time to spend on each image in the loop
const HERO_TIME = 5000;

class HeroView extends Component {

	constructor() {
		super('section.hero');

		this._brandObj = getBrand();
		// get a list of all the panels
		this._panels = Array.from(this.el.querySelectorAll('li.hero-panel'));
		// get a list of all the tabs
		this._tabs = Array.from(this.el.querySelectorAll('li.tab'));
		// if we dont have any panels or tabs we cant do anything
		if (this._panels.length === 0 || this._tabs.length === 0) {
			return;
		}
		// the current selected/visible panel & tab
		this.setVisible(this._panels[0], this._tabs[0]);
		// whether the mouse is hovering over the div
		this._hovering = false;

		// add the click event
		this._tabs.forEach((tab) => {
			this.addEvent(tab, 'click', this.toggleView.bind(this));
		});
		
		// add events to watch for when the mouse enters and leave the hero
		this.addEvent(this.el, 'mouseover', () => { this._hovering = true });
		this.addEvent(this.el, 'mouseout', () => { this._hovering = false });

		this.addEvent(this.el, 'touchstart', () => { this._hovering = true });
		this.addEvent(this.el, 'touchend', () => { this._hovering = false });

		this._windows = Array.from(this.el.querySelectorAll('div.row'));
		this._touchStartX, this._touchMoveX, this._moveX, this._index = 0;
		// carousel for mobile device only
		if (document.body.offsetWidth < 768) {
			this._windows.forEach((win) => {
				this.addEvent(this.el, 'touchstart', this.touchSlideStart.bind(this), {passive: true});
				this.addEvent(this.el, 'touchmove', this.touchSlideMove.bind(this), {passive: true});
				this.addEvent(this.el, 'touchend', this.touchSlideEnd.bind(this), {passive: false});
			});
		}

		var body = document.body;
		var touchDevice = (typeof (window.ontouchstart) !== 'undefined') ? true : false;
		if(touchDevice == true){
			body.addEventListener("touch",() => { this._hovering = false });
		}else{
			body.addEventListener("click",() => { this._hovering = false });
		}

		// start the auto switching
		this._timer = setTimeout(this.autoSwitch.bind(this), HERO_TIME);
	}

	touchSlideStart(e) {
		this._moveX = 0;
		this._selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		this._ul = this._selected.querySelector('ul');
		this._elementWidth = this._selected.querySelector('li:not([style*="display:none"]):not([style*="display: none"])').offsetWidth;
		this._touchStartX = e.touches[0].pageX;
	}

	touchSlideMove(e) {
		this._touchMoveX = e.touches[0].pageX;
		this._moveX = (this._index*this._elementWidth) + (this._touchStartX - this._touchMoveX);
	}

	touchSlideEnd() {
        var touchswipe = false;
		if(!this._moveX || (Math.abs(this._moveX) < this._elementWidth/2)) {
			return;
		}
		let currentIndex = this._panels.indexOf(this._visible[0]);
		if (this._moveX > 0 && currentIndex < this._ul.querySelectorAll('li').length-1) {
            touchswipe = true;
			this.setVisible(this._panels[currentIndex++], this._tabs[currentIndex++], touchswipe);
		} else if (this._moveX < 0 && currentIndex > 0) {
            touchswipe = true;
			this.setVisible(this._panels[currentIndex--], this._tabs[currentIndex--], touchswipe);
		}
	}
	/**
	 * When you click on an item, select it.
	 * 
	 * @param  {DOMEVENT} e
	 */
	toggleView(e) {
		const tab = e.target.classList.contains('tab') ? e.target : e.target.closest('.tab');
		// find the tab in the index
		this.setVisible(this._panels[this._tabs.indexOf(tab)], tab);
	}

	setVisible(panel, tab, swipe_event) {
		// are we trying to set the visible one
		if (this._visible === panel) {
			return;
		}
		let colour = "#ffffff";
		// loop through all the tabs and select the new one
		this._tabs.forEach((t, i) => {
			if (tab === t) {
				t.classList.add('selected');
				//black friday specific carousel style changes
				if(this._brandObj.id !== 'avy'){
					if(this._tabs[0].classList.contains('selected')){
						t.classList.add('black_friday');
						this.el.classList.add('black_friday_background');
					}
					else{
						this.el.classList.remove('black_friday_background');
					}
				}

				this._panels[i].style.display = 'block';
				colour = this._panels[i].getAttribute('data-color')
				this._visible = [this._panels[i], t];
				return;
			}
			t.classList.remove('selected');
			this._panels[i].style.display = 'none';
		});
		// set the background colour
		this.el.style.backgroundColor = colour;
		if (swipe_event) {
			clearTimeout(this._timer);
			this._timer = setTimeout(this.autoSwitch.bind(this), HERO_TIME);
		}
	}
	autoSwitch() {
		// are we hovering
		if (this._hovering) { 
			this._timer = setTimeout(this.autoSwitch.bind(this), HERO_TIME);
			return;
		}
		// jump to the next one
		let currentIndex = this._panels.indexOf(this._visible[0]);

		if (currentIndex >= this._panels.length - 1) {
			this.setVisible(this._panels[0], this._tabs[0]);
		} else {
			this.setVisible(this._panels[currentIndex + 1], this._tabs[currentIndex + 1]);
		}
		this._timer = setTimeout(this.autoSwitch.bind(this), HERO_TIME);
	}
}

export default HeroView;