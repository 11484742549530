import richTextToHtml from './richText';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const headingOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => `<strong>${text}</strong>`,
    [MARKS.ITALIC]: (text) => `<i>${text}</i>`,
    [MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => `<h4>${next(node.content)}</h4>`,
  },
};

/**
 * Transforms the cell data from contentful into an easier to consume object
 *
 * @param {Object} cell => The individual cell data within the tetris
 * @return {Object} => Properties of the cell after transformation
 */
const cellDataToTetrisData = (id, cell) => {
  const { backgroundColour, title, content, image, button } = cell.fields || {};

  return {
    dataSection: id === 'tetris-top-section' ? 'Top Tetris' : 'Bottom Tetris',
    dataOfferImage: image ? image.fields.entryTitle : null,
    dataOfferButton: button ? button.fields.entryTitle : null,
    backgroundColour: `contentful-${backgroundColour.toLowerCase().replaceAll(' ', '-')}`,
    title: richTextToHtml(title, headingOptions),
    content: richTextToHtml(content),
    imageUrl: image ? image.fields.asset.fields.file.url : null,
    imageAlt: image ? image.fields.accessibilityText : null,
    buttonText: button.fields.link.fields.text,
    buttonUrl: button.fields.link.fields.url,
    buttonType: backgroundColour
      ? 'primary'
      : button.fields.buttonType.toLowerCase(),
  };
};

export default cellDataToTetrisData;
