import Component from "../component";
import event from "../../utilities/event";
import { getWinePlanSchemeData, getItem, rejectUpsellToOrder, getUpsellForConfirmationPage, getFillTheCaseItem } from './request';
import { supports_html5_storage } from './helper';
import { openUnlimitedModal } from './post-upsell/unlimited';
import { openWinePlanUpsellModal } from './post-upsell/wineplan';
import { openFTCUpsellModal } from './post-upsell/fill-the-case';

class PostCheckoutUpsell extends Component {

    constructor() {
        super('div.post-upsell');

        // Upsell items and content
        this._upsell = '';
        this._itemData = '';
        this._offerItems = [];
        this._template = this.el.querySelector('.template');
        this._transactionId = ''; // Set to a string to fake the ID
        this._unlimited = '';

        // Check the data layer for the transactionId
        if (window.dataLayer && window.dataLayer[0] && window.dataLayer[0].transactionId) {
            this._transactionId = window.dataLayer[0].transactionId;
        }
        // Check that orderId exists and the PostUpsellRejected in localstorage is true
        if (supports_html5_storage()) {
            const lsUpsell = sessionStorage.getItem('PostUpsellRejected');
            if (this._transactionId && lsUpsell !== 'true') {
                this.checkForUpsell();
            }
        }
        document.addEventListener("close-modal", (e) => {
            if (e.detail === 'postUpsellWinePlan') {
                event('Pop-ups', 'Post Upsell Wineplan', 'Post Upsell Wineplan closed');
            }
            if (e.detail === 'postUpsellUnlimited') {
                event('Pop-ups', 'Post Upsell Unlimited', 'Post Unlimited Wineplan closed');
            }            
            /* Set localStorage 'PostUpsellRejected' to true, this will stop the modal 
            From reappearing */
            if(e.detail !== 'UnlimitedLearnMore'){
                sessionStorage.setItem('PostUpsellRejected', 'true');
            };
            rejectUpsellToOrder(this._transactionId);
        });
    }

    async checkForUpsell() {
        const getUpsellsApiRes = await getUpsellForConfirmationPage(this._transactionId);
        const getUpsells = getUpsellsApiRes?.response?.UpsellOffers?.offerItems;

        // Did we get the upsell data?
        if (getUpsells) {
            const offerWinePlanUpsell = getUpsells.filter(upsell => upsell.type === 'WINEPLAN');
            const offerUnlimitedUpsell = getUpsells.filter(upsell => (upsell.type === 'SUBSCRIPTION' && upsell.rule === 'FreeShip'));
            var offerFTCUpsell = getUpsells.filter(upsell => upsell.type === 'FILL_THE_CASE');
            offerFTCUpsell = offerFTCUpsell.filter((item, index, array) => array.findIndex(t => t.itemCode == item.itemCode) == index);
            /**
             * Unlimited Upsell
             */
             const cardPayment = dataLayer[0].transactionPaymentMethod !== undefined && dataLayer[0].transactionPaymentMethod !== 'paypalPayment' ? true : false;
             if (cardPayment == true){
                 if (offerUnlimitedUpsell.length > 0) {
                     // Set the Upsell
                     this._upsell = offerUnlimitedUpsell[0];
                     this._itemData = await getItem(this._upsell.itemCode);
                    
                     if (this._itemData && this._itemData.skus && this._itemData.skus.length > 0) {
                         openUnlimitedModal(this._transactionId, this._upsell, this._itemData.skus[0].salePrice);
                     } else {
                         console.log(`No skus setup for Unlimited: ${this._itemData.skus[0].itemCode}`)
                     }
                 }
             }

            /**
             * Wineplan Upsell
             */
            if (offerWinePlanUpsell.length > 0) {
                
                // Set the Upsell
                this._upsell = offerWinePlanUpsell[0];
                this._itemData = await getItem(this._upsell.itemCode);

                if (this._itemData && this._itemData.skus && this._itemData.skus.length > 0) {
                    if (this._itemData.skus[0].schemeDetails && this._itemData.skus[0].schemeDetails.length > 0) {
                        const schemeId = this._itemData.skus[0].schemeDetails[0].schemeId;
                        if (schemeId) {

                            this._winePlanSchemeData = await getWinePlanSchemeData(schemeId);
                            openWinePlanUpsellModal(this._winePlanSchemeData, this._itemData, this._transactionId, this._upsell);

                        } else {
                            console.log(`No SchemeID setup for upsell: ${this._itemData.skus[0].itemCode}, cannot get dynamic wine plan details`)
                        }
                    }
                } else {
                    console.log(`No skus setup for upsell: ${this._itemData.skus[0].itemCode}`)
                }
            }

            /**
             * Fill the case Upsell
             */
            let availableSpaces = getUpsellsApiRes.response?.UpsellOffers?.availableSpaces;
             if (offerFTCUpsell.length > 0 && availableSpaces > 0) {
                // set the preferences for preselect
                const productList = getUpsells.length;
                var preselectItems;
                if(availableSpaces >= 3){
                    preselectItems = (productList >= availableSpaces) ? 3 : (productList < availableSpaces) ? productList : 0;
                }else if (availableSpaces < 3){
                    preselectItems = (productList <= availableSpaces) ? productList : (productList > availableSpaces) ? availableSpaces : 0;
                }
                
                var preferences = getUpsells.filter(items => items);
                    preferences =  preferences.slice(0,preselectItems);
                var preferedItem = preferences.map(obj => obj.itemCode);

                // Set the Upsell
                this._upsell = offerFTCUpsell;
                var promises = [];
                offerFTCUpsell.forEach(item => {
                    this._itemData = getFillTheCaseItem(item.itemCode);
                    promises.push(this._itemData);
                });
                Promise.all(promises).then((itemData) => {
                    itemData.forEach(offerItem =>{
                        if (offerItem && offerItem.skus && offerItem.skus.length > 0) {
                            this._offerItems.push(offerItem);
                        }
                    });
                    if(this._offerItems.length > 0){
                        openFTCUpsellModal(this._offerItems, this._transactionId, availableSpaces, preferedItem, this._upsell);
                    }
                });
            }

        } else {
            this._template.innerHTML = '<div class="noUpsell"></div>';
        }
    }
}
export default PostCheckoutUpsell;

