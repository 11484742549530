import Component from "../component";
import User from "../../models/user/user";

class Subscriptions extends Component {
	constructor() {
		super();
		this._winerewards = Array.from(
			document.querySelectorAll(".js-wr-balance")
		);

		// If the profile lookup is successful
		User.subscribe(User.actions.UPDATED_SUCCESSFUL, () => {
			if (User.isWineReward && User._securityStatus >= 4) {
				// Find each element of wine rewards and add
				// a loading spinner
				this._winerewards.forEach(function (balance) {
					balance.classList.add("loading");
				  });
				// Get the balance from the savings account
				User.savingAccount();
			}
		});
		// Run the correct function if we are returned a balance or if a failure occurs
		User.subscribe(
			User.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_SUCCESS,
			this.renderBalance.bind(this)
		);
		User.subscribe(
			User.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_FAILURE,
			this.failure.bind(this)
		);
	}

	failure(e) {
		this._winerewards.forEach(function (balance) {
			balance.classList.remove("loading");
		  });
	}

	renderBalance(data) {
		if (data._balance) {								
			this._winerewards.forEach(function (balance) {
				balance.classList.remove("loading");
				//balance.classList.remove("hide");
				//$('.side_nav_rewards_link').removeClass('hide');
				var b = balance.querySelector(".balance");
				b.innerHTML = data._balance;
				if($("#Next-Case").length > 0){
					setTimeout(function(){
						var bal = document.getElementById("reward-balance");
						 bal.innerHTML = data._balance;
					}, 3000);
				}
			  });
		}
	}
}

export default Subscriptions;
