import request from '../../utilities/request';
import Model from '../model';
import Cookies from 'js-cookie'
import event from '../../utilities/event';
import getBrand from 'dw-brand-identify';
import { dlUserUpdate, dlmarketingPermissions } from 'dw-global/apps/dataLayer';

/**
 * @class  User
 */
class UserModel extends Model {

	constructor() {
		super();

		this._title = false;
		this._firstname = false;
		this._lastname = false;
		this._email = false;
		this._customerNumber = false;
		this._profileId = false;
		this._freeItemProfile = false; // Premiere
		this._freeShipProfile = false; // Unlimited
		this._recentlyViewed = [];
		this._recommendedWinesArr = [];
		this._subscriptions = [];
		this._balance = 0;
		this._isWineReward = false; // Wine Reward (duh)
		this._findActiveSubscription = false;
		this._preferences = false;
		this._profileId = false;
		this._subscriptions = [];
		this._emailOptInStatus = true;
		this._brandObj = getBrand();
	}

	/**
	 * List of actions associated with this model. They can be accessed via
	 * `User.actions.UPDATED_START`.
	 * 
	 * @return {Object}
	 */
	get actions() {
		return {
			// update action
			UPDATED_START: 'user::updated-start',
			UPDATED_SUCCESSFUL: 'user::updated-successful',
			UPDATED_FAILURE: 'user::updated-failure',
			UPDATED_LOADING_END: 'user::updated-loading-end',
			// login actions
			LOGIN_START: 'user::login-start',
			LOGIN_SUCCESSFUL: 'user::login-successful',
			LOGIN_FAILURE: 'user::login-failure',
			LOGIN_LOADING_END: 'user::login-loading-end',
			// logout actions
			LOGOUT_START: 'user::logout-start',
			LOGOUT_SUCCESSFUL: 'user::logout-successful',
			LOGOUT_FAILURE: 'user::logout-failure',
			LOGOUT_LOADING_END: 'user::logout-loading-end',
			// recently browsed actions
			RECENTLY_VIEWED_START: 'user::recently-viewed-start',
			RECENTLY_VIEWED_SUCCESSFUL: 'user::recently-viewed-successful',
			RECENTLY_VIEWED_FAILURE: 'user::recently-viewed-failure',
			// recommended wines
			RECOMMENDED_WINES_START: 'user::recommended-wines-start',
			RECOMMENDED_WINES_SUCCESSFUL: 'user::recommended-wines-successful',
			RECOMMENDED_WINES_FAILURE: 'user::recommended-wines-failure',

			SUBSCRIPTIONS_SAVINGS_ACCOUNT_START: 'user::subscriptions-savingsaccount-start',
			SUBSCRIPTIONS_SAVINGS_ACCOUNT_SUCCESSFUL: 'user::subscriptions-savingsaccount-successful',
			SUBSCRIPTIONS_SAVINGS_ACCOUNT_FAILURE: 'user::subscriptions-savingsaccount-failure',

			//payment options
			PAYMENT_OPTIONS_START: 'user::payment-options-start',
			PAYMENT_OPTIONS_SUCCESSFUL: 'user::payment-options-successful',
			PAYMENT_OPTIONS_FAILURE: 'user::payment-options-failure',

			// user optin preferences
			UPDATE_PREFERENCES_START: 'user::perferences_start',
			UPDATE_PREFERENCES_SUCCESSFUL: 'user::perferences_successful',
			UPDATE_PREFERENCES_FAILURE: 'user::perferences_failure',
			UPDATE_PREFERENCES_LOADING_END: 'user::perferences_loading_end',
			//hsbc entry page
			HSBC_COOKIECHECK_START: 'user::hsbc_start',
			HSBC_COOKIE_AVAILABLE: 'user::hsbc_true',
			HSBC_COOKIE_FAILURE: 'user::hsbc_false',

			//emailSignup services
			EMAIL_OPTIN_INIT: 'user::email_optin_init',

			//newsletterOptIn services
			NEWSLETTER_OPTIN_INIT: 'user::newsletter_optin_init',

		}
	}

	/**
	 * Get the title of the user. For example 'Mr'
	 * @return {string} Users title
	 */
	get title() {
		return this._title;
	}

	/**
	 * Get the users first name, it will be returned in title case since the
	 * API has a habit of uppercasing everything.
	 *
	 * @todo once the API/ATG has been fixed remove the special casing for the
	 * title case.
	 * 
	 * @return {string}
	 */
	get firstName() {
		return this._firstname.replace(/\w\S*/g, (txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	/**
	 * Get the users last name, it will be returned in title case since the
	 * API has a habit of uppercasing everything.
	 *
	 * @todo once the API/ATG has been fixed remove the special casing for the
	 * title case.
	 * 
	 * @return {string}
	 */
	get lastName() {
		return this._firstname.replace(/\w\S*/g, (txt) => {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
	}

	/**
	 * Get the users whole name. This will append the `getFirstName` and 
	 * `getLastName` functions.
	 * 
	 * @return {string}
	 */
	get wholeName() {
		return `${this.firstName} ${this.lastName}`;
	}

	/**
	 * Get the users email
	 * 
	 * @return {string}
	 */
	get email() {
		return this._email;
	}

	get customerNumber() {
		return this._customerNumber;
	}

	get profileId() {
		return this._profileId;
	}

	get balance() {
		return this._balance;
	}

	/**
	 * Get the users logged in status. Will either return 'hard', 'soft' or false
	 * depending on whether the user is logged in.
	 *
	 * | status 	| Description 												|
	 * =============|==========================================================	|
	 * | `hard`		| User is fully logged in, we have access to everything 	|
	 * | `soft`		| The user isn't logged in but we have info from the cookie |
	 * | `false`	| The user isn't logged in 									|
	 * 
	 * @return {string|boolean}
	 */
	get status() {
		switch (this._securityStatus) {
			case 5:
			case 4:
				return 'hard';
			case 3:
			case 2:
				return 'soft';
			default:
				return false;
		}
	}

	get recentlyViewed() {
		return this._recentlyViewed;
	}

	get isUnlimited() {
		return this._freeShipProfile;
	}

	get isPremiere() {
		return this._freeItemProfile;
	}

	get isWineReward() {
		return this._isWineReward;
	}

	get preferences() {
		return {
			email: !this._preferences.noEmail,
			mail: !this._preferences.mail,
			outBoundCall: !this._preferences.noOutBoundCall,
			shareData: this._preferences.shareData,
			shareEmail: this._preferences.shareEmail,
			sms: this._preferences.sms
		}
	}

	/**
	 * Fetch the users profile, this will hydrate the model causing the update
	 * actions to be fired.
	 *
	 * @return {Promise} [description]
	 */
	fetch() {
		// starting the 
		this.dispatch(this.actions.UPDATED_START);
		// make the request
		return request.user.profile().then((res) => {
			return res.json();
		}).then((json) => {
			this._map(json);
			this.updateDataLayer(this._customerNumber, this._preferences, this._subscriptions);
			this.updateMarketingPermissions();
			dlUserUpdate(json.response);
			this.dispatch(this.actions.UPDATED_SUCCESSFUL, this);
		}).catch((e) => {
			this.dispatch(this.actions.UPDATED_FAILURE, e);
			console.log('error', e);
		});
	}

	/**
	 * Update the datalayer with information for Yieldify
	 * @param {string} customerId 
	 * @param {object} marketingPrefs 
	 * @param {object} subscriptions 
	 */
	updateDataLayer(customerId, marketingPrefs, subscriptions) {
		// We'll assume that the user is unsullied by subscriptions, at least to start.
		let winePlan = false;
		let wineRewards = false;
		let unlimited = false;
		let premiere = false;
		let confrere = false;

		// Let's clean out all the inactive subscriptions
		let activeSubs = subscriptions.filter(subscription => subscription.status === "ACTIVE");

		// I'm leaving this here as I think there's a cleaner way than the loop below to
		// handle this that we can come back to later.
		let trackedSubs = [];

		// Check through our subscriptions to see if they match the plans we want to track
		activeSubs.forEach(function (sub) {
			trackedSubs.push(sub.schemeId);

			if (sub.schemeId === 'CONF') {
				confrere = true;
			}

			if (sub.schemeId === 'CNF6') {
				confrere = true;
			}

			if (sub.schemeId === 'CNFM') {
				confrere = true;
			}

			if (sub.type === "WINEREWARD") {
				wineRewards = true;
			}

			if (sub.schemeId === "UNLT") {
				unlimited = true;
			}
			if (sub.schemeId === "PREM") {
				premiere = true;
			}

			if (sub.type === "WINEPLAN") {
				winePlan = true;
			}
		});

		// Push the collected stuff to the datalayer
		window.dataLayer.push({
			'marketingPreferences': marketingPrefs,
			'customerId': customerId,
			'winePlan': winePlan,
			'wineRewards': wineRewards,
			'unlimited': unlimited,
			'premiere': premiere,
			'confrere': confrere,
		});
	}

	/**
	 * # Login
	 * Login the user in via email and password. This does not support 
	 * customer number!
	 * 
	 * @param  {string} email
	 * @param  {string} password 
	 * @return {Promise}
	 */
	login(email, password) {
		// notify that we are logging in
		this.dispatch(this.actions.LOGIN_START);
		// make the request
		return request.user.login({ email, password }).then((res) => {
			// decode the JSON
			return res.json();
		}).then((json) => {
			// if the response is in range
			if (json.statusCode >= 400 && json.statusCode < 600) {
				throw json;
			}
			// dispatch that the login was successful
			this.dispatch(this.actions.LOGIN_SUCCESSFUL, this);
			// we now have to get the user data
			// return this.fetch();
		}).catch((e) => {
			this.dispatch(this.actions.LOGIN_FAILURE, e);
			event('Login', 'error', JSON.stringify(e));
		});
	}

	/** 
	 * Update the users optin preferences
	 * 
	 * @param  {Boolean} optIn
	 * @return {Promise}
	 */
	updateContactPreferences(optIn = true) {
		// annouce that we are loading
		this.dispatch(this.actions.UPDATE_PREFERENCES_START);
		// make the call
		// btw our API is crap and we are submitting the opposite of what you think, hence the !flag below
		return request.user.preferences.contact({ optin: { emailFlag: !optIn } }).then((res) => {
			return res.json();
		}).then((json) => {
			// if the response is in range
			if (json.statusCode >= 400 && json.statusCode < 600) {
				throw json;
			}
			// dispatch that the login was successful
			this.dispatch(this.actions.UPDATE_PREFERENCES_SUCCESSFUL, this);
		}).catch((e) => {
			// we've errored
			this.dispatch(this.actions.UPDATE_PREFERENCES_FAILURE, e);
		});
	}

	/**
	 * # Recently Browsed
	 * Fetch the user list of recently browsed item.
	 * 
	 * @return {Promise} Returns a promise which resolves with a list of products
	 */
	recentlybrowsed() {
		this.dispatch(this.actions.RECENTLY_VIEWED_START);
		return request.user.recentlybrowsed.list().then(res => res.json()).then((json) => {
			// if we don't have a valid respone throw an error
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}
			const data = json.response;
			// convert all the items into Items
			this._recentlyViewed = data.userItems.slice(0, 12);
			// tell everyone we have finished
			this.dispatch(this.actions.RECENTLY_VIEWED_SUCCESSFUL, this);
		}).catch((e) => {
			this.dispatch(this.actions.RECENTLY_VIEWED_FAILURE, e);
		});
	}

	savingAccount() {
		if (this.status === 'hard') {
			this.dispatch(this.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_START, this);
			return request.user.savingsaccounts.list({}).then((res) => {
				return res.json();
			}).then((json) => {
				if (json.statusCode >= 400 && json.statusCode < 600) {
					throw new Error(json.statusMessage);
				}
				if (json && json.response && json.response.accounts.length > 0) {
					const accountId = json.response.accounts[0].accountId;
					this.wineRewardsSummaryDetail(accountId);
				}
			}).catch((e) => {
				this.dispatch(this.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_FAILURE, e);
			});
		}
	}

	paymentOptions() {
		this.dispatch(this.actions.PAYMENT_OPTIONS_START);
		$.ajax({
            type: "GET",
            url: '/api/checkout/payment/paymentOptions',
            success: function(data) {
				this.dispatch(this.actions.PAYMENT_OPTIONS_SUCCESSFUL, data);
            }.bind(this),
            error: function(xhr, status, err) {
                this.dispatch(this.actions.PAYMENT_OPTIONS_FAILURE, err.toString());
            }.bind(this)
        });
	}

	wineRewardsSummaryDetail(accountId) {
		return request.user.savingsaccounts.detail({
			accountId,
		}).then((res) => {
			return res.json();
		}).then((json) => {
			if (json.statusCode >= 400 && json.statusCode < 600) {
				throw new Error(json.statusMessage);
			}
			if (json && json.response) {
				this._balance = json.response.balance.toFixed(2);
				this.dispatch(this.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_SUCCESS, this);
			}
		}).catch((e) => {
			this.dispatch(this.actions.SUBSCRIPTIONS_SAVINGS_ACCOUNT_FAILURE, e);
		});
	}

	recommendedwines() {
		this.dispatch(this.actions.RECOMMENDED_WINES_START);
		return request.user.recommendedwines.list({
			limit: 10,
		}).then(res => res.json()).then((json) => {
			// if we don't have a valid respone throw an error
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}
			const data = json.response;
			// convert all the items into Items
			this._recommendedWinesArr = data.userItems;
			// tell everyone we have finished
			this.dispatch(this.actions.RECOMMENDED_WINES_SUCCESSFUL, this);
		}).catch((e) => {
			this.dispatch(this.actions.RECOMMENDED_WINES_FAILURE, e);
		});
	}

	hsbcCard() {
		this.dispatch(this.actions.HSBC_COOKIECHECK_START);
		function getCookieValue(a) {
			var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
			return b ? b.pop() : '';
		}
		let cardType = getCookieValue('cardType');

		if ((cardType == null) || (cardType == '')) {
			this.dispatch(this.actions.HSBC_COOKIE_AVAILABLE);
		} else {
			this.dispatch(this.actions.HSBC_COOKIE_FAILURE);
		}

	}

	/**
	 * Logout the user
	 * @return {Promise}
	 */
	logout() {
		this.dispatch(this.actions.LOGOUT_START);
		return request.user.logout().then(() => {
			this.dispatch(this.actions.LOGOUT_SUCCESSFUL);
			return this.fetch();
		});
	}

	/** 
	 * Function: emailOptInModal
	 * Display a overlay allowing a customer to opt-in to emails
	 * Checks that the user is logged in and not opted in to receive emails
	 * If the user dismisses the modal or opts in a cookie is set to not
	 * display for 90 days 
	 */

	emailOptInModal() {
		// Verify that conditions are met to display the modal
		var getCookie = Cookies.get("emailOptInRemind");

		// Only display if cookie is not set and user is new and logged in
		if (getCookie !== 'true') {
			this.dispatch(this.actions.EMAIL_OPTIN_INIT);
			
		}
	}

	/** 
	 * Function: checkEmailOptIn
	 * validates if a customer has opted for emails
	 * if has denied already , will be reminded to opt for email.
	 **/

	updateMarketingPermissions() {
		if ((this.status === 'soft' || this.status === 'hard')) {
			return request.user.permissions.category({ category: "MARKETING" }).then((res) => {
				// decode the JSON
				return res.json();
			}).then((json) => {
				if (json.response && json.response.permissions) {
					dlmarketingPermissions(json.response.permissions);
				}
			}).catch((e) => {
				console.log(e);
			});
		}
	}

	/**
	 * Function: newsletterOptInModal
	 * Display a overlay allowing a customer to opt-in to emails
	 * If the user dismisses the modal or opts in a cookie is set to not
	 * display for 30 days 
	 */

	newsletterOptInModal() {
		if (window.dataLayer && window.dataLayer[0]) {
			var visitorTypeDetailed = dataLayer[0].visitorTypeDetailed;
			// Verify that conditions are met to display the modal
			if(Cookies){
				var getCookie = Cookies.get("delayNewsletterReminder");
			}
			// Only display if cookie is not set and user is new and logged in and if it is not the basket page
			// Newsletter Signup
			var pathArray = window.location.pathname.split('/');
			var secondLevelLocation = pathArray[1];
			// Do not load the popup on product pages
			if (getCookie != 'true' && visitorTypeDetailed == 'Unidentified' && window.location.pathname !== '/jsp/checkout/common/shoppingcart.jsp' && secondLevelLocation !== 'product') {
				this.dispatch(this.actions.NEWSLETTER_OPTIN_INIT);
			}
		}
	}

	/**
	 * [checkForActiveWineReward]
	 * Checks the User Profile to see if the customer
	 * has an open ,active Wine Reward Account.
	 * Sets a new boolean value for if both values are met
	 * @param  {[type]} data [API data response from user profile]
	 */
	checkForActiveWineReward(data) {
		if (data && data.userDetails && data.userDetails.profileClassifications && data.userDetails.profileClassifications.features && data.userDetails.profileClassifications.features.length > 0) {
			const features = data.userDetails.profileClassifications.features;
			if (features.includes("WINE_REWARD_SUBSCRIPTION_ACTIVE") && features.includes("WINE_REWARD_ACCOUNT_OPEN")) {
				return true;
			}
		}
		return false;
	}


	hasActiveSubscription(data) {
		data.trustedDetails.subscriptions.filter((sub) => {
			//checks if user have active subscription
			if ((sub.schemeId === 'UNLT' || sub.type === "WINEREWARD" || sub.schemeId === "PREM" || sub.schemeId === "LWGO" || (sub.schemeId === 'CP')) && (sub.status === 'ACTIVE')) {
				$(".check_subscription").removeClass('hide');
				$(".no_subscription").addClass('hide');
			}

			//checks if user have active wineplan subscription
			if (sub.type === "WINEPLAN" && (sub.status === 'ACTIVE' || sub.status === 'SUSPENDED')) {
				$(".check_wine_plan").removeClass('hide');
				$(".no_wine_plan").addClass('hide');
			}

			//checks if user have active rewards
			if (sub.type === "WINEREWARD" && sub.status === 'ACTIVE') {
				$(".js-wr-balance").removeClass('hide');
				if(this._brandObj.id === 'ba') {
					$("#top-links").addClass('rewards-customer');
				}
			}

			//checks if user have active storage subscription
			if (sub.type === 'STORAGE' && sub.status === 'ACTIVE') {
				$(".check_storage").removeClass('hide');
			}

			if (sub.schemeTypes) {
				if (sub.schemeTypes.indexOf('FreeShip') > -1  && sub.status === 'ACTIVE') {
					$('#shopping-basket-container .unlimited-container').css('display', 'none');
				}else{
					$('#account-container .unlimited-container').css('display', 'block');
				}
			}
		});
	}

	/**
	 * Map the data returned from the API to the user Model
	 *
	 * @private
	 * @param  {object} data data to be maped
	 */
	_map(data) {
		data = data.response;
		let subscriptions = this._subscriptions;

		if (data.trustedDetails && data.trustedDetails.subscriptions) {
			subscriptions = data.trustedDetails.subscriptions;
			this._findActiveSubscription = this.hasActiveSubscription(data);
		}
		if (data.userDetails?.freeShipProfile === false) {
			$('#account-container .unlimited-container').css('display', 'block');
		}
		if (data.securityStatus > 0) {
			// basic information
			this._securityStatus = data.securityStatus;
			this._title = data.summaryDetails.salutation;
			this._email = data.summaryDetails.emailAddress;
			this._firstname = data.summaryDetails.firstName;
			this._lastname = data.summaryDetails.lastName;
			this._freeShipProfile = data.userDetails.freeShipProfile;
			this._freeItemProfile = data.userDetails.freeItemProfile;
			this._isWineReward = this.checkForActiveWineReward(data);
			this._preferences = data.userDetails.userPreferences;
			this._customerNumber = data.summaryDetails.brandAccountNumber;
			this._profileId = data.summaryDetails.profileId;
			this._subscriptions = subscriptions;
			this._emailOptInStatus = !['stw', 'law', 'hsb', 'avy', 'bbc'].includes(this._brandObj.id) ? this.checkEmailOptIn() : '';
		} else {
			this._securityStatus = data.securityStatus;
			this._title = false;
			this._email = false;
			this._firstname = false;
			this._lastname = false;
			this._freeShipProfile = false;
			this._freeItemProfile = false;
			this._isWineReward = false;
			this._findActiveSubscription = false;
			this._preferences = false;
		}	
		
	}
}

export default new UserModel();