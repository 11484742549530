import Event from 'dw-events';
// port id from './id';

/**
 * Generic error logging
 * @param  {[type]} type   [description]
 * @param  {[type]} action [description]
 * @param  {[type]} label  [description]
 * @return {[type]}        [description]
 */
export default (type, action, label) => {
	if (process.env.NODE_ENV === 'development') {
		// only log to the console if we are in development mode
		console.log(type, action, label);
	}

	if (process.env.NODE_ENV === 'production') {
		// log the error/message to GA
		new Event(type, {
			category: type,
			action: action,
			label: label
		});
	}
}