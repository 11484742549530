import Cookies from 'js-cookie';

export const getUpsellCookie = (cookie) => {
    if (Cookies) {
        return Cookies.get(cookie)
    }
}

export const setUpsellCookie = () => {
    if (Cookies) {
        const upsellCookie = Cookies.get("UpsellRejectionCount");

        if (upsellCookie === undefined) {
            Cookies.set('UpsellRejectionCount', '1');
        } else {
            let value = Cookies.get('UpsellRejectionCount')
            ++value;
            Cookies.set('UpsellRejectionCount', value);
        }
    }
}

export const removeCookie = (cookie) => {
    Cookies.remove(cookie)
}