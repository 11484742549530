import Modal from "../../modal/modal";
import event from "../../../utilities/event";
import Cart from '../../../models/cart/cart';
import { templateForUnlimited, UnlimitedLearnMore } from '../templates';
import { addUpsellToOrder, rejectUpsellToOrder } from '../request';
import getBrand from 'dw-brand-identify';

const brandObj = getBrand();
const termsLink = (brandObj.id === 'law' || brandObj.id === 'bbc') ?
                    '/customer-services/customer-terms-and-conditions-of-sale-and-use#terms-and-conditions' :
                    '/customer-services/terms-and-conditions-of-sale-and-use#terms-and-conditions';

export const openUnlimitedModal = (transactionId, upsell, salePrice) => {
    const CartTotal = Cart._total;
    const savings = (dataLayer[0].transactionShipping !== undefined && dataLayer[0].transactionShipping !== 0) ? dataLayer[0].transactionShipping : 7.99;
    const offerPrice = (dataLayer[0].transactionShipping == undefined || dataLayer[0].transactionShipping !== 0) ? "£" + (salePrice - parseFloat(savings)).toFixed(2) : "£" + salePrice;
    const UnlimitedModal = new Modal({
        title: (offerPrice === '£0.00') ? 'Claim unlimited delivery for free' : brandObj.id === 'law'? 'FANCY NOT PAYING FOR DELIVERY?' : 'Get Unlimited Delivery',
        body: templateForUnlimited(CartTotal, salePrice),
        containsForm: true,
        showFooter: false,
        largeModal: true,
        id: 'postUpsellUnlimited'
    });

    // Open the modal
    UnlimitedModal.open();
    event('Pop-ups', 'Post Upsell Unlimited', 'Post Upsell Unlimited opened');

    const signUpButton = document.getElementById('signUp');
    if (typeof (signUpButton) !== 'undefined' && signUpButton !== null) {
        signUpButton.addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell Unlimited', 'Post Upsell Unlimited added to order');
            if (document.querySelector('input#futurePayments').checked) {
                addUpsellToOrder(transactionId, upsell);
            } else {
                const errorContainer = document.getElementById('error-container');
                errorContainer.innerHTML = `Please confirm you are happy to be charged for future payments by checking the box above.`;
                errorContainer.style.display = "block";
            }
        });
    }
    const termsButton = document.getElementById('termsLink');
    if (typeof (termsButton) !== 'undefined' && termsButton !== null) {
        document.getElementById('termsLink').addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell Unlimited', 'Clicked through from Post Upsell Unlimited to terms page');
            window.open(termsLink,"_blank");
        });
    }

    document.getElementById('noThanks-mob').addEventListener('click', (e) => {
        rejectUpsellToOrder(transactionId);
        UnlimitedModal.close();
        sessionStorage.setItem('PostUpsellRejected', 'true');
    });

    const learnMoreModal = new Modal({
        title: 'Future Payments',
        body: UnlimitedLearnMore(),
        containsForm: true,
        showFooter: false,
        largeModal: true,
        id: 'UnlimitedLearnMore'
    });

    const learnMore = document.getElementById('learnMore');
    if (typeof (learnMore) !== 'undefined' && learnMore !== null) {
        document.getElementById('learnMore').addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell Unlimited', 'Clicked through from Post Upsell Unlimited LearnMore link');
            learnMoreModal.open();
            var targetCloseDiv = document.getElementById('UnlimitedLearnMore').getElementsByClassName('close')[0];
            if(targetCloseDiv){
                var newTag = document.createElement('a');
                //just setting html in it
                newTag.innerHTML = 'x';
                targetCloseDiv.replaceWith(newTag);
                newTag.classList.add('closeLearnMore');
                if (document.querySelector('.closeLearnMore')) {
                    document.querySelector('.closeLearnMore').addEventListener('click', (e) => {
                        var element = document.getElementById('UnlimitedLearnMore');
                        $(element).remove();
                    });
                }
            }
        });
    }
}