import Component from '../component';
import User from '../../models/user/user';

/**
 * Optin
 * This class will render a small button which allows the user to opt in to our
 * marketing communications.@async
 *
 * ## Usage
 * `new Optin(CONTAINER)`
 * 
 * ## Params
 * `container` - The containing element the optin button will be appended to
 * `properties` -
 * `- text: The string for the text`
 * `- successMessage: The string for the success messaging
 *  - onSuccess: function
 *  - onFailure: function
 */
class Optin extends Component {
	constructor(container, properties) {
		super();
		// default properties
		this._properties = {
			text: 'Sign me up',
			successMessage: 'Signed up',
			onSuccess: () => {},
			onFailure: () => {},
			...properties
		}
		this._container = container;

		// it's starting
		User.subscribe(User.actions.UPDATE_PREFERENCES_START, () => {
			this._button.classList.remove('error');
			this._button.classList.add('loading');
		});
		// it worked
		User.subscribe(User.actions.UPDATE_PREFERENCES_SUCCESSFUL, () => {
			this._button.classList.remove('loading');
			this._button.classList.add('disabled');
			this._button.innerHTML = this._properties.successMessage;
			this._properties.onSuccess();
			this.removeEvent(this._event);
		});
		// it failed
		User.subscribe(User.actions.UPDATE_PREFERENCES_FAILURE, () => {
			this._button.classList.add('error');
			this._properties.onFailure();
		});
		// render the button
		this.render();
	}

	/**
	 * Update the users preferences
	 * @param  {Boolean} optin
	 * @return#
	 */
	updatePreferences() {
		// this is true but we are reversing it in the model
		return User.updateContactPreferences(true);
	}

	/**
	 * Render the button
	 */
	render() {
		if (this._button === undefined) {
			// create the button
			this._button = document.createElement('button');
			this._button.type = 'button';
			this._button.className = 'btn btn-primary';
			this._button.innerHTML = `<span></span>${this._properties.text}`;
			// add the event
			this._event = this.addEvent(this._button, 'click', this.updatePreferences.bind(this));
			// add the button into the container
			this._container.appendChild(this._button);
		}
	}
}

export default Optin;