import Component from "../component";
import { $ } from "../../utilities/min";
import Search from "../../models/search/search";

class SearchView extends Component {
	constructor() {
		super(".desktop-search-panel");

		this.search = this.el.find('input[type="text"]');
		this.autosuggestPanel = this.el.find("ul.autosuggest");
		//this.advancedFiltersButton = this.el.find('strong.qpf-toggle');
		// this.quickProductFinder = this.el.find('.qpf');
		this.facetsPanel = this.el.find(".facets");
		this.selectedFacets = this.el.find(".selected-facets");
		this.submitButton = this.el.find('button[type="submit"]');
		this.closeButton = this.el.find(".close");
		this.openButton = this.el.find(".open");
		this.clearSearch = this.el.find(".clear-search");
		this.parentNode = this.el.parentNode;


		this.addEvent(this.search, "click", this.open.bind(this));
		this.addEvent(this.search, "focus", this.open.bind(this));
		// this.addEvent(this.submitButton, "click", this.open.bind(this));
		this.addEvent(this.search, "keyup", this.autocomplete.bind(this));
		this.addEvent(this.search, "cut", this.onCutandPaste.bind(this));
		this.addEvent(this.search, "paste", this.onCutandPaste.bind(this));
		this.addEvent(this.closeButton, "click", this.close.bind(this));
		this.addEvent(this.el, "submit", this.submit.bind(this));
		this.addEvent(this.clearSearch, "click", this.clear.bind(this));

		this.addEvent(this.openButton, "click", this.open.bind(this));
		// a counter for keeping track of the item which is selected going up and down
		this._selectedPosition = null;
	}

	onCutandPaste(e) {
		setTimeout(()=>{
			this.autocomplete(e);
		},100,e);
	}
	/**
	 * Autocomplete
	 * Shows a dropdown of possible products from 2 characters. The dropdown
	 * supports up/down arrows and the enter button
	 *
	 * @param  {Event} e
	 */
	autocomplete(e) {
		// grab all the options
		let suggestedItems = Array.prototype.slice.call(
			this.autosuggestPanel.querySelectorAll("li")
		);
		// check for key presses
		if (e) {
		switch (e.keyCode) {
			case 27:
				// if they are in the auto suggest, hide the autosuggest box
				if (this.autosuggestPanel.style.display === "block") {
					this.autosuggestPanel.style.display = "none";
					document.getElementsByTagName("html")[0].style.overflow = "initial";
				} else {
					// otherwise close the whole search popup
					this.close();
				}
				return;
			case 38:
				// up arrow
				if (this._selectedPosition === null) {
					this._selectedPosition = suggestedItems.length - 1;
				} else if (this._selectedPosition === 0) {
					this._selectedPosition = suggestedItems.length - 1;
				} else {
					this._selectedPosition--;
				}
				e.preventDefault();
				break;
			case 40:
				// down arrow
				if (this._selectedPosition === null) {
					this._selectedPosition = 0;
				} else if (
					this._selectedPosition ===
					suggestedItems.length - 1
				) {
					this._selectedPosition = 0;
				} else {
					this._selectedPosition++;
				}
				e.preventDefault();
				break;
			case 13:
				// pressing enter
				if (this._selectedPosition !== null) {
					window.location = suggestedItems[this._selectedPosition]
						.querySelector("a")
						.getAttribute("href");
				}
				break;

			default: {
				// reset the counter
				this._selectedPosition = null;
			}
		}
		}

		// if we have more than 2 characters
		if (this.search.value.length >= 2) {
			// add the loading spinner
			this.autosuggestPanel.classList.add("loading");
			// wipe the contents
			this.autosuggestPanel.innerHTML = "";
			// fetch the suggestions
			Search.searchSuggest(this.search.value)
				.then(suggestions => {
					// if we dont have any suggestions don't do anything
					if (suggestions.length === 0) {
						this.autosuggestPanel.style.display = "none";
						return;
					}

					const list = suggestions.map((s, i) => {
						return `
						<li class="${i === this._selectedPosition ? "selected" : ""}">
							<a href="${s.i.indexOf("/") == -1 ? "/product/" + s.i : s.i}">${s.n}</a>
						</li>`;
					});
					// remove the loading spinner
					this.autosuggestPanel.classList.remove("loading");
					// add the list
					this.autosuggestPanel.innerHTML = list.join("");
					// show the panel
					this.autosuggestPanel.style.display = "block";
					document.getElementsByTagName("html")[0].style.overflow = "hidden";
				})
				.catch(e => {
					this.autosuggestPanel.style.display = "none";
				});
		} else {
			this.autosuggestPanel.style.display = "none";
			this.removeEvent(this._autosuggestEsc);
			document.getElementsByTagName("html")[0].style.overflow = "initial";
		}
	}

	submit(e) {
		if (this._selectedPosition !== null) {
			e.preventDefault();
		}
	}
	clear(e){
		this.search.value = '';
		this.autosuggestPanel.style.display = "none";
		this.removeEvent(this._autosuggestEsc);
	}

	close() {
		this.autosuggestPanel.style.display = "none";
		document.getElementsByTagName("html")[0].style.overflow = "initial";
		// this.quickProductFinder.style.display = 'none';
		this.el.classList.remove("focus");
		this.el.classList.add("d-none");
		this.background.parentNode.removeChild(this.background);
		this.parentNode.appendChild(this.el);
		this.el.classList.remove("container");
		this.search.setAttribute("placeholder", "Search...");
		this.search.removeAttribute("autofocus");
		document
			.querySelector("#new-homepage-test-header")
			.classList.remove("searchOpen");
	}

	open(e) {
		if (this.el.classList.contains("focus")) {
			return;
		}
		if (e) {
			e.preventDefault();
		}
		this.el.classList.add("focus");
		this.el.classList.remove("d-none");
		this.background = document.createElement("div");
		this.background.className = "search-background";
		this.background.addEventListener("click", this.close.bind(this));
		document.body.appendChild(this.background);
		document.body.appendChild(this.el);
		this.el.classList.add("container");
		// UXE-3944 - make sure we focus
		this.focus();
		this.search.setAttribute("placeholder", "e.g. Rioja");
		this.search.setAttribute("autofocus",  "true");
		this.search.focus();
		document
			.querySelector("#new-homepage-test-header")
			.classList.add("searchOpen");

		if (this.search.value !== '' || this.search.value.length >= 2) {
			this.autocomplete(e);
		}
	}

	toggle() {
		if (this.el.classList.contains("focus")) {
			this.close();
		} else {
			this.open();
		}
	}

	focus() {
		window.scrollTo(0, 0);
		setTimeout(() => {
			this.search.focus();
		}, 100);
	}

	toggleFilters(link = false) {
		const FACETS = [
			"wine type",
			"country",
			"region",
			"grape variety",
			"price"
		];

		this.quickProductFinder.classList.add("loading");
		this.facetsPanel.innerHTML = "";
		this.quickProductFinder.style.display = "block";

		// load up the search
		Search.fetchFacets("", link).then(results => {
			let facets = results.facets;
			let total = results.total;
			let button = this.el.querySelector("p a");

			this.quickProductFinder.classList.remove("loading");

			button.innerText = `${total} Result${total === 1 ? "" : "s"}`;

			if (link === false) {
				button.href = "#";
				button.classList.add("disabled");
			} else {
				button.href = link;
				button.classList.remove("disabled");
			}

			if (Object.keys(facets).length === 0) {
				// show a warning that there are 0 results
				return;
			}

			Object.keys(facets).forEach(key => {
				if (FACETS.indexOf(key.trim().toLowerCase()) === -1) {
					// skip everything that isn't in a required facet
					console.log(key);
					return;
				}

				let li = document.createElement("li");
				let h3 = document.createElement("h3");
				h3.innerHTML = key;
				li.appendChild(h3);

				h3.addEventListener("click", () => {
					h3.closest("li").classList.toggle("show");
				});

				let ul = document.createElement("ul");
				facets[key].forEach(item => {
					let facet = document.createElement("li");
					facet.innerHTML = item.title;
					facet.setAttribute("data-link", item.href);
					facet.addEventListener("click", e => {
						this.toggleFilters(e.target.getAttribute("data-link"));
						let selectedFacet = document.createElement("li");
						selectedFacet.innerHTML = item.title.slice(
							0,
							item.title.indexOf("(")
						);
						selectedFacet.setAttribute(
							"data-link",
							link ? link : ""
						);
						selectedFacet.addEventListener("click", e => {
							this.toggleFilters(
								e.target.getAttribute("data-link")
							);
							selectedFacet.parentNode.removeChild(selectedFacet);
						});
						this.selectedFacets.appendChild(selectedFacet);
					});
					ul.appendChild(facet);
				});

				li.appendChild(ul);
				this.facetsPanel.appendChild(li);
			});
		});
	}
}

export default SearchView;
