/**
 * Parallax component takes the parallax banner data and injects
 * it into the top-parallax id tag and the bottom-parallax id tag,
 * if either of them top or bottom banner data sets are missing
 * then the id tag is left empty.
 */

import Component from '../component';

class Parallax extends Component {
  constructor(data, topBannerEl, bottomBannerEl, element) {
    super(element);
    this.loadData(data, topBannerEl, bottomBannerEl);
  }

  parallaxTemplate(
    dataSection,
    dataOffer,
    urlDesktop,
    urlTablet,
    urlMobile,
    alt,
    backgroundColour,
    link
  ) {
    const colourClassname = `contentful-${backgroundColour.toLowerCase().replaceAll(' ', '-')}`

    return `
    <section class="giftbanner ${colourClassname} flex-grow-1">
      <div class="container p-0">
        <div class="row m-0">
          <div class="col-12 p-0">
            <a ${link} data-section="${dataSection}" data-offer="${dataOffer}">
              <picture>
                <source
                  srcset="${urlMobile}"
                  media="(max-width: 768px)">
                  <source srcset="${urlTablet}" media="(min-width: 768px) and (max-width: 992px)">
                <img src="${urlDesktop}" class="w-100"
                  alt="${alt}">
              </picture>
            </a>
          </div>
        </div>
      </div>
    </section>
  `;
  }
  /**
   * This function uses the banners from a getByElementId query
   * and loops through, injecting the data from bannerData if present.
   *
   * @param  {Array} banners
   * @param  {Array} bannerData
   */
  loadParallax(banners, bannerData) {
    banners.forEach((banner, index) => {
      if (bannerData[index]) {
        const section = index === 0 ? 'Top Parallax' : 'Bottom Parallax';
        
        const { entryTitle: offer } = bannerData[index]?.fields;
        
        const {
          accessibilityText: alt,
          asset: {
            fields: {
              file: { url: desktopImageUrl },
            },
          },
        } = bannerData[index]?.fields?.desktopImage?.fields;

        const { url: tabletImageUrl } =
          bannerData[index]?.fields?.tabletImage?.fields?.asset?.fields?.file;
        const { url: mobileImageUrl } =
          bannerData[index]?.fields?.mobileImage?.fields?.asset?.fields?.file;

        if (desktopImageUrl && tabletImageUrl && mobileImageUrl) {
          const { backgroundColour } = bannerData[index]?.fields;

          let linkUrl = '';
          const linkPath = bannerData[index]?.fields?.link?.fields;

          if (linkPath) {
            const { url: link } = linkPath;
            link ? (linkUrl = 'href=' + link) : '';
          }

          banner.innerHTML = this.parallaxTemplate(
            section,
            offer,
            desktopImageUrl,
            tabletImageUrl,
            mobileImageUrl,
            alt,
            backgroundColour,
            linkUrl
          );
        }
      }
    });
  }
  /**
   * loadData is responsible for pulling the data into the component
   * and destructuring it for use in the loadParallax function.
   *
   * @param  {JSON} data
   * @param  {String} topBannerEl
   * @param  {String} bottomBannerEl
   */
  loadData(data, topBannerEl, bottomBannerEl) {
    const { topBanner, bottomBanner } = data;
    const topParallaxBanner = document.getElementById(topBannerEl);
    const bottomParallaxBanner = document.getElementById(bottomBannerEl);

    const parallaxBanners = [topParallaxBanner, bottomParallaxBanner];
    const bannerData = [topBanner, bottomBanner];

    this.loadParallax(parallaxBanners, bannerData);
  }
}

export default Parallax;
