import Component from "../component";
import request from '../../utilities/request';
import Modal from "../modal/modal";
import Cart from "../../models/cart/cart.js";
import getBrand from "dw-brand-identify";

class CaseTabbedOffer extends Component {
    constructor() {
        super("div.case-tabbed-offer");

        this.recruitWines = [];
        this._template = this.el.querySelector('.template');
        this.OgilvyPage = $('#OgilvyPage').length === 1 ? true : false;

        // Get the data from the HTML data tag and pass to API
        let showTermsLink = $("#wines").data("tcs-link");
        this._brandObj = getBrand();
        const termsLink = (this._brandObj.id === 'law' || this._brandObj.id === 'bbc') ?
                            '/customer-services/customer-terms-and-conditions-of-sale-and-use#wine-and-spirit-plans' :
                            '/customer-services/terms-and-conditions-of-sale-and-use#wine-and-spirit-plans';
        this.displayShowTermsLink = showTermsLink === true ? `<div class="terms-link"><a href="${termsLink}">Read full Terms & Conditions</a></div>` : '';
        this.tourType = '';
        this.choiceItem = '';
        const winesFromHTMLDataTag = $("#wines ").data("items").split(',');        
        const winesForAPI = {
            ids: winesFromHTMLDataTag
        }
        this.loadWines(winesForAPI);

        // Listen for when the API has found all the wines
        document.addEventListener('wines-loaded', e => {
            this.render();
        }, false);      
        
    }

    loadWines(winesForAPI) {
        const getWines = async ({ ids }) => {
            // Loop over each item in the array and
            // and fetch the data from the API
            const wines = ids.map(async id => {
                const data = await request.product.get({
                    itemcode: id
                }).then(res => res.json()).then((json) => {
                    return json;
                }).then((json) => {
                    // if the response is in range
                    if (json.statusCode >= 400 && json.statusCode < 600) {
                        throw json;
                    }
                    return json.response;
                }).catch((e) => {
                    // we've errored
                    console.log('error', e.errorResponse.message, `for item ${id}`);
                    return {
                        error: true,
                        msg: e.errorResponse.message
                    };
                });
                return data;
            });

            // Once the loop is finshed resolve the promise
            return Promise.all(wines);
        }

        // Start a async function to get all the API data
        // passed in from our array
        (async () => {
            const wineDataForUI = await getWines(winesForAPI);

            // Update the recruit wines array with all the data
            // from the API
            this.recruitWines = wineDataForUI;

            // Dispatch an event to tell our component that
            // the data is ready and we can now render the template
            document.dispatchEvent(new CustomEvent("wines-loaded", {
                bubbles: true,
                cancelable: false,
            }));
        })();
    }

    getCase(itemcode, openModal) {
        async function getUserAsync(itemcode) {
            let response = await request.product.case({
                itemcode,
            });
            let data = await response.json()
            return data;
        }

        getUserAsync(itemcode)
            .then(data => {
                this.caseContentsTemplate(data.response, openModal);
            })
            .catch((e) => {
                // we've errored
                console.log('error', e);
            });
    }

    caseContentsTemplate(data, openModal) {
        let template = [];
        let winesincase = [];
        let dropdownOptions = [];
        let rating = []; 
        let tourType = '';
        if(this.tourType === 'half'){
            tourType = (
                `<h2>Half tour</h2>
                <p class="sub-heading">6 bottles every 12 weeks</p>`
            )
        }else if(this.tourType === 'full'){
            tourType =(
                `<h2>Full tour</h2>
                <p class="sub-heading">12 bottles every 12 weeks</p>`
            )
        }else if(this.tourType === 'grand'){
            tourType =(
                `<h2>Grand tour</h2>
                <p class="sub-heading">12 bottles every 8 weeks</p>`
            )
        }
        let giveAwayContainer = (
            `<div class="intro panel-offwhite give-away-container">
            <h2>Your welcome gifts</h2>
            <p>This is our way of saying "Welcome to the family". Subscribe to a wineplan today and enjoy your free <br/>gifts on your first purchase.
            Limited availablility offered.</p>
            <div class="clear"></div>
            <div class="container col-sm-12 give-aways-info">
                <div class="col-sm-6 no-pad-right">
                    <div class="info-container">
                        <img class="panel-img" style="width:224px;margin-bottom:0" src="https://uat.laithwaites.co.uk/images/uk/en/law/product/M14232.png"/>
                        <div class="green-bg">
                            <p>Free delivery on your first case</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 info-2-col">
                    <div class="info-container info-2">
                        <div class="green-bg">
                            <p>Enjoy a family discount of up to &#163;70 off on your first case	</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 no-pad-right mt-15">
                    <div class="info-container">
                        <img class="panel-img" style="width:268px;margin-bottom:0" src="https://uat.laithwaites.co.uk/images/uk/en/law/wineplans/29-sep-2021/prosecco.png"/>
                        <div class="green-bg">
                            <p>Free Prosecco</p>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 mt-15">
                    <div class="info-container">
                        <img class="panel-img" style="width:212px;margin: 48px 0;" src="https://uat.laithwaites.co.uk/images/uk/en/law/wineplans/29-sep-2021/tumblers.png"/>
                        <div class="green-bg">
                            <p>A set of handmade british crystal tumblers </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
            <a class="btn btn-primary btn-sm js-add-cart hide" data-itemcode="${this.choiceItem}" data-price="32.50" data-quantity="1" href="#">Subscribe now</a>
        </div>`
        )  

        winesincase = data.contentProducts.map(wine => {
            if (wine.referencedProduct.ratingDetails && wine.referencedProduct.ratingDetails.productRating) {
                rating = (
                    `<span class="stars stars-small rating-${wine.referencedProduct.ratingDetails.productRating.roundAvgRating}Star"></span>`
                )
            }
            
            const grapeName = wine.referencedProduct.grapeName ? (
                `<li>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
                            <g fill="#333" fill-rule="evenodd">
                                <path d="M12.356 10.355a.959.959 0 0 1-.952-.963c0-.531.427-.963.952-.963.525 0 .953.432.953.963a.959.959 0 0 1-.953.963zm-.938-5.526c-.775.55-1.818.55-2.594 0l-.05-.036c.822-.609 1.844-.613 2.669-.01l.02.014-.045.032zm1.866 2.52a.515.515 0 0 1-.297-.468V4.788h-.002a1.06 1.06 0 0 0-.431-.841l-.338-.247c-1.285-.94-2.942-.933-4.222.016l-.314.233a1.06 1.06 0 0 0 .018 1.712l.368.262a3.549 3.549 0 0 0 3.22.457c.21-.073.43.081.43.306v.2c0 .2-.115.381-.294.465a2.255 2.255 0 0 0-1.283 2.217c.084 1.098.96 1.984 2.046 2.066 1.303.1 2.395-.945 2.395-2.242 0-.906-.532-1.687-1.296-2.044zM9.743 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M12.373 16.848a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207A2.21 2.21 0 0 1 12.373 20M15.097 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M17.445 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207"></path>
                                <path d="M7.207 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152A2.21 2.21 0 0 1 5 9.34a2.21 2.21 0 0 1 2.207-2.207A2.21 2.21 0 0 1 9.414 9.34a2.21 2.21 0 0 1-2.207 2.207"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="text">${wine.referencedProduct.grapeName}</div>
                </li>`
            ) : '';

            const countryName = wine.referencedProduct.countryName ? (
                `<li>
                    <div class="icons">
                        <img src="/images/uk/en/common/icons/flags/${wine.referencedProduct.countryName}.png" class="js-default-image-handler" alt="${wine.referencedProduct.countryName}" title="${wine.referencedProduct.countryName}" />
                    </div>
                    <div class="text">${wine.referencedProduct.countryName}</div>
                </li>`
            ) : '';

            return (
                `
                <div class="item">
                    <div style="display: flex">
                        <div class="image">
                        <img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${wine.referencedProduct.smallImage}" /></div>
                        <div class="detail">
                            <h4>${wine.referencedProduct.name} ${wine.referencedProduct.vintage ? wine.referencedProduct.vintage : ''}</h4>
                            ${rating}
                            ${wine.referencedProduct.productType === 'wine' ? (`<p>x ${wine.quantity} ${wine.quantity > 1 ? 'bottles' : 'bottle' }</p>`) :
                            (`<p>x ${wine.quantity}</p>`) }
                            <ul class="detail-table">
                                ${grapeName}
                                ${countryName}
                            </ul>
                            <p>${wine.referencedProduct.webHeadline}</p>
                        </div>
                    </div>
                </div>
                `
            )
        }).join(' ');

        dropdownOptions = this.recruitWines.map(wine => {
            return (
                `<option value="${wine.itemCode}">${wine.name}</option>`
            )
        }).join(' ');

        template = this.OgilvyPage == true ? 
        (`<div>
            <div class="selection-container">
                ${tourType}
                <p>Top introduction to a range of grape varieties and wine styles from across the world + free Prosecco </p>
                <p>Season's Favourites+ 4 tumblers</p>
            </div>
            <div id="modal-wines-in-case" class="case-container">            
                ${winesincase}
            </div> 
            ${giveAwayContainer}
        </div>`)
        : (`
            <div>
                <div class="selection-container">
                    <div id="case-webheadline">${data.parentProduct.webHeadline ? data.parentProduct.webHeadline : ''}</div>
                    <div>
                        <select id="case-options" class="form-control">
                            ${dropdownOptions}
                        </select>
                    </div>
                </div>
                <div id="modal-wines-in-case" class="case-container">            
                    ${winesincase}
                </div> 
            </div>            
        `);

        // Trigger the modal or update the modal
        // based on the 'openModal' param
        if (openModal) {
            this.modal = new Modal({
                title: this.OgilvyPage == true ? '': data.parentProduct.name,
                body: template,
                containsForm: true,
                showHeader: this.OgilvyPage == true ? false : true,
                showFooter: false,
                largeModal: true,
                id: 'case-contents',
            });

            // Open the modal
            this.modal.open();
            this.modalActions(this.modal, data.parentProduct.itemCode);
        } else {
            this.modal.el.find('.modal-body').classList.remove('loading');
            this.modal.el.find('.modal-title').innerHTML = data.parentProduct.name;
            this.modal.el.find('#case-webheadline').innerHTML = data.parentProduct.webHeadline ? data.parentProduct.webHeadline : '';
            this.modal.el.find('#modal-wines-in-case').innerHTML = winesincase;
        }
    }

    modalActions(modal, selectedItem)  {
        const selectElement = modal.el.find('#case-options');
        if(selectElement !== undefined){
            // Lets set the correct case to the selected option
            selectElement.value = selectedItem;

            selectElement.addEventListener('change', (event) => {
                this.getCase(event.target.value, false);
                this.modal.el.find('.modal-body').classList.add('loading');
            });
        }
    }

    template() {
        let tabs = [];
        let tabContent = [];

        tabs = this.recruitWines.map(wine => {
            if (!wine.error) {
                return (
                    `<li class="nav-item">
                        <a class="nav-link" href="#" id="${wine.itemCode}">${wine.name}</a>
                    </li>`
                )
            } else {
                return;
            }

        }).join(' ');

        tabContent = this.recruitWines.map(wine => {
            let purchaseItem = [];
            let roundal = [];
            let saving = [];

            const webHeadline = wine.webHeadline ? wine.webHeadline : '';

            if (wine.skus && wine.skus.length > 0) {                
                if (wine.skus[0].salePrice < wine.skus[0].listPrice) {
                    roundal = (`<div class="roundal"><span class="just">Just</span> <span class="price">£${wine.skus[0].salePricePerBottle}</span> <span class="bottle">a bottle</span></div>`);

                    const discount = wine.skus[0].listPrice - wine.skus[0].salePrice;
                    const formatDiscount = discount.toFixed(2);
                    saving = (`<span class="saving">(saving £${formatDiscount})</span>`);
                }
                purchaseItem = (`
                    <p class="price">${wine.skus[0].numberOfBottles} bottles - <span class="bold">£${wine.skus[0].salePrice}</span> ${saving}</p>
                    <div><button class="btn btn-primary btn-block js-add-cart" data-itemcode="${wine.itemCode}" data-price="${wine.skus[0].salePrice}" data-quantity="1">Add to basket</button></div>
                `)
            } else {
                purchaseItem = (`
                    <div class="alert alert-info" role="alert">
                        Out of stock
                    </div>
                `)
            }
            if (!wine.error) {                
                const colourName = wine.colourName ? wine.colourName.toLowerCase() : '';
                return (
                    `
                    <div class="tab-pane" id="${wine.itemCode}">
                        <div class="case">
                            <div class="image ${colourName}">
                                <div><img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_case.png';" src="${wine.smallImage}" /></div>
                                ${roundal}
                            </div>                            
                            <div class="detail">
                                <h3>${wine.name}</h3>
                                <p>${webHeadline}</p>
                                <p><a href="#" class="js-whats-in-the-case emp" data-itemcode="${wine.itemCode}">What's in the case?</a></p>
                                ${purchaseItem}
                                ${this.displayShowTermsLink}                                
                            </div>
                        </div>
                    </div>
                `)
            }

        }).join(' ');
        return (`
            <ul class="nav nav-tabs">${tabs}</ul>
            <div class="tab-content">${tabContent}</div>
        `);
    }

    render() {
        if(this._template !== null){
            this._template.innerHTML = this.template();
        }

        // Get all the case details buttons, assign an event to
        // trigger a modal on click
        const caseDetailsButtons = this.OgilvyPage == true ? document.querySelectorAll('.js-whats-in-the-case') : Array.from(document.querySelectorAll('.js-whats-in-the-case'));
        caseDetailsButtons.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                const itemCode = e.target.getAttribute('data-itemcode');
                this.tourType = e.target.getAttribute('data-tour');
                this.choiceItem = itemCode;
                this.getCase(itemCode, true);

            });
        });

        const cartButtons = this.OgilvyPage == true ? document.querySelectorAll('a.js-add-cart') : Array.from(document.querySelectorAll('button.js-add-cart'));
        cartButtons.forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();

                const finished = () => {
                    e.target.classList.remove('loading');
                    e.target.innerText = 'Added to basket';
                }

                const code = e.target.getAttribute('data-itemcode');
                const price = e.target.getAttribute('data-price');
                const quantity = e.target.getAttribute('data-quantity');
                // add the item to the cart, this will fire a render
			    Cart.addItem(code, parseInt(quantity, 10), price);

                Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			    Cart.subscribe(Cart.actions.ADD_FAILURE, finished);

                e.target.classList.add('loading');
		        e.target.innerText = 'Adding...';                
            });
        });

        const tabs = Array.from(this.el.querySelectorAll('.nav-item'));
        const tabContent = Array.from(this.el.querySelectorAll('.tab-pane'));

        if(tabs !== undefined){

            // Open the first tab on first render
            tabs[0].find('.nav-link').classList.add("active");
            tabContent[0].classList.add("active");
            
            tabs.forEach((btn) => {
                btn.addEventListener("click", (e) => {
                    e.preventDefault();
                    console.log(e.target);
    
                    const itemCode = e.target.id;
    
                    // Lets clear any active classes
                    [].forEach.call(tabs, (el) => {
                        el.find('.nav-link').classList.remove("active");
                    });
    
                    [].forEach.call(tabContent, (el) => {
                        el.classList.remove("active");
                    });
    
                    // Lets add an active class to the clicked element                
                    e.target.classList.add("active");
    
                    // Lets show the correct content for the tab 
                    const content = tabContent.find(element => element.id === itemCode);
                    content.classList.add("active");
                });
            });    
        }  
    }
}
export default CaseTabbedOffer;