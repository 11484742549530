import Component from "../component";
import Template from "./template.js";

class TastingNoteView extends Component {
	constructor() {
		super();

		this._tastingnote = new Template();

		const tooltip = document.getElementById('info-tip');
		tooltip?.addEventListener("mouseover", function (e) {
			e.preventDefault();
			if ($(window).width() < 768) {
				$('[data-toggle="tooltip"]').tooltip({html: true, placement: "bottom" });
			} else {
				$('[data-toggle="tooltip"]').tooltip({html: true, placement: "right" });
			}
		})

		const noteButton = Array.from(document.querySelectorAll('.js-open-tasting-note'));
		noteButton.forEach((btn) => {
			btn.addEventListener("click", function (e) {
				e.preventDefault();
				// Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
				const event = new CustomEvent('open-tasting-note', {
					bubbles: true,
					detail: btn.getAttribute('data-itemcode')
				});
				// Dispatch the event.
				document.dispatchEvent(event);
			});
		});

		if (window.location.href.indexOf("/product/tasting-notes/") > -1) {
			const itemCode = /[^/]*$/.exec(window.location.href)[0];

			if (itemCode !== undefined) {
				this._tastingnote.getItem(itemCode, 'modal');
			}
		}

		if (window.location.href.indexOf("/print_template.jsp") > -1) {
			this.urlParams = new URLSearchParams(window.location.search);
			const itemcode = this.urlParams.get('itemcode');
			this._tastingnote.getItem(itemcode, 'print');
		}
		else {
			document.addEventListener("open-tasting-note", (e) => {
				this._tastingnote.getItem(e.detail, 'modal');
			});
		}
	}

}
export default TastingNoteView;
