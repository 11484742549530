// This file is for New world embedded header
import User from "../models/user/user";
import { pageInteractionDl, clickEventDL } from 'dw-global/apps/dataLayer';
import EmbeddedCartController from './embedded-cart';
/**
 * # Header
 */
 class HeaderController {
    constructor() {
		document.addEventListener("event-add-voucher", (e) => {
			clickEventDL(e.detail.event, e.detail);
		});

		User.subscribe(
			User.actions.UPDATED_SUCCESSFUL,
			this.toggleLoggedInView.bind(this)
		);
		User.subscribe(
			User.actions.UPDATED_FAILURE,
			this.toggleLoggedInView.bind(this)
		);
		User.subscribe(User.actions.PAYMENT_OPTIONS_SUCCESSFUL, (data) => {
			const res = data.response;
			if (!res && res.length <= 0) {
				return;
			}
			const hasGooglePay = res.find(i => i.paymentMode === 'googlePay' && !!i.paymentProvider);
			const hasApplyPay = res.find(i => i.paymentMode === 'applePay' && !!i.paymentProvider);
			if (hasApplyPay) {
				let icons = document.querySelectorAll('.apple-pay-svg');
				if (icons && icons.length > 0) {
					icons.forEach(i => i.style.display = "block");
				}
			}

			if (hasGooglePay) {
				let icons = document.querySelectorAll('.gpay-svg');
				if (icons && icons.length > 0) {
					icons.forEach(i => i.style.display = "block");
				}
			}
		});

		User.fetch();
		// hide the cart and the checkout button if we are on the shopping cart page
		if (window.location.toString().indexOf("shoppingcart.jsp") === -1) {
			this.cart = new EmbeddedCartController();
		} else {
			// add the adobedatalayer event tracker 
			Array.from(document.querySelectorAll('.DLclick')).forEach((btn) => {
				if (this.addEvent !== undefined) {
					this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
				}
			});
			 // to trigger the basket product
			document.addEventListener("event-add-voucher", (e) => {
				clickEventDL(e.detail.event, e.detail);
			});
			this.cart = new EmbeddedCartController();
		}

	}

	toggleLoggedInView() {
		// is the user logged in
		if (User.status === "soft" || User.status === "hard") {
			User.savingAccount();
			User.paymentOptions();
		}
	}
	
	pageInteraction(element){
		pageInteractionDl(element);
	}
 }

 export default HeaderController;