import event from '../utilities/event';
import HeaderView from '../components/header/header';
import HeroView from '../components/hero/hero';
import Parallax from '../components/parallax/parallax';
import FooterView from '../components/footer/footer';
import RecommendedView from '../components/recommended/recommended';
import RecentlyViewed from '../components/recently-viewed/recently-viewed';
import ReferralLink from '../components/buyapower/footer-link';
import Subscriptions from '../components/subscriptions/subscriptions';
import PasswordReset from '../components/password-reset/reset';
import NotifyButton from '../components/stock-notify-optin/notify';
import GiftCardView from '../components/gift-cards/gift-cards';
import EVoucherView from '../components/gift-cards/e-vouchers/e-vouchers';
import TastingNoteView from '../components/tasting-note/tasting-note';
import HsbcOverlay from '../components/hsbc/hsbcEntry';
import getBrand from 'dw-brand-identify';
import CaseTabbedOffer from '../components/case-tabbed-offer/template';
import Contentful from '../models/contentful/contentful.js';
import Tetris from '../components/tetris/tetris';
import QuickLinks from '../components/quick-links/quick-links';
import PartnershipContent from '../components/partnership-content/partnershipContent';
import MixedCaseHighlights from '../components/mixed-case-highlights/mixed-case-highlights';
import productUpsell from '../components/upsell/product';
import cartUpsell from '../components/upsell/cart';
import postUpsell from '../components/upsell/confirmation';
import DeliveryOptionsInfoPanel from '../components/delivery-options/delivery';
import Bambuser from '../components/bambuser/bambuser';
import StripBanner from '../components/strip-banner/strip-banner';
import offerListingGrid from '../components/offers/grid-listing';
import offerBulkDeals from '../components/offers/bulk-deals';
import CaseContents from '../components/case-contents/case';
import PaymentLogos from '../components/payment/payment-logos';
import Optin from '../components/optin/optin';
import ProductModel from '../models/product/product';
import { orderConfirmationDL, dlPageInit, clickEventDL } from 'dw-global/apps/dataLayer';
import User from '../models/user/user';
import HeaderController from './embedded-header';
import request from '../utilities/request.js';

class PageController {
  constructor() {
    this._brandObj = getBrand();
    
    if (location.pathname.indexOf("/product") === 0) {
      const itemcode = document.getElementById('itemData')?.getAttribute('data-item');
      const productApiCall = new ProductModel();
      productApiCall.hydrate(itemcode);
      productApiCall.isFavourite(itemcode);
       // to trigger the dl-click event on product page
			document.addEventListener("dl-event", (e) => {
				clickEventDL(e.detail.event, e.detail);
			});
    }
    if (location.pathname.indexOf("/confirmation.jsp") > 0) {
		  console.log('controller.js Fetch');
      // User.fetch();
      document.addEventListener("event-recall-order", (e) => {
        if (e.detail.lineItemsLength !== 0) {
          request.user.profile().then((res) => {
            return res.json();
          }).then(async (json) => {
            console.log('Event Data in LWUK',e.detail);
            dlPageInit(json.response, e.detail.orderItems, e.detail.transaction);
          });
        }
      });
    }

    if (location.pathname.indexOf("/checkout_guest_registered.jsp") > 0) {
		  console.log('controller.js 2 Fetch');
      User.fetch();
    }

    Contentful.subscribe(
      Contentful.actions.FETCH_SUCCESSFUL,
      this.manageContentfulData.bind(this)
    );

    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });

    if (params && params.preview) {
      Contentful.fetchPreview(params.preview);
    } else {
      Contentful.fetchData(this._brandObj.id);
    }

    if (document.querySelector('#stripBanner-message .big-strip')) {
      this.stripBannerText = new StripBanner();
    }
    document.addEventListener("open-case-contents-modal", (e) => {
      this.caseContents = new CaseContents(e.detail);
    });    

    if (document.querySelector('header#new-homepage-test-header')) {
      this.header = new HeaderView();
    }else if (document.querySelector('#newHeaderReactApp') || document.querySelector('.vine-react-app')) {
      // This function is for New world embedded header cart update 
      this.header = new HeaderController();
    }

    if (document.querySelector('.footer-links')) {
      this.footer = new FooterView();
    }

    if (document.querySelector('section.hero')) {
      this.hero = new HeroView();
    }

    if (document.querySelector('section.bambuser')) {
      this.bambuser = new Bambuser();
    }

    if (document.querySelector('section.recommended')) {
      this.recommended = new RecommendedView();
    }

    if (document.querySelector('div.product-upsell')) {
      this.productUpsell = new productUpsell();
    }

    if (document.querySelector('div.offer-listing-grid')) {
      this.offerListingGrid = new offerListingGrid();
    }

    if (document.querySelector('div.offer-bulk-deals')) {
      this.offerBulkDeals = new offerBulkDeals();
    }

    if (document.querySelector('div.cart-upsell')) {
      this.cartUpsell = new cartUpsell();
    }

    if (document.querySelector('div.post-upsell')) {
      this.postUpsell = new postUpsell();
    }

    if (document.querySelectorAll('section.recently-viewed').length > 0) {
      this.recentlyViewed = new RecentlyViewed();
    }

    if (document.querySelectorAll('.js-wr-balance').length > 0) {
      this.subscriptions = new Subscriptions();
    }

    if (document.getElementById('optin-button')) {
      let optinContainer = document.getElementById('optin-button');
      let text = optinContainer.getAttribute('data-text') || false;
      let successMessage = optinContainer.getAttribute('data-text-success') || false;
      // user not signed up
      new Optin(document.getElementById('optin-button'), {
        text,
        successMessage
      });
    }

    if (document.querySelectorAll('section.gift-cards').length > 0) {
      this.giftcards = new GiftCardView();
    }

    if (document.querySelectorAll('section.e-vouchers').length > 0) {
      this.evouchers = new EVoucherView();
    }
    if (document.querySelectorAll('.notify-button-container').length > 0) {
      this.notifyButton = new NotifyButton();
    }

    if (document.querySelectorAll('.js-buyapower-refer-link').length > 0) {
      this.referralLink = new ReferralLink();
    }

    if (document.querySelector('div.case-tabbed-offer')) {
      this.caseTabbedOffer = new CaseTabbedOffer();
    }

    if (document.querySelector('section#password-reset')) {
      this.PasswordReset = new PasswordReset();
    }

    if (document.querySelector('.delivery-option-panel')) {
      this.DeliveryOptionsInfoPanel = new DeliveryOptionsInfoPanel();
    }

    if (document.querySelector('div.payment-types')) {
      new PaymentLogos('div.payment-types', this._brandObj.id, 42);
    }

    if (document.querySelector('section.payment-logos')) {
      new PaymentLogos('section.payment-logos', this._brandObj.id, 42);
    }

    // added custom error handling for the JSP login page UXE-7697
    if (document.querySelector('div#login .error')) {
      event(
        'Login',
        'error',
        document.querySelector('div#login .error').innerText
      );
    }
    // if (this._brandObj.id !== 'stw' && this._brandObj.id !== 'law' && this._brandObj.id !== 'hsb') {
    //   // email optin modal
    //   this.emailSignUpModal = new emailSignUpPopUp();
    //   // newsletter sign up modal
    //   this.newsletterSignUpModal = new newsletterSignUp();
    // }
    // Tasting Note
    this.tastingnote = new TastingNoteView();
    if (this._brandObj.id === 'hsb') {
      this.hsbcOverlay = new HsbcOverlay();
    }
  }

  /**
   * Initialises the contentful data for each contentful component
   *
   * @param {Object} data => The data from contentful
   */
  manageContentfulData(data) {
    this.renderTetris(data);
    this.renderBrandMessage(data);
    this.renderParallax(data);
    this.renderQuickLinks(data);
    this.renderHighlightedProducts(data);
  }

  renderTetris(data) {
    const tetrisTopSectionId = 'tetris-top-section';
    const tetrisBottomSectionId = 'tetris-bottom-section';

    if (document.getElementById(tetrisTopSectionId)) {
      const {
        tetrisTopSection: { fields: topTetrisData },
      } = data;
      this.TetrisTopSection = new Tetris(tetrisTopSectionId, topTetrisData);
    }

    if (
      document.getElementById(tetrisBottomSectionId) &&
      data?.tetrisBottomSection
    ) {
      const {
        tetrisBottomSection: { fields: bottomTetrisData },
      } = data;
      this.TetrisBottomSection = new Tetris(
        tetrisBottomSectionId,
        bottomTetrisData
      );
    }
  }

  renderBrandMessage(data) {
    if (data?.partnershipContent) {
      const partnershipContentId = 'partnership-content';
      const {
        partnershipContent: { fields: partnershipContentData },
      } = data;
      if (document.getElementById(partnershipContentId)) {
        this.partnershipContent = new PartnershipContent(
          partnershipContentId,
          partnershipContentData
        );
      }
    }
  }

  renderParallax({ parallaxTopBanner, parallaxBottomBanner }) {
    const parallaxData = {
      topBanner: parallaxTopBanner,
      bottomBanner: parallaxBottomBanner,
    };

    const topBannerEl = 'top-parallax';
    const bottomBannerEl = 'bottom-parallax';

    const isTopBannerPresent =
      document.getElementById(topBannerEl) && parallaxData.topBanner;
    const isBottomBannerPresent =
      document.getElementById(bottomBannerEl) && parallaxData.bottomBanner;

    if (isTopBannerPresent || isBottomBannerPresent) {
      this.Parallax = new Parallax(parallaxData, topBannerEl, bottomBannerEl);
    }
  }

  renderQuickLinks({ quickLinks, quickLinksTitle = '' }) {
    const quickLinksData = {
      title: quickLinksTitle,
      links: quickLinks,
    };

    const quickLinksEl = '.quick-links';

    if (document.querySelector(quickLinksEl) && quickLinks) {
      this.QuickLinks = new QuickLinks(quickLinksData, quickLinksEl);
    }
  }

  renderHighlightedProducts({ highlightedProductsSection }) {
    if (highlightedProductsSection) {
      const { title, highlightedProducts } = highlightedProductsSection.fields;
      const highlightedProductsData = {
        title,
        highlightedProducts
      };

      const highlightedProductsSectionId = 'highlighted-products';

      if (document.getElementById(highlightedProductsSectionId)) {
        this.HighlightedProductsSection = new MixedCaseHighlights(highlightedProductsSectionId, highlightedProductsData);
      }
    }
  }
}
export default PageController;
