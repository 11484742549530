import Component from "../component";
import Modal from "../modal/modal";
import Cart from '../../models/cart/cart';
import event from "../../utilities/event";
import { getUpsellCookie, setUpsellCookie } from './cookie';
import { dlProductUpdate, pageInteractionDl } from 'dw-global/apps/dataLayer';
import { getWinePlanSchemeData, getItem, getUpsellForCart, addUpsellToCart } from './request';
import { templateForNonHeroWine } from './templates';
import getBrand from "dw-brand-identify";

/**
 * Cart Upsell
 * Creates a component to load the an upsell icon with links
 * This is based on the product item code having an upsell promotion
 * set up against it (via an API call), if this is the case, we check to see
 * to see if an HTML and image have been created in order to display the UI
 */
class CartUpsell extends Component {

    constructor() {
        super('div.cart-upsell');

        // Upsell items and content
        this._upsell = '';
        this._itemData = '';
        this._htmlContent = '';
        this._buttonImage = '/images/uk/en/upsell/20percentoff.svg';
        this._upsellOfferedPage = '';
        this._addUpsellOnlyToCart = false;

        this._template = this.el.querySelector('.template');

        /* Fire the upsell overlay if the user adds 12 bottles
        of the qualifying item to the cart */
        Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, (itemData) => {
            if (this._upsell && itemData.quantity >= 12 && window.location.toString().indexOf("shoppingcart.jsp") === -1) {
                this._addUpsellOnlyToCart = true;
                this.openUpsellModal(this._itemData.name);
            }
        });

        // Await the cart to load from the JS in uk_shopping_cart in store UI in order
        // to trigger the upsell
        document.addEventListener("event-recall-cart-upsell", (e) => {
            if (e.detail.lineItems !== 0) {
                this.checkForUpsell();
            } else {
                this._template.innerHTML = '<div class="noUpsell"></div>';
            }
        });

        document.addEventListener("close-modal", (e) => {
            if (e.detail === 'cartUpsell') {
                event('Pop-ups', 'Cart Upsell', 'Cart upsell modal closed');
                setUpsellCookie();

                // Hide the upsell is the cookie count is 3 or above
                if (getUpsellCookie('UpsellRejectionCount') >= 3) {
                    this._template.innerHTML = '<div class="noUpsell"></div>';
                }
            }
        });

        // Await the cart to load from the JS in uk_shopping_cart in store UI in order
        // to trigger the basket product
        document.addEventListener("event-recall-cart-update", (e) => {
            if (e.detail.cartItemsLength !== 0) {
                dlProductUpdate(e.detail.cartItems);
            }
        });
    }

    async checkForUpsell() {
        const getUpsells = await getUpsellForCart();
        // Did we get the upsell data?
        if (getUpsells) {

            const offerWinePlanUpsell = getUpsells.filter(upsell => upsell.type === 'WINEPLAN');

            // Wineplan upsell
            if (offerWinePlanUpsell.length > 0) {
                this._upsell = offerWinePlanUpsell[0];

                this._itemData = await getItem(offerWinePlanUpsell[0].itemCode);

                if (this._itemData && this._itemData.skus && this._itemData.skus.length > 0) {
                    if (this._itemData.skus[0].schemeDetails && this._itemData.skus[0].schemeDetails.length > 0) {
                        const schemeId = this._itemData.skus[0].schemeDetails[0].schemeId;
                        if (schemeId) {
                            this._winePlanSchemeData = await getWinePlanSchemeData(schemeId);

                            this._template.innerHTML = this.renderButton();
                            document.getElementById('openCartUpsellModal').addEventListener('click', (e) => {
                                e.preventDefault();

                                this.openUpsellModal(this._winePlanSchemeData.displayName);
                                pageInteractionDl(e.target)
                            });
                        } else {
                            alert(`No SchemeID setup for upsell: ${this._itemData.skus[0].itemCode}, cannot get dynamic wine plan details`)
                        }
                    }
                } else {
                    alert(`No skus setup for upsell: ${this._itemData.skus[0].itemCode}`)
                }
            }
        } else {
            this._template.innerHTML = '<div class="noUpsell"></div>';
        }
    }

    openUpsellModal() {
        // Get the brand
        const brandObj = getBrand();
        const termsLink = (brandObj.id === 'law' || brandObj.id === 'bbc') ?
                                '/customer-services/customer-terms-and-conditions-of-sale-and-use#wine-plans' :
                                '/customer-services/terms-and-conditions-of-sale-and-use#wine-plans';
        this.modal = new Modal({
            title: 'SAVE AN EXTRA 20% TODAY',
            body: templateForNonHeroWine(this._winePlanSchemeData, 'upsellonly'),
            containsForm: true,
            showFooter: false,
            largeModal: true,
            id: 'cartUpsell'
        });

        // Open the modal
        this.modal.open();
        event('Pop-ups', 'Cart Upsell', 'Cart upsell modal opened');

        const signUpButton = document.getElementById('signUp');
        if (typeof (signUpButton) !== 'undefined' && signUpButton !== null) {
            document.getElementById('signUp').addEventListener('click', (e) => {
                e.preventDefault();
                event('Pop-ups', 'Cart Upsell', 'Cart upsell Wineplan added to order');
                addUpsellToCart(this._upsell);
            });
        }

        const termsButton = document.getElementById('termsLink');
        if (typeof (termsButton) !== 'undefined' && termsButton !== null) {
            document.getElementById('termsLink').addEventListener('click', (e) => {
                e.preventDefault();
                event('Pop-ups', 'Cart Upsell', 'Clicked through from cart upsell to terms page');
                window.open(termsLink);
            });
        }
    }

    renderButton() {
        return (`
        <div class="upsell-cart-button-container">
            <div>${this._buttonImage ? `<img src="${this._buttonImage}" width="142" height="33" />` : ''}</div>  
            <div>You could save a further 20% on your order today</div> 
            <div>
                <a href="#" id="openCartUpsellModal" class="claim-link DLclick" data-event="subscribeSaveClick" data-action="subscribe and save overlay" data-section="subscribe and save:overlay" data-action="accordion menu" data-name="shopping cart upsell" data-text="subscribe and save">
                Claim your extra discount here <span class="chevron-right">></span>
                </a>
            </div>             
        </div>`);
    }
}
export default CartUpsell;

