import Component from "../component";
import request from '../../utilities/request';
import Cart from '../../models/cart/cart';

class Bambuser extends Component {
	constructor() {
        super('section.bambuser');
        let button = this.el.getElementsByTagName("button");
        this.loadVideo(button[0].id);
    }

    loadVideo(id) {

        let uniqueID = id.split('-')[1];

        if (!window.initBambuserLiveShopping){
            window.initBambuserLiveShopping = function(item) { window.initBambuserLiveShopping.queue.push(item) }; window.initBambuserLiveShopping.queue = [];
            var scriptNode = document.createElement('script');
            scriptNode['src'] = 'https://lcx-embed.bambuser.com/laithwaites-uk/embed.js';
            document.body.appendChild(scriptNode);
        }
        

        window.onBambuserLiveShoppingReady = player => {
            let price = 0;

            player.configure({
                buttons: {
                    dismiss: player.BUTTON.MINIMIZE,   
                },
                minimizedPosition: player.MINIMIZED_POSITION.BOTTOM_RIGHT,
            });
            player.on(player.EVENT.PROVIDE_PRODUCT_DATA, event => {
                event.products.forEach(({ ref: sku, id: productId, url: publicUrl }) => {

                    request.product.get({itemcode: sku}).then(res => res.json()).then((json) => {

                        let product = json.response;
                        if(product){

                            price = product.skus[0].salePrice;

                            player.updateProduct(productId, factory => factory
                                .publicUrl(publicUrl).currency('GBP').locale('en-US').product(p => p
                                    .brandName(product.grapeName)
                                    .defaultVariationIndex(0)
                                    .description(product.description)
                                .name(product.name)
                                .sku(product.itemCode)

                                .variations(v => [
                                    v()
                                    .attributes(a=> a
                                        .colorName(product.colourId)
                                        .colorHexCode(product.colourName)
                                    )
                                    .imageUrls([`${window.location.origin}${product.largeImage}`])
                                    .name(product.name)
                                    .sku(product.itemCode)
                                    .sizes(s => [
                                    s()
                                    .name(product.name)
                                    .inStock(product.inventoryInfo.stockQty > 0)
                                    .sku(product.itemCode)
                                    .price(pr => pr
                                        .current(product.skus[0].salePrice))
                                    ])
                                ])
                                )
                            );
                        }
                    });
                });
            });
            player.on(player.EVENT.CHECKOUT, function() {
                player.showCheckout(window.location.origin + '/jsp/checkout/common/shoppingcart.jsp');
            }),
            player.on(player.EVENT.SYNC_CART_STATE, () => {
                request.cart.list().then(res => res.json()).then((json) => {
                    if (json.response.lineItems.length === 0) {
                        player.updateCart({
                            items: []
                        })
                    } else {
                        player.updateCart({
                            items: json.response.lineItems
                        })
                    }
                }).catch(function() {})
            }),
            player.on(player.EVENT.ADD_TO_CART, (addedItem, callback) => {
                request.product.get({itemcode: addedItem.sku}).then(res => res.json()).then((json) => {
                    let result = json.response;
                
                if (result.inventoryInfo.summaryAvailabilityStatus !== 'out_of_stock') {
                    Cart.addItem(addedItem.sku,1,price).then(() => {
                        callback(true);
                      }).catch(error => {
                        if (error.type === 'out-of-stock') {
                          callback({
                            success: false,
                            reason: 'out-of-stock',
                          });
                        } else {
                          callback(false);
                        }
                      });
                } else if (result.inventoryInfo.summaryAvailabilityStatus === 'out_of_stock') {
                    callback({
                        success: false,
                        reason: 'custom-error',
                        message: 'Out of Stock'
                      });
                }
            });
            }),

            player.on(player.EVENT.UPDATE_ITEM_IN_CART, (updatedItem, callback) => {
                if (updatedItem.quantity > 0) {
                    let updateQuantity = `${updatedItem.quantity}?method=set`
                    Cart.addItem(updatedItem.sku,updateQuantity,price).then(() => {
                        callback(true);
                    }).catch(error => {
                        if (error.type === 'out-of-stock') {
                          callback({
                            success: false,
                            reason: 'out-of-stock',
                          });
                        } else {
                          callback(false);
                        }
                    });
                } else if (updatedItem.quantity === 0) {
                    Cart.removeItem(updatedItem.sku,price).then(() => {
                        callback(true);
                    }).catch(error => {
                        if (error.type === 'out-of-stock') {
                          callback({
                            success: false,
                            reason: 'out-of-stock',
                          });
                        } else {
                          callback(false);
                        }
                    });
                }

            });
        };


        window.initBambuserLiveShopping({
            showId: uniqueID,
            node: document.getElementById(id),
            type: "overlay",
        });
    }
}

export default Bambuser;
