import request from '../../utilities/request';
import Product from '../product/product';
import Model from '../model';

const TYPES = {
	subscriptionRecruitmentItem: 'SUBSCRIPTION_RECRUITMENT_ITEM',
	wineplaneRecruitmentItem: 'WINEPLAN_RECRUITMENT_ITEM',
	winerewardRecuitmentItem: 'WINEREWARD_RECRUITMENT_ITEM',
	subscriptionFreeItem: 'SUBSCRIPTION_FREE_ITEM',
	winerewardFreeItem: 'WINEREWARD_FREE_ITEM',
};

/**
 * @class  Cart
 */
class CartItemModel extends Product {

	/**
	 * List of actions associated with this model. They can be accessed via
	 * `User.actions.UPDATED_START`.
	 * 
	 * @return {Object}
	 */
	get actions() {
		return {
			// remove actions
			REMOVE_START: `cart::${this.id}::remove-start`,
			REMOVE_SUCCESSFUL: `cart::${this.id}::remove-successful`,
			REMOVE_FAILURE: `cart::${this.id}::remove-failure`,
			// increase quantity
			INCREASE_QUANTITY_START: `cart::${this.id}::increase-quantity-successful`,
			INCREASE_QUANTITY_SUCCESSFUL: `cart::${this.id}::increase-quantity-successful`,
			INCREASE_QUANTITY_FAILURE: `cart::${this.id}::increase-quantity-successful`,
			// decrease quantity
			DECREASE_QUANTITY_START: `cart::${this.id}::decrease-quantity-successful`,
			DECREASE_QUANTITY_SUCCESSFUL: `cart::${this.id}::decrease-quantity-successful`,
			DECREASE_QUANTITY_FAILURE: `cart::${this.id}::decrease-quantity-successful`,
		}
	}

	get quantity() {
		return this._item.quantity;
	}

	get total() {
		return this._item.itemPriceInfo.amount;
	}

	remove() {

	}

	increaseQuantity(quantityModifier = 1) {

	}

	decreaseQuantity(quantityModifier = -1) {

	}
}

export default CartItemModel;