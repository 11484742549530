import Modal from "../../modal/modal";
import event from "../../../utilities/event";
import { templateForPostUpsellWinePlan } from '../templates';
import { addUpsellToOrder } from '../request';
import getBrand from "dw-brand-identify";

const brandObj = getBrand();
const termsLink = (brandObj.id === 'law' || brandObj.id === 'bbc') ?
                    '/customer-services/customer-terms-and-conditions-of-sale-and-use#wine-plans' :
                    '/customer-services/terms-and-conditions-of-sale-and-use#wine-plans';

export const openWinePlanUpsellModal = (winePlanSchemeData, itemData, transactionId, upsell) => {
    const transaction_Id = transactionId;
    const WinePlanModal = new Modal({
        title: 'Just before you go ...',
        body: templateForPostUpsellWinePlan(winePlanSchemeData, itemData),
        containsForm: true,
        showFooter: false,
        largeModal: true,
        id: 'postUpsellWinePlan'
    });

    // Open the modal
    WinePlanModal.open();
    event('Pop-ups', 'Post Upsell Wineplan', 'Post Upsell Wineplan opened');

    const signUpButton = document.getElementById('signUp');
    if (typeof (signUpButton) !== 'undefined' && signUpButton !== null) {
        document.getElementById('signUp').addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell Wineplan', 'Post Upsell Wineplan added to order');
            if (document.querySelector('input#futurePayments').checked) {
                addUpsellToOrder(transactionId, upsell);
            } else {
                const errorContainer = document.getElementById('error-container');
                errorContainer.innerHTML = `Please confirm you are happy to be charged for future payments by checking the box above.`;
                errorContainer.style.display = "block";
            }
        });
    }

    const termsButton = document.getElementById('termsLink');
    if (typeof (termsButton) !== 'undefined' && termsButton !== null) {
        document.getElementById('termsLink').addEventListener('click', (e) => {
            e.preventDefault();
            event('Pop-ups', 'Post Upsell Wineplan', 'Clicked through from Post Upsell Wineplan to terms page');
            window.open(termsLink);
        });
    }

}