
import event from "../../utilities/event";

export const lookupUpsellContentType = (rulename) => {
    let type;
    switch (rulename) {
        case 'Hero Wines':
        case 'HeroWines':
            type = true
            break;
        default:
            type = false
    }
    return type;
}

export const getImage = async (url) => {
    return fetch(url).then((data) => {
        if (!data.ok) {
            throw new Error(`Upsell: Image file does not exist to support upsell, please create ${url}`);
        }
        return data.url;
    }).catch((error) => {
        console.log(error.message);
        event('Errors', 'Upsell', error.message);
    });
}

export const getHTMLContent = async (url) => {
    return fetch(url)
        .then((data) => {
            if (!data.ok) {
                throw new Error(`Upsell: HTML file does not exist to support upsell, please create ${url}`);
            }
            return data.text()
        }).then((html) => {
            var parser = new DOMParser();
            // Parse the text
            var doc = parser.parseFromString(html, "text/html");
            // Select part of that html as you would in the regular DOM 
            return doc.querySelector('body').innerHTML;
        }).catch((error) => {
            console.log(error.message);
            event('Errors', 'Upsell', error.message);
        });
}

export const supports_html5_storage = () => {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
        return false;
    }
}