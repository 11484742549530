import Component from "../component";
import Modal from "../modal/modal";
import Cart from '../../models/cart/cart';
import event from "../../utilities/event";
import { setUpsellCookie, getUpsellCookie } from './cookie';
import { templateForHeroWine, templateForNonHeroWine, templateForNonHeroWineRightPanel } from './templates';
import { getWinePlanSchemeData, getItem, getUpsellForProduct, addUpsellAndBottlesToCart, addUpsellOnlyToCart } from './request';
import { lookupUpsellContentType } from './helper';
import getBrand from "dw-brand-identify";

/**
 * Product Upsell
 * Creates a component to load the an upsell icon with links
 * This is based on the product item code having an upsell promotion
 * set up against it (via an API call), if this is the case, we check to see
 * to see if an HTML and image have been created in order to display the UI
 */
class ProductUpsell extends Component {
    constructor() {
        super('div.product-upsell');

        // Primary Items
        this._primaryItem = document.getElementById('itemData').getAttribute('data-item');
        this._primaryItemName = document.getElementById('itemData').getAttribute('data-item-name');

        // Upsell items and content
        this._upsell = '';
        this._itemData = '';
        this._htmlContent = '';
        this._isHero = false;
        this._buttonImage = '/images/uk/en/upsell/20percentoff.svg';
        this._winePlanSchemeData = '';

        this._template = this.el.querySelector('.template');

        
        this.checkForUpsell();

        document.addEventListener("close-modal", (e) => {
            if (e.detail === 'upsellOverlay' || e.detail === 'upsellonly') {
                event('Pop-ups', 'Upsell', 'Upsell modal closed');
                setUpsellCookie();
               
                // Hide the upsell is the cookie count is 3 or above
                if (getUpsellCookie('UpsellRejectionCount') >= 3) {
                    this._template.innerHTML = '<div class="noUpsell"></div>';
                }
                
            }
        });

        document.body.addEventListener('click', this.hideModalWhenClickedOutside, true);

        /* Fire the upsell overlay if the user adds 12 bottles
        of the qualifying item to the cart */
        Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, (itemData) => {
            if (this._upsell) {
                /* 
                    1. Checks if bottle added that qty is 12 or above
                    2. Checks if case added that bottles in the case is 12 or above
                */

                if (itemData && itemData.recentItemDetails) {
                    if ((!itemData.recentItemDetails.mixed && itemData.recentItemDetails.bottleCount >= 12) || (itemData.recentItemDetails.mixed && itemData.recentItemDetails.caseBottleCount >= 12)) {
                        if (getUpsellCookie('UpsellRejectionCount') >= 1) {
                            // Do not show the right panel
                        } else {
                            this.openRightPanelTemplate();
                        } 
                    }
                }
            }
        });

        // Close the modal if the batch add is successful
        Cart.subscribe(Cart.actions.ADD_BATCH_SUCCESSFUL, () => {
            this.modal.close();
        });

        Cart.subscribe(Cart.actions.ADD_FAILURE, (error) => {
            const errorContainer = document.getElementById('error-container');
            if (typeof (errorContainer) !== 'undefined' && errorContainer !== null) {
                errorContainer.innerHTML = error.message;
                errorContainer.style.display = "block";
            }
        });
    }

    /**
     * 
     * @param {*} e
     * Remove the modal if the user clicks outside for the elements defined below 
     */
    hideModalWhenClickedOutside(e) {
        const element = e.target.parentNode.id;
        if (element === "upsellonly") {
            $(e.target.parentNode).remove();
        }
    }

    openRightPanelTemplate() {
        if (this._isHero) {
            const title = `<img class="visible-xs" src="${this._buttonImage}" />Love ${this._primaryItemName}?`
            this.openUpsellModal(title, templateForHeroWine('upsellonly'), 'upsellonly', false);
        } else {
            const title = `Save an extra 20% today`
            this.openUpsellModal(title, templateForNonHeroWineRightPanel(this._winePlanSchemeData, 'upsellonly'), 'upsellonly', false);
        }
    }

    openCorrectUITemplate() {
        if (this._isHero) {
            const title = `<img class="visible-xs" src="${this._buttonImage}" />Love ${this._primaryItemName}?`
            this.openUpsellModal(title, templateForHeroWine(), '', false);
        } else {
            const title = `Save an extra 20% today`
            this.openUpsellModal(title, templateForNonHeroWine(this._winePlanSchemeData), '', true);
        }
    }

    async checkForUpsell() {
        const getUpsells = await getUpsellForProduct(this._primaryItem);
        if (getUpsells) {
            const offerWinePlanUpsell = getUpsells.filter(upsell => upsell.type === 'WINEPLAN');

            if (offerWinePlanUpsell.length > 0) {

                this._upsell = offerWinePlanUpsell[0];
                this._itemData = await getItem(this._primaryItem);
                this._isHero = lookupUpsellContentType(this._upsell.rule);

                const upsellItemData = await getItem(this._upsell.itemCode);


                if (upsellItemData && upsellItemData.skus && upsellItemData.skus.length > 0) {
                    if (upsellItemData.skus[0].schemeDetails && upsellItemData.skus[0].schemeDetails.length > 0) {
                        const schemeId = upsellItemData.skus[0].schemeDetails[0].schemeId;
                        this._winePlanSchemeData = await getWinePlanSchemeData(schemeId);
                    }
                }

                this._template.innerHTML = this.renderButton();
                document.getElementById('openUpsellModal').addEventListener('click', (e) => {
                    e.preventDefault();
                    // Prepare data for the modal
                    this.openCorrectUITemplate();
                });
            }
        } else {
            this._template.innerHTML = '<div class="noUpsell"></div>';
        }
    }

    openUpsellModal(title, template, type, showlargeModal) {
        const brandObj = getBrand();
        const termsLink = brandObj.id === 'law' || brandObj.id === 'bbc' ?
                            '/customer-services/customer-terms-and-conditions-of-sale-and-use#wine-plans' :
                            '/customer-services/terms-and-conditions-of-sale-and-use#wine-plans';

        this.modal = new Modal({
            title: title,
            body: template,
            containsForm: true,
            showFooter: false,
            largeModal: showlargeModal,
            id: type === 'upsellonly' ? 'upsellonly' : 'upsellOverlay'
        });

        // Open the modal
        this.modal.open();
        event('Pop-ups', 'Upsell', 'Upsell modal opened');

        const signUpButton = document.getElementById('signUp');
        if (typeof (signUpButton) != 'undefined' && signUpButton != null) {
            document.getElementById('signUp').addEventListener('click', (e) => {
                e.preventDefault();
                event('Pop-ups', 'Upsell', 'Upsell and case promo added together');
                addUpsellAndBottlesToCart(this._upsell, this._itemData);
            });
        }

        const signUpUpsellOnlyButton = document.getElementById('signUpUpsellOnly');
        if (typeof (signUpUpsellOnlyButton) != 'undefined' && signUpUpsellOnlyButton != null) {
            document.getElementById('signUpUpsellOnly').addEventListener('click', (e) => {
                e.preventDefault();
                event('Pop-ups', 'Upsell', 'Upsell promo added only');
                addUpsellOnlyToCart(this._upsell);
            });
        }

        const termsButton = document.getElementById('termsLink');
        if (typeof (termsButton) != 'undefined' && termsButton != null) {
            document.getElementById('termsLink').addEventListener('click', (e) => {
                e.preventDefault();
                event('Pop-ups', 'Upsell', 'Clicked through from upsell to terms page');
                window.open(termsLink);
            });
        }
    }

    renderButton() {
        return (`
        <div class="upsell-button-container">  
            <a href="#" id="openUpsellModal" class="claim-link">
            ${this._buttonImage ? `<img src="${this._buttonImage}" width="142" height="33" /><br/>` : ''}    
            Claim your extra discount here <span class="chevron-right">></span>
            </a>
        </div>`
        );
    }


}
export default ProductUpsell;

