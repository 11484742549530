import cellDataToTetrisData from '../../../utilities/contentful/tetrisCellTransformer';
import cellOneByOne from '../cell/cellOneByOne';
import cellOneByTwo from '../cell/cellOneByTwo';
import cellTwoByOne from '../cell/cellTwoByOne';

class TetrisLayoutTwo {
  constructor(elementId, cellData) {
    this.id = elementId;
    this.data = cellData;
  }

  /**
   * Organises the tetris cells into the correct blocks for this layout
   *
   * @return {String} => The html string which will be placed into the tetris element
   */
  render() {
    const cellCount = this.data.length;
    if(cellCount !== 5) {
      console.warn(`tetrisLayoutTwo.js: Incorrect number of cells. Expected 5 but received ${cellCount}`);
    }

    const [cellOne, cellTwo, cellThree, cellFour, cellFive] = this.data.map(
      (cellData) => cellDataToTetrisData(this.id, cellData)
    );

    return `
      <div class="col-12 col-md-6">
        <div class="row no-gutters h-50">
          <div class="col-12 pb-4 pb-md-2 pr-md-2 mb-4 cell-container">
            ${cellOneByTwo({ ...cellOne })}
          </div>
        </div>
        <div class="row no-gutters h-50 pt-md-2">
          <div class="col-12 col-md-6 pr-md-2 pb-4 pb-md-0 mb-4 cell-container">
            ${cellOneByOne({ ...cellFour })}
          </div>
          <div class="col-12 col-md-6 pb-4 pb-md-0 pl-md-2 pr-md-2 mb-4 cell-container">
            ${cellOneByOne({ ...cellFive })}
          </div>
        </div>
      </div>
      <div class="col-12 col-md-3 pt-1 pt-md-0 px-md-2 pb-4 pb-md-0 mb-4 cell-container">
        ${cellTwoByOne({ ...cellTwo })}
      </div>
      <div class="col-12 col-md-3 pt-1 pt-md-0 pl-md-2 mb-4 cell-container">
        ${cellTwoByOne({ ...cellThree })}
      </div>
    `;
  }
}

export default TetrisLayoutTwo;
