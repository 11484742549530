import Model from "../model";
import getBrand from 'dw-brand-identify';

let averysJSON = 'AVRY';
if (window.dataLayer && window.dataLayer[0] && window.dataLayer[0].dataAreaId) {
	const dataAreaId = window.dataLayer[0].dataAreaId;
	if (dataAreaId === 'UK') {
		averysJSON = 'UKAVRY';
	}
}

let SEARCH_SUGGEST_URL = "/json/searchsuggest/searchsuggest_en_GB_UKLAIT.json";
let SEARCH_SUGGEST_URL_AVERYS = "/json/searchsuggest/searchsuggest_en_GB_" + averysJSON + ".json";

if ((window.location.href.indexOf("localhost") > -1) || (window.location.href.indexOf("webdev") > -1) || (window.location.href.indexOf("uat") > -1) || (window.location.href.indexOf("rebrand") > -1)) {
	SEARCH_SUGGEST_URL = "/json/searchsuggest/internal/searchsuggest_en_GB_UKLAIT.json";
	SEARCH_SUGGEST_URL_AVERYS = "/json/searchsuggest/internal/searchsuggest_en_GB_" + averysJSON + ".json";
}

const POPULAR_PRODUCTS_JSON = "/assets/components/homepage/data/search/data.json";
const POPULAR_KEYWORDS = "/assets/components/homepage/data/search/keywords.json";

class SearchModel extends Model {
	constructor() {
		super();
		this._results = [];
		this._suggestions = [];
		this._facets = [];
		this._index = null;
		this._lunr = false;
		this._errored = false;
		this._brandObj = getBrand();
	}

	/**
	 * List of actions associated with the search model
	 * @return {Object}
	 */
	get actions() {
		return {
			// search suggests
			SUGGEST_START: "search::suggest-search",
			SUGGEST_SUCCESSFUL: "search::suggest-successful",
			SUGGEST_FAILURE: "search::suggest-failure",
			// facets fetching
			FACETS_START: "search::facets-start",
			FACETS_SUCCESSFUL: "search::facets-successful",
			FACETS_FAILURE: "search::facets-failure"
		};
	}

	/**
	 * Load the initial search suggest object, this is quite a large request
	 * so this should only be loaded when a user clicks on the search box
	 */
	_loadSeachSuggest() {
		// notify we are starting
		this.dispatch(this.actions.SUGGEST_START);

		if (this._brandObj.id === 'avy') {
			return this._loadAverysSearchSuggest();
		}

		// make the request
		return fetch(SEARCH_SUGGEST_URL, {
			"Content-Type": "application/json"
		})
			.then(res => res.json())
			.then(suggestions => {
				// load up any additional meta information
				return fetch(POPULAR_PRODUCTS_JSON, {
					"Content-Type": "application/json"
				})
					.then(res => res.json())
					.then(popular => {
						// load up the additional keywords
						return fetch(POPULAR_KEYWORDS, {
							"Content-Type": "application/json"
						})
							.then(res => res.json())
							.then(keywords => {
								// normalise the page views on a scale 1-100
								let score = popular.map(item =>
									parseInt(item.score, 10)
								);
								let maxScore = Math.max.apply(null, score);
								let minScore = Math.min.apply(null, score);
								let pageScoreScale = number => {
									return (
										(100 / (maxScore - minScore)) *
										(number - minScore)
									);
								};

								// add the data to the suggestions
								this._suggestions = suggestions;

								// add the index to the suggestions
								this._suggestions = this._suggestions.map(
									(item, i) => {
										item.location = i;
										// add the popular meta
										popular.forEach((popularItem, j) => {
											if (
												popularItem.code
													.toString()
													.indexOf(item.i) !== -1
											) {
												item.normalisedRanking = pageScoreScale(
													popular[j].score
												);
											}
										});
										return item;
									}
								);

								keywords.forEach(keyword => {
									this._suggestions.push({
										i: keyword.url,
										n: keyword.name,
										boost: 100
									});
								});

								return this._setupLunr().then(() => {
									// nofitfy we have the data
									this.dispatch(
										this.actions.SUGGEST_SUCCESSFUL
									);
								});
							});
					});
			})
			.catch(e => {
				// all gone horribly wrong
				this.dispatch(this.actions.SUGGEST_FAILURE, e);
				this._errored = true;
			});
	}

	/**
	 * Averys doesn't have the same product category
	 */
	_loadAverysSearchSuggest() {
		// make the request
		return fetch(SEARCH_SUGGEST_URL_AVERYS, {
			"Content-Type": "application/json"
		})
			.then(res => res.json())
			.then(suggestions => {
				// add the data to the suggestions
				this._suggestions = suggestions;
				this._suggestions = this._suggestions.map(
					(item, i) => {
						item.normalisedRanking = 1;
						item.location = i;
						return item;
					}
				);
				return this._setupLunr().then(() => {
					// nofitfy we have the data
					this.dispatch(
						this.actions.SUGGEST_SUCCESSFUL
					);
				});
			})
			.catch(e => {
				// all gone horribly wrong
				this.dispatch(this.actions.SUGGEST_FAILURE, e);
				this._errored = true;
			});
	}

	/**
	 * Setup Lunr
	 * Lunr is a JS powered search engine. It is very powerful and we can use it
	 * in a much better way.
	 *
	 * @private
	 */
	_setupLunr() {
		const suggestions = this._suggestions;

		return import(/* webpackChunkName: "lunr" */ "lunr").then(
			({ default: lunr }) => {
				// take a copy of lunr
				this._lunr = lunr;

				let commonMisspellings = builder => {
					let pipelineFunction = token => {
						switch (token.toString()) {
							case ("riesling", "resiling", "resling"):
								return token.update(() => "reisling");
							case ("gwertztraminer", "gewurtztramier"):
								return token.update(() => "gewurztraminer");
							case "sparking":
								return token.update(() => "sparkling");
							case ("tempriniyo", "temprinillo"):
								return token.update(() => "tempranillo");
							case "granache":
								return token.update(() => "grenache");
							case "sirya":
								return token.update(() => "syrah");
							case ("picpul", "picpol"):
								return token.update(() => "picpoul");
							case ("cabanet", "cabenet"):
								return token.update(() => "cabernet");
							case "vionier":
								return token.update(() => "viognier");
							case ("savignon", "sauvion"):
								return token.update(() => "sauvignon");
							default:
								return token;
						}
					};
					lunr.Pipeline.registerFunction(
						pipelineFunction,
						"commonMisspellings"
					);
					builder.pipeline.before(lunr.stemmer, pipelineFunction);
					builder.searchPipeline.before(
						lunr.stemmer,
						pipelineFunction
					);
				};

				this._index = lunr(function() {
					this.ref("i");
					this.field("n");
					this.k1(0.5);
					this.b(0);

					this.use(commonMisspellings);
					// add all the suggestions
					suggestions.forEach(suggestion => {
						let boostValue = 1;
						if (suggestion.normalisedRanking) {
							boostValue += suggestion.normalisedRanking / 50;
						}
						this.add(suggestion, {
							boost: suggestion.boost || boostValue
						});
					});
				});
			}
		);
	}

	/**
	 * Search suggest
	 * Provide a list of possible results for a search string
	 *
	 * @param  {string} str    Search string
	 * @param  {Number} length Max number of items (defaults to 10)
	 * @return {Array}
	 */
	searchSuggest(str, length = 10) {
		if (this._errored) {
			// we have errored, give up
			return Promise.reject();
		}
		// if we don't have the search suggestion list, load it
		if (this._suggestions.length === 0) {
			return this._loadSeachSuggest().then(() => {
				return this.searchSuggest(str, length);
			});
		}

		// configure the query to get better results
		/*let strings = str.split(' ');
		strings.map((s) => {

			const parts = [];
			if (s.length > 3) {
				// fuzziness is proportional to the length of the search term
				parts.push('~' + Math.floor(s.length/2));
			}
			return s + parts.join('');
		})*/

		if(!this._index) {
			return Promise.resolve([])
		}
		let matches = this._index.query(q => {
			this._lunr.tokenizer(str).forEach(token => {
				q.term(token.toString(), { boost: 100 });
				q.term(token.toString(), {
					boost: 10,
					usePipeline: true,
					wildcard:
						this._lunr.Query.wildcard.LEADING |
						this._lunr.Query.wildcard.TRAILING
				});
				q.term(token.toString(), {
					boost: 1,
					usePipeline: false,
					editDistance: 1
				});
			});
		});

		matches = matches.map(r => {
			return this._suggestions.filter((item, i) => {
				return item.i === r.ref;
			})[0];
		});

		return Promise.resolve(matches.slice(0, length));
	}

	/**
	 * Facets
	 * This will load up the search page into a new element and use queryselctors
	 * in order to pull out the facets. Note that it is only only one result per
	 * page so we get the correct item count.
	 *
	 * @param  {String}  query The search query
	 * @param  {Boolean} link  The link we are search (optional)
	 * @return {Promise}
	 */
	fetchFacets(query, link = false) {
		// notify everyone we are starting
		this.dispatch(this.actions.FACETS_START);
		// fetch the item
		return fetch(link ? link : `/wines?Ntt=${escape(query)}&Nrpp=1`)
			.then(res => res.text())
			.then(body => {
				// we get a bunch of HTML back
				const doc = document.implementation.createHTMLDocument(
					"Search Page"
				);
				const searchPage = doc.createElement("html");
				searchPage.innerHTML = body;
				// work out how many wines have been returned
				let total = searchPage.querySelector(
					".print-sort-pagination small"
				);
				if (total && total.innerText) {
					total = parseInt(total.innerText.replace(/\D+/g, ""), 10);
				} else {
					total = false;
				}

				let facets = {};
				// select all the facets from the query
				Array.from(
					searchPage.querySelectorAll(
						"#accordion .panel.panel-filter"
					)
				).forEach(panel => {
					const title = panel
						.querySelector(".panel-title h3")
						.innerText.trim();
					const options = Array.from(
						panel.querySelectorAll("ul li a")
					).map(link => {
						return {
							href: link.getAttribute("href"),
							title: link.innerHTML.trim()
						};
					});

					facets[title] = options || [];
				});

				// notify we have finished
				this.dispatch(this.actions.FACETS_SUCCESSFUL);
				// return all the facets and the total number of items
				return {
					facets,
					total
				};
			});
	}
}

export default new SearchModel();
