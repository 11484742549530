import Component from '../component';
import Modal from '../modal/modal';
import User from '../../models/user/user';
import event from '../../utilities/event';
import { clickEventDL } from 'dw-global/apps/dataLayer';

/**
 * @class  LoginModal
 */
class LoginModal extends Component {

	constructor(settings) {
		super();
		// create the modal
		this.modal = new Modal({
			title: 'Login',
			body: this.template,
			onAction: this.login.bind(this),
			containsForm: true,
		});
		// open the modal
		this.modal.open();
		// listen for updates
		User.subscribe(User.actions.LOGIN_START, this.loginStart.bind(this));
		User.subscribe(User.actions.LOGIN_FAILURE, this.loginFailure.bind(this));
		User.subscribe(User.actions.LOGIN_SUCCESSFUL, this.loginSuccess.bind(this));
		// find all the elements we are going to use
		this._email = this.modal.el.querySelector('input[name="email"]');
		this._password = this.modal.el.querySelector('input[name="password"]');
		this._button = this.modal.el.querySelector('button[type="submit"]');
		this._error = this.modal.el.querySelector('.alert-danger');
	}

	/**
	 * This is the template for the modal to log in
	 */
	get template() {
		return (`
			<div class="alert alert-danger text-danger" style="display: none; padding: 10px;"></div>
			<div class="form-group">
				<label for="email" class="col-form-label">Email:</label>
				<input type="text" class="form-control" id="email" name="email" tabindex="1">
			</div>
			<div class="form-group">
				<label for="password" class="col-form-label">Password:</label>
				<input type="password" class="form-control" id="password" name="password" tabindex="2">
				<div class="mt-1"><a href="/jsp/registration/common/account_password.jsp">Forgotten Password?</a></div>
			</div>
		`);
	}

	/**
	 * Login the user in using the information they have provided in the pop up
	 * @return {[type]} [description]
	 */
	login(e) {
		e.preventDefault();
		// check to make sure the email is valid
		if (!this._validateEmail(this._email.value)) {
			this._showError('Please enter a valid email address');
			event('Login', 'error', 'Please enter a valid email address');
			this._email.classList.add('error');
			let itemData = {
				name : 'login overlay:submit:error', 
				clickText : 'login error'
			}
			clickEventDL('loginError', itemData);
			return;
		} else {
			this._hideError();
			this._email.classList.remove('error');
		}
		// check to make sure both feils are valid
		if (!this._password.value) {
			this._showError('Password is required');
			event('Login', 'error', 'Password is required');
			this._password.classList.add('error');
			return;
		} else {
			this._hideError();
			this._password.classList.remove('error');
		}
		// log the user in
		User.login(this._email.value, this._password.value);
		let itemData = {
			name : 'login overlay:submit:success', 
			clickText : 'login success'
		}
		clickEventDL('loginError', itemData);
	}

	/**
	 * Start login, add a loading spinner to the button
	 */
	loginStart() {
		this._button.classList.add('loading');
	}

	/**
	 * When the login is successful, close the login modal
	 */
	loginSuccess() {
		this._button.classList.remove('loading');
		// close the modal
		this.modal.close();
		this.gaEventLogin();
		// reload the page
		setTimeout(() => {
			window.location.reload();
		}, 100);
	}

	gaEventLogin() {
		dataLayer.push({
			'event': 'loginSuccess',
		});
	}

	/**
	 * When the login fails show the error message
	 * @param  {Exception} e 
	 */
	loginFailure(e) {
		// this is out generic catch all message
		let message = `
			We've encounted an unexpected error, please try again. 
			If you continue to experience this message please 
			<a href="/jsp/customerservice/common/contactus.jsp" target="_blank">contact us</a>.
		`;
		// if we get a response and a status code we should use it
		switch (e.statusCode) {
			case 422: {
				message = e.errorResponse.message;
				break;
			}
			default: {
				// log it out to the console for debugging
				console.error(e);
				break;
			}
		}
		// remove the loading spinner
		this._button.classList.remove('loading');
		// show the error, assumeing that the only error 
		this._showError(message);
	}

	/**
	 * Show the error message
	 * @param  {string} msg The message you want to be displayed
	 */
	_showError(msg) {
		this._error.style.display = 'block';
		this._error.innerHTML = msg;
	}

	/**
	 * Hide the error message
	 */
	_hideError() {
		this._error.style.display = 'none';
	}

	/**
	 * Email validation
	 * 
	 * @param  {string} value
	 * @return {bool}   Whether or not the email address is valid
	 */
	_validateEmail(value) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return emailRegex.test(value);
	}
}

export default LoginModal;