import Component from '../component';
import contentfulDataToPartnershipContentData from '../../utilities/contentful/partnershipContentTransformer';

class PartnershipContent extends Component {
  constructor(elementId, tetrisData) {
    super();
    this.render(elementId, tetrisData);
  }

  /**
   * Initialises the layout of the partnership content and changes
   * the inner HTML of any partnership content component
   *
   * @param {String} elementId => The id of the partnership content to change
   * @param {Object} data => The data from contentful
   */
  render(elementId, data) {
    if (!data) return;
    const partnershipContentData = contentfulDataToPartnershipContentData(data);
    const title = partnershipContentData.title;
    const content = partnershipContentData.content;
    const imageUrl = partnershipContentData.imageUrl;
    const imageAlt = partnershipContentData.imageAlt;

    const partnershipContent = `
			<section class="partnership-content">
				<div class="container">
					<div class="row">
						<div class="col-12">
							${title}
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-sm-8">
							${content}
						</div>
						<div class="d-none d-md-block col-sm-4">
							<img src="${imageUrl}"
								alt="${imageAlt}">
						</div>
					</div>
				</div>
			</section>
		`;

    const element = document.getElementById(elementId);
    element.innerHTML = partnershipContent;
  }
}
export default PartnershipContent;
