let environment = process.env.CONTENTFUL_UAT_ENVIRONMENT;
let apiToken = process.env.CONTENTFUL_UAT_ACCESS_TOKEN;
let spaceId = process.env.CONTENTFUL_UAT_SPACE_ID;
let previewToken = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;
let contentType = 'jsonBlob';

export const isLive = typeof window !== 'undefined' && (window.location.hostname.indexOf('webdev') === -1 && window.location.hostname.indexOf('localhost') === -1);
if (isLive) {
	// if window is defined and we don't have webdev in the URL we are going to
	// show the live version
	environment = process.env.CONTENTFUL_PRODUCTION_ENVIRONMENT;
	apiToken = process.env.CONTENTFUL_PRODUCTION_ACCESS_TOKEN;
	spaceId = process.env.CONTENTFUL_PRODUCTION_SPACE_ID;
	contentType;
}

export const API_SPACE_ID = spaceId;
export const API_TOKEN = apiToken;
export const API_ENV = environment;
export const CONTENT_TYPE = contentType;
export const API_PREVIEW_TOKEN = previewToken;
