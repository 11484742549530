import Component from "../component";
import request from '../../utilities/request';
import Cart from "../../models/cart/cart.js";
import Modal from "../modal/modal";
import ChristmasOffer from "../christmas-content/christmasOffer";
/**
 * Product Upsell
 * Creates a component to load the an upsell icon with links
 * This is based on the product item code having an upsell promotion
 * set up against it (via an API call), if this is the case, we check to see
 * to see if an HTML and image have been created in order to display the UI
 */
class OfferBulkDeals extends Component {
    constructor() {
        super();
        this.allCaseData = [];
        this.allBulkData = [];
        Array.from(document.getElementsByClassName('offer-bulk-deals')).forEach((offer) => {
            const template = offer.querySelector('.template');
            const items = offer.getAttribute('data-items');
            const descAsTitle = offer.getAttribute('data-description-as-title');
            const itemsCodesForAPI = {
                ids: items.split(",").map(item => item.trim()),
            }
            this.getAllQualifyingDeals(itemsCodesForAPI, template, descAsTitle);
        });
    }

    getAllQualifyingDeals(qualifyingItems, template, descAsTitle){
        const getDeals = async ({ ids }) => {
            // Loop over each item in the array and
            // and fetch the data from the API
            const wines = ids.map(async id => {
                const data = await request.promotions.bulk({
                    itemcode: id
                }).then(res => res.json()).then((json) => {
                    return json;
                }).then((json) => {
                    // if the response is in range
                    if (json.statusCode >= 400 && json.statusCode < 600) {
                        throw json;
                    }
                    return json.response;
                }).catch((e) => {
                    // we've errored
                    console.log('error', e.errorResponse.message, `for item ${id}`);
                    return {
                        error: true,
                        msg: e.errorResponse.message
                    };
                });
                return data;
            });

            // Once the loop is finshed resolve the promise
            return Promise.all(wines);
        }
        // Start a async function to get all the API data
        // passed in from our array
        (async () => {
            const wineDataForUI = await getDeals(qualifyingItems);
            // Update the recruit wines array with all the data
            // from the API
            this.allCaseData = wineDataForUI;
            template.innerHTML = this.template(descAsTitle);
            Array.from(document.querySelectorAll('.js-view-case-contents')).forEach((btn) => {
                btn.addEventListener("click", (e) => {
                    e.preventDefault();
                    const itemCode = e.target.getAttribute('data-itemcode');
                    const qualifyingItems = e.target.getAttribute('data-qualifyingItems');
                    this.ChristmasOffer = new ChristmasOffer(itemCode, true, qualifyingItems);
                });
            });
            Array.from(template.querySelectorAll('button.js-add-tiered-to-cart')).forEach((btn) => {
                btn.addEventListener("click", (e) => {
                    e.preventDefault();
    
                    const itemCode = e.target.getAttribute('data-itemcode');
                    const quantity = e.target.getAttribute('data-quantity');
                    const amount = e.target.getAttribute('data-amount');
    
                    const finished = () => {
                        e.target.classList.remove('loading');
                        e.target.innerText = 'Add to basket';
                    }
    
                    e.target.classList.add('loading');
                    e.target.innerText = 'Adding...';
    
                    // remove the styling and text afterwards
                    Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
                    Cart.subscribe(Cart.actions.ADD_FAILURE, finished);
    
                    // add the item to the cart, this will fire a render
                    Cart.addItem(itemCode, quantity, amount, '');
                });
            });
    
            Array.from(template.querySelectorAll('button.js-bulk-add-to-cart')).forEach((btn) => {
                btn.addEventListener("click", (e) => {
                    e.preventDefault();
                    const result = this.allBulkData.filter(deal => deal.promoId === e.target.getAttribute('data-promoid'));
                    const cartItems = [];
                    result[0].bulkUIRules[0].qualifyingItems.map((deal) => {
                        cartItems.push({
                            itemCode: deal,
                            quantity: 1
                        });
                    });
    
                    const finished = () => {
                        e.target.classList.remove('loading');
                        e.target.innerText = 'Add to basket';
                    }
    
                    e.target.classList.add('loading');
                    e.target.innerText = 'Adding...';
    
                    // remove the styling and text afterwards
                    Cart.subscribe(Cart.actions.ADD_MULTI_SUCCESSFUL, finished);
                    Cart.subscribe(Cart.actions.ADD_MULTI_FAILURE, finished);
    
                    // add the item to the cart, this will fire a render
                    Cart.addMultipleItems(cartItems);
                });
            });
        })();
    }

    formatCurrency(amount) {
        const value = amount.toFixed(2);
        return `£${value}`
    }

    christmasDeals(){
        const deals = this.allCaseData?.map((promoItems) => {
            return promoItems.promotionItems
        });
        deals.map((caseitem) => {
            caseitem.map((bulkDeal) => {
                if(bulkDeal?.bulkUIRules[0]?.qualifyingItems) {
                    this.allBulkData.push(bulkDeal); 
                }
            })
        });

        this.allBulkData = [... new Set(this.allBulkData.map(JSON.stringify))].map(JSON.parse);
        this.allBulkData  =  this.allBulkData.sort(function(a, b){
            return a.bulkUIRules[0]?.qualifyingItems.length - b.bulkUIRules[0]?.qualifyingItems.length;
        });
    }

    template(descAsTitle) {
        this.christmasDeals();
        const deal = this.allBulkData.map((deal) => {
            if (deal?.bulkUIRules[0]?.qualifyingItems) {
                const qualifyingItems = deal?.bulkUIRules[0]?.qualifyingItems;
                const images = deal.bulkUIRules[0].qualifyingItems.map((item) => {
                    return `<div>
                                <img src="/images/uk/en/law/product/${item}.png" onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_case.png'" />
                            </div>`;
                }).join('');
                let desc1 = deal.description.split(/£(.*)/s);
                let desc2 = desc1[1].split('-');
                let desc3 = desc2[1].split('SAVE');

                let deal2 = '', deal3 = '';

                if(qualifyingItems.length == 2){
                    deal2 = `<div class="deal-container two-case">
                        <div class="image-container">
                            ${images}
                        </div> 
                        <div class="button-container">
                        ${descAsTitle === null ? 
                            `<div>
                                <p>${desc1[0]}</p>
                                <strong class="offerPrice">£${desc2[0]}</strong><small>(${desc3[0]})</small><br />
                                <span class="savings-text">Save ${desc3[1]}</span><br />
                            </div>` 
                            : ''}
                            <div class="contents-link"><a class="js-view-case-contents" data-case="singlecase" data-qualifyingItems="${qualifyingItems}" data-itemcode="${qualifyingItems[0]}" href="#">View contents&nbsp;&nbsp; <i class="fa fa-chevron-right"></i></a></div>
                            <button class="js-bulk-add-to-cart btn btn-primary btn-block" data-promoid="${deal.promoId}">Add to basket</button></div>
                    </div>`
                }

                if(qualifyingItems.length == 3){
                    deal3 = `<div class="deal-container three-case">
                        <div class="image-container">
                            ${images}
                        </div>
                        <div class="button-container">
                        ${descAsTitle === null ? 
                            `<div>
                                <p>${desc1[0]}</p>
                                <strong class="offerPrice">£${desc2[0]}</strong><small>(${desc3[0]})</small><br />
                                <span class="savings-text">Save ${desc3[1]}</span><br />
                            </div>` 
                            : ''}
                            <div class="contents-link"><a class="js-view-case-contents" data-case="singlecase" data-qualifyingItems="${qualifyingItems}" data-itemcode="${qualifyingItems[0]}" href="#">View contents&nbsp;&nbsp; <i class="fa fa-chevron-right"></i></a></div>
                            <button class="js-bulk-add-to-cart btn btn-primary btn-block" data-promoid="${deal.promoId}">Add to basket</button></div>
                    </div>`;
                }

                return (`${deal2}${deal3}`)
            }

        }).join('');

        return (`<div>${deal}</div>`)
    }
}
export default OfferBulkDeals;

