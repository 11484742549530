import Component from "../component";
import deliveryPromiseText from 'dw-uk-common/src/components/deliveryOptions/deliveryMessage';
import Contentful from '../../models/contentful/contentful.js';
import { CONTENT_TYPE } from '../../models/contentful/config';
import getBrand from "dw-brand-identify";


class DeliveryOptionsInfoPanel extends Component {
    constructor() {
        super("div.delivery-option-panel");
        this._template = this.el.querySelector('.template');
        this.deliveryMsg = '';
        
        // Prepare the data we wish to get from Contentful
        Contentful.fetchByQuery({
            "fields.name": 'Delivery Promise',
            content_type: CONTENT_TYPE,
          });

        // Render the template if you receive the data
        Contentful.subscribe(
            Contentful.actions.FETCH_SUCCESSFUL_BY_QUERY,
            this.render.bind(this)
        );
    }

    async getdeliveryPromiseText(contentfulData) {
        let deliveryPromise =  await deliveryPromiseText().then(data => data);
        if (deliveryPromise && deliveryPromise.deliveryPromiseText) {
            this.deliveryMsg = deliveryPromise;
            this.render(contentfulData);
        } 

        return deliveryPromise;
    }

    collectSectionDesc(averysStoreCollectionDelivery, storeCollectionDelivery) {
        return getBrand().id === 'avy' ? averysStoreCollectionDelivery : storeCollectionDelivery;
    }

    render(data) {
        if (this.deliveryMsg === '') {
            this.getdeliveryPromiseText(data);
        }

        const {standardDeliveryCollectionPromise, standardDeliveryCollectionTrack, averysStoreCollectionDelivery, storeCollectionDelivery } = data.items[0].fields.body;
        const { collectSectionDesc } = this;
        this._template.innerHTML = (`
        <h3>Delivery and collection options</h3>
        <div class="row px-15">
            <div class="col-sm-4">
                <div class="devlivert-option-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="129" height="129" viewBox="0 0 129 129">
                    <defs>
                    <clipPath id="clip-path-truck">
                        <rect id="Rectangle_534" data-name="Rectangle 534" width="55.335" height="55.335" transform="translate(0 0)" class="color-red"/>
                    </clipPath>
                    </defs>
                    <g id="Group_1276" data-name="Group 1276" transform="translate(-544 -1616)">
                    <circle id="Ellipse_33" data-name="Ellipse 33" cx="64.5" cy="64.5" r="64.5" transform="translate(544 1616)" fill="#fff"/>
                    <g id="Group_1219" data-name="Group 1219" transform="translate(-736.566 466.897)">
                        <rect id="Rectangle_533" data-name="Rectangle 533" width="32.481" height="24.512" transform="translate(1317.567 1199.248)" class="color-red"/>
                        <g id="Group_1212" data-name="Group 1212" transform="translate(1317.566 1183.103)" clip-path="url(#clip-path-truck)">
                        <path id="Path_1510" data-name="Path 1510" d="M1687.99,761.343h-8.54a4.537,4.537,0,0,1,8.54,0" transform="translate(-1640.625 -712.083)" class="color-red"/>
                        <path id="Path_1511" data-name="Path 1511" d="M1691.779,732.916v14.174h-4.113a6.244,6.244,0,0,0-12.1,0h-1.844V719.543h10.174Z" transform="translate(-1638.519 -697.829)" class="color-red"/>
                        <path id="Path_1512" data-name="Path 1512" d="M1677.728,731.934v-7.906h6.038l4.682,7.938Z" transform="translate(-1639.993 -699.477)" class="color-red"/>
                        <path id="Path_1513" data-name="Path 1513" d="M1637.491,761.343h-8.541a4.537,4.537,0,0,1,8.541,0" transform="translate(-1622.072 -712.083)" class="color-red"/>
                        <path id="Path_1514" data-name="Path 1514" d="M1687.99,761.343h-8.54a4.537,4.537,0,0,1,8.54,0" transform="translate(-1640.625 -712.083)" class="color-red"/>
                        <path id="Path_1515" data-name="Path 1515" d="M1673.41,753.587V759.6h-6.187a6.243,6.243,0,0,0-12.1,0h-19.843a6.244,6.244,0,0,0-12.1,0h-5.1v-6.009Z" transform="translate(-1618.076 -710.337)" class="color-red"/>
                        <path id="Path_1516" data-name="Path 1516" d="M1637.6,762.877a4.54,4.54,0,1,1-9.079,0,4.409,4.409,0,0,1,.27-1.534,4.537,4.537,0,0,1,8.541,0,4.419,4.419,0,0,1,.269,1.534" transform="translate(-1621.915 -712.083)" class="color-red"/>
                        <path id="Path_1517" data-name="Path 1517" d="M1688.1,762.877a4.539,4.539,0,1,1-9.079,0,4.422,4.422,0,0,1,.269-1.534,4.538,4.538,0,0,1,8.541,0,4.428,4.428,0,0,1,.269,1.534" transform="translate(-1640.469 -712.083)" class="color-red"/>
                        </g>
                        <path id="Path_1518" data-name="Path 1518" d="M.237.48H7.47L13.1,9.987H.266Z" transform="translate(1354.369 1206.12)" fill="#f8f5ef"/>
                    </g>
                    </g>
                    </svg>
                </div>
                <div class="devlivert-option-desc">
                   ${(this.deliveryMsg !== '') ? `Standard Delivery ${this.deliveryMsg.deliveryPromiseText + '. ' + standardDeliveryCollectionPromise}` : standardDeliveryCollectionPromise}
                </div>
            </div>
            <div class="col-sm-4">
                <div class="devlivert-option-icon">
                   
                    ${((getBrand().id === 'stw') || (getBrand().id === 'avy')) ? `
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129">
                    <g id="Group_4914" data-name="Group 4914" transform="translate(-894 -1616)">
                    <circle id="Ellipse_34" data-name="Ellipse 34" cx="64.5" cy="64.5" r="64.5" transform="translate(894 1616)" fill="#fff"/>
                    <g id="Group_1282" data-name="Group 1282" transform="translate(916.62 1659)">
                        <g id="Group_608" data-name="Group 608" transform="translate(20.379 0)">
                        <rect id="Rectangle_249" data-name="Rectangle 249" width="8.124" height="8.095" transform="translate(7.563 25.056)" class="color-red"/>
                        <rect id="Rectangle_250" data-name="Rectangle 250" width="8.124" height="8.052" transform="translate(7.563 34.126)" class="color-red"/>
                        <path id="Path_311" data-name="Path 311" d="M1264.623,703.779v23.707h5.276V708.205h16.7v19.281h5.212v-12.1h8.206v12.1h6.83V703.779l-20.427-18.47Z" transform="translate(-1264.623 -685.309)" class="color-red"/>
                        </g>
                    </g>
                    </g>
                </svg>
                    ` : `
                        <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129">
                        <g id="Group_1287321" data-name="Group 1287" transform="translate(-894 -1616)">
                        <circle id="Ellipse_34" data-name="Ellipse 34" cx="64.5" cy="64.5" r="64.5" transform="translate(894 1616)" fill="#fff"/>
                        <g id="Group_1282" data-name="Group 1282" transform="translate(921.395 1646.283)">
                            <g id="Group_608" data-name="Group 608" transform="translate(19.458 0)">
                            <rect id="Rectangle_249" data-name="Rectangle 249" width="7.757" height="7.729" transform="translate(7.221 23.923)" fill="#cf004f"/>
                            <rect id="Rectangle_250" data-name="Rectangle 250" width="7.757" height="7.688" transform="translate(7.221 32.583)" fill="#cf004f"/>
                            <path id="Path_311" data-name="Path 311" d="M1264.623,702.944v22.635h5.037V707.17H1285.6v18.409h4.976V714.031h7.835v11.548h6.521V702.944l-19.5-17.635Z" transform="translate(-1264.623 -685.309)" fill="#cf004f"/>
                            </g>
                            <g id="Group_1281" data-name="Group 1281" transform="translate(0 51.453)">
                            <path id="Path_245" data-name="Path 245" d="M457.934,443.652H455.7V458.2h4.56v-1.872h-2.33Z" transform="translate(-455.704 -443.477)" fill="#cf004f"/>
                            <g id="Group_542" data-name="Group 542" transform="translate(6.145 0.175)">
                                <path id="Path_246" data-name="Path 246" d="M538.381,448.623c.057.7.117,1.363.188,2.123l.4,3.183h-1.183l.4-3.189c.079-.787.141-1.443.195-2.117m-1.278-4.971L535.056,458.2h2.185l.32-2.564h1.631l.32,2.564H541.8l-2.079-14.552Z" transform="translate(-535.056 -443.652)" fill="#cf004f"/>
                            </g>
                            <rect id="Rectangle_226" data-name="Rectangle 226" width="2.229" height="14.552" transform="translate(14.29 0.175)" fill="#cf004f"/>
                            <path id="Path_247" data-name="Path 247" d="M696.831,445.524h1.9V458.2h2.229v-12.68h1.9v-1.872h-6.024Z" transform="translate(-678.649 -443.477)" fill="#cf004f"/>
                            <path id="Path_248" data-name="Path 248" d="M801.595,449.524h-1.607v-5.872h-2.229V458.2h2.229v-6.838h1.607V458.2h2.219V443.652h-2.219Z" transform="translate(-771.967 -443.477)" fill="#cf004f"/>
                            <g id="Group_543" data-name="Group 543" transform="translate(33.243 0.175)">
                                <path id="Path_249" data-name="Path 249" d="M906.186,451.318c-.051.522-.093.994-.13,1.469-.032-.474-.07-.948-.118-1.469l-.764-7.665h-2.319l-.753,7.666c-.049.5-.089.953-.122,1.418-.035-.446-.075-.9-.125-1.419l-.774-7.665h-2.228L900.7,458.2h2.458l.66-6.841c.08-.827.141-1.5.193-2.182.054.69.115,1.366.189,2.182l.649,6.842h2.479l1.8-14.552h-2.165Z" transform="translate(-898.853 -443.652)" fill="#cf004f"/>
                                <path id="Path_250" data-name="Path 250" d="M1042.5,448.623c.056.7.117,1.363.188,2.122l.4,3.183h-1.183l.4-3.189c.078-.787.141-1.443.195-2.116m-1.278-4.971-2.046,14.552h2.185l.32-2.564h1.631l.32,2.564h2.289l-2.079-14.552Z" transform="translate(-1028.31 -443.652)" fill="#cf004f"/>
                            </g>
                            <rect id="Rectangle_227" data-name="Rectangle 227" width="2.229" height="14.552" transform="translate(52.354 0.175)" fill="#cf004f"/>
                            <path id="Path_251" data-name="Path 251" d="M1200.951,445.524h1.9V458.2h2.229v-12.68h1.9v-1.872h-6.024Z" transform="translate(-1144.756 -443.477)" fill="#cf004f"/>
                            <path id="Path_252" data-name="Path 252" d="M1304.108,451.356h2.248v-1.782h-2.248v-4.051h2.526v-1.872h-4.755V458.2h4.879v-1.872h-2.65Z" transform="translate(-1238.246 -443.477)" fill="#cf004f"/>
                            <g id="Group_544" data-name="Group 544" transform="translate(70.177 0)">
                                <path id="Path_253" data-name="Path 253" d="M1390.755,448.705l-.93-1.481a5.9,5.9,0,0,1-1.21-3.224c0-.577.227-.869.675-.869.415,0,.8.186.8,1.533v1.816l2.106-.393V444.6c0-2.134-.97-3.216-2.883-3.216a2.545,2.545,0,0,0-2.821,2.872,7.4,7.4,0,0,0,1.519,4.1l.918,1.5a6.78,6.78,0,0,1,1.316,3.57c0,.507-.135,1.111-.778,1.111-.451,0-.932-.142-.932-1.835v-1.947l-2.157.4v1.595c0,2.924,1.663,3.537,3.059,3.537,1.919,0,2.975-1.1,2.975-3.095a8.241,8.241,0,0,0-1.654-4.491" transform="translate(-1386.376 -441.388)" fill="#cf004f"/>
                            </g>
                            </g>
                        </g>
                        </g>
                        </svg>
                    `}
                </div>
                <div class="devlivert-option-desc">
                    ${collectSectionDesc(averysStoreCollectionDelivery, storeCollectionDelivery )}
                </div>
            </div>
            <div class="col-sm-4">
                <div class="devlivert-option-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="129" height="129" viewBox="0 0 129 129">
                    <g id="Group_1291" data-name="Group 1291" transform="translate(-1230 -1617)">
                    <circle id="Ellipse_32" data-name="Ellipse 32" cx="64.5" cy="64.5" r="64.5" transform="translate(1230 1617)" fill="#fff"/>
                    <g id="Group_1218" data-name="Group 1218" transform="translate(-44 440)">
                        <line id="Line_167" data-name="Line 167" x2="88.5" transform="translate(1297 1261.5)" fill="none" stroke="#327474" stroke-width="3"/>
                        <g id="Group_1171" data-name="Group 1171" transform="translate(107 26.208)">
                        <g id="Group_1162" data-name="Group 1162" transform="translate(-3477.936 4256)">
                            <g id="Group_452" data-name="Group 452" transform="translate(4659.936 -3033.208)">
                            <circle id="Ellipse_20" data-name="Ellipse 20" cx="12.104" cy="12.104" r="12.104" transform="translate(0)" fill="#327474"/>
                            </g>
                            <path id="Path_290" data-name="Path 290" d="M152.34,106.793l-3.621-3.6a.578.578,0,0,1,0-.781l.8-.78a.483.483,0,0,1,.379-.174.55.55,0,0,1,.4.174l2.428,2.428,5.2-5.2a.552.552,0,0,1,.4-.174.483.483,0,0,1,.379.174l.8.78a.578.578,0,0,1,0,.781l-6.4,6.375a.527.527,0,0,1-.781,0Z" transform="translate(4518.332 -3124.262)" fill="#fff"/>
                        </g>
                        </g>
                        <g id="Group_1185" data-name="Group 1185" transform="translate(311.5 -56.421)">
                        <g id="Group_1173" data-name="Group 1173" transform="translate(1052.5 1305.421)">
                            <g id="Group_1172" data-name="Group 1172" transform="translate(0 0)">
                            <g id="Group_452-2" data-name="Group 452">
                                <g id="Path_1657" data-name="Path 1657" fill="#fff">
                                <path d="M 12.10406494140625 23.7081356048584 C 9.004505157470703 23.7081356048584 6.090475082397461 22.5011043548584 3.898754835128784 20.30938529968262 C 1.707034945487976 18.1176643371582 0.5000049471855164 15.2036247253418 0.5000049471855164 12.10406494140625 C 0.5000049471855164 9.004505157470703 1.707034945487976 6.090475082397461 3.898754835128784 3.898754835128784 C 6.090475082397461 1.707034945487976 9.004505157470703 0.5000049471855164 12.10406494140625 0.5000049471855164 C 15.2036247253418 0.5000049471855164 18.1176643371582 1.707034945487976 20.30938529968262 3.898754835128784 C 22.5011043548584 6.090475082397461 23.7081356048584 9.004505157470703 23.7081356048584 12.10406494140625 C 23.7081356048584 15.2036247253418 22.5011043548584 18.1176643371582 20.30938529968262 20.30938529968262 C 18.1176643371582 22.5011043548584 15.2036247253418 23.7081356048584 12.10406494140625 23.7081356048584 Z" stroke="none"/>
                                <path d="M 12.10406494140625 0.9999942779541016 C 9.138065338134766 0.9999942779541016 6.349584579467773 2.155025482177734 4.252304077148438 4.252304077148438 C 2.155025482177734 6.349584579467773 0.9999942779541016 9.138065338134766 0.9999942779541016 12.10406494140625 C 0.9999942779541016 15.07006454467773 2.155025482177734 17.85854530334473 4.252304077148438 19.95582580566406 C 6.349584579467773 22.05310440063477 9.138065338134766 23.2081241607666 12.10406494140625 23.2081241607666 C 15.07006454467773 23.2081241607666 17.85854530334473 22.05310440063477 19.95582580566406 19.95582580566406 C 22.05310440063477 17.85854530334473 23.2081241607666 15.07006454467773 23.2081241607666 12.10406494140625 C 23.2081241607666 9.138065338134766 22.05310440063477 6.349584579467773 19.95582580566406 4.252304077148438 C 17.85854530334473 2.155025482177734 15.07006454467773 0.9999942779541016 12.10406494140625 0.9999942779541016 M 12.10406494140625 -5.7220458984375e-06 C 18.78895568847656 -5.7220458984375e-06 24.2081241607666 5.419174194335938 24.2081241607666 12.10406494140625 C 24.2081241607666 18.78895568847656 18.78895568847656 24.2081241607666 12.10406494140625 24.2081241607666 C 5.419174194335938 24.2081241607666 -5.7220458984375e-06 18.78895568847656 -5.7220458984375e-06 12.10406494140625 C -5.7220458984375e-06 5.419174194335938 5.419174194335938 -5.7220458984375e-06 12.10406494140625 -5.7220458984375e-06 Z" stroke="none" fill="#327474"/>
                                </g>
                            </g>
                            <g id="Group_1161" data-name="Group 1161" transform="translate(5.19 5.213)">
                                <path id="Path_1472" data-name="Path 1472" d="M4893.7-3051.185h3.226v-4.18h3.537v4.18h3.235V-3057l-4.9-4.429-5.1,4.429Z" transform="translate(-4891.494 3064.13)" class="color-red"/>
                                <path id="Path_1473" data-name="Path 1473" d="M4886.406-3063.919l6.545-5.521,6.324,5.521.9-.87v-.395l-7.137-6.1-7.383,6.1v.395Z" transform="translate(-4885.649 3071.28)" class="color-red"/>
                            </g>
                            </g>
                        </g>
                        </g>
                        <g id="Group_1217" data-name="Group 1217" transform="translate(147 26.208)">
                        <g id="Group_1162-2" data-name="Group 1162" transform="translate(-3479.936 4256)">
                            <g id="Group_452-3" data-name="Group 452" transform="translate(4659.936 -3033.208)">
                            <circle id="Ellipse_20-2" data-name="Ellipse 20" cx="12.104" cy="12.104" r="12.104" transform="translate(0)" fill="#327474"/>
                            </g>
                            <path id="Path_290-2" data-name="Path 290" d="M152.34,106.793l-3.621-3.6a.578.578,0,0,1,0-.781l.8-.78a.483.483,0,0,1,.379-.174.55.55,0,0,1,.4.174l2.428,2.428,5.2-5.2a.552.552,0,0,1,.4-.174.483.483,0,0,1,.379.174l.8.78a.578.578,0,0,1,0,.781l-6.4,6.375a.527.527,0,0,1-.781,0Z" transform="translate(4518.332 -3124.262)" fill="#fff"/>
                        </g>
                        </g>
                    </g>
                    <g id="Group_1216" data-name="Group 1216" transform="translate(-403.669 437.605)">
                        <g id="Group_1215" data-name="Group 1215" transform="translate(1677.543 1197.479)">
                        <path id="Path_605" data-name="Path 605" d="M1126.788,222.41h-24.481a1.877,1.877,0,0,1-1.726-2V177.993a1.877,1.877,0,0,1,1.726-2h24.481a1.876,1.876,0,0,1,1.725,2v42.418a1.877,1.877,0,0,1-1.725,2" transform="translate(-1093.83 -175.994)" class="color-red" fill-rule="evenodd"/>
                        <rect id="Rectangle_319" data-name="Rectangle 319" width="22.651" height="36.13" transform="translate(9.283 3.297)" fill="#fff"/>
                        <rect id="Rectangle_321" data-name="Rectangle 321" width="41.151" height="45.571" transform="translate(0 0.844)" fill="none"/>
                        <rect id="Rectangle_518" data-name="Rectangle 518" width="19.41" height="12.906" transform="translate(10.971 5.378)" fill="#b1927b"/>
                        <path id="Path_606" data-name="Path 606" d="M1131.261,253.794a1.886,1.886,0,1,1-1.886-1.94,1.913,1.913,0,0,1,1.886,1.94" transform="translate(-1108.502 -210.514)" fill="#fff" fill-rule="evenodd"/>
                        </g>
                        <rect id="Rectangle_539" data-name="Rectangle 539" width="16.875" height="1.985" transform="translate(1689.681 1204.686)" fill="#fff"/>
                        <rect id="Rectangle_540" data-name="Rectangle 540" width="15.386" height="1.985" transform="translate(1689.681 1207.168)" fill="#fff"/>
                        <rect id="Rectangle_541" data-name="Rectangle 541" width="16.875" height="1.985" transform="translate(1689.681 1209.649)" fill="#fff"/>
                        <rect id="Rectangle_542" data-name="Rectangle 542" width="13.897" height="1.985" transform="translate(1689.681 1212.131)" fill="#fff"/>
                    </g>
                    </g>
                    </svg>
                </div>
                <div class="devlivert-option-desc">
                    ${standardDeliveryCollectionTrack}
                </div>
            </div>
        </div>
        `)
    }
}
export default DeliveryOptionsInfoPanel;