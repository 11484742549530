import Component from "../component";

class QuickLinks extends Component {
  constructor(data, element) {
    super(element);

    this.render(data);
  }

  subheaderTemplate(header) {
    return `
      <div class="row">
        <div class="col-12">
          <h2>${header}</h2>
        </div>
      </div>
    `;
  }

  buttonTemplate(button, isLast) {
    const {
      fields: {
        entryTitle,
        link: {
          fields: { text, url },
        },
      },
    } = button;

    return `
      <div class="col-12 col-sm-4 mb-3 ${!isLast ? "mb-md-0" : ""}">
        <a class="btn btn-outline-primary" href="${url}" data-section="Quick Links" data-offer="${entryTitle}">
          ${text}
          <span class="arrow right"></span>
        </a>
      </div>
    `;
  }

  mainTemplate(header, links) {
    const buttons = links
      .map((link, i) => this.buttonTemplate(link, i + 1 === links.length))
      .join(" ");

    return `
      <div class="container p-0">
        ${header && this.subheaderTemplate(header)}
        <div class="row">
          ${buttons}
        </div>
      </div>
    `;
  }

  render(data) {
    const { title, links } = data;

    if (!links) {
      return null;
    }

    this.el.innerHTML = this.mainTemplate(title, links);
  }
}

export default QuickLinks;
