import Component from "../component";
import request from '../../utilities/request';
import event from "../../utilities/event";
import Modal from "../modal/modal";
/**
 * Product Upsell
 * Creates a component to load the an upsell icon with links
 * This is based on the product item code having an upsell promotion
 * set up against it (via an API call), if this is the case, we check to see
 * to see if an HTML and image have been created in order to display the UI
 */
class CaseContents extends Component {
    constructor(itemcode) {
        super();
        this.getCase(itemcode)      
    }
    getCase(itemcode) {
        return request.product.case({
            itemcode
        }).then((res) => res.json()).then((res) => {
            if (res.statusCode !== 0) {
                throw new Error(res.errorResponse.message);
            }
            this.caseContentsTemplate(res.response)
        }).catch((error) => {
            console.log('Error', error);
            event('Errors', 'Upsell', error);
        });
    }

    caseContentsTemplate(data) {
        let winesincase = [];
        let rating = [];
        let template = [];

        winesincase = data.contentProducts.map(wine => {
            if (wine.referencedProduct.ratingDetails && wine.referencedProduct.ratingDetails.productRating) {
                rating = (
                    `<span class="stars stars-small rating-${wine.referencedProduct.ratingDetails.productRating.roundAvgRating}Star"></span>`
                )
            }
            
            const grapeName = wine.referencedProduct.grapeName ? (
                `<li>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
                            <g fill="#333" fill-rule="evenodd">
                                <path d="M12.356 10.355a.959.959 0 0 1-.952-.963c0-.531.427-.963.952-.963.525 0 .953.432.953.963a.959.959 0 0 1-.953.963zm-.938-5.526c-.775.55-1.818.55-2.594 0l-.05-.036c.822-.609 1.844-.613 2.669-.01l.02.014-.045.032zm1.866 2.52a.515.515 0 0 1-.297-.468V4.788h-.002a1.06 1.06 0 0 0-.431-.841l-.338-.247c-1.285-.94-2.942-.933-4.222.016l-.314.233a1.06 1.06 0 0 0 .018 1.712l.368.262a3.549 3.549 0 0 0 3.22.457c.21-.073.43.081.43.306v.2c0 .2-.115.381-.294.465a2.255 2.255 0 0 0-1.283 2.217c.084 1.098.96 1.984 2.046 2.066 1.303.1 2.395-.945 2.395-2.242 0-.906-.532-1.687-1.296-2.044zM9.743 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M12.373 16.848a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207A2.21 2.21 0 0 1 12.373 20M15.097 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M17.445 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207"></path>
                                <path d="M7.207 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152A2.21 2.21 0 0 1 5 9.34a2.21 2.21 0 0 1 2.207-2.207A2.21 2.21 0 0 1 9.414 9.34a2.21 2.21 0 0 1-2.207 2.207"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="text">${wine.referencedProduct.grapeName}</div>
                </li>`
            ) : '';

            const countryName = wine.referencedProduct.countryName ? (
                `<li>
                    <div class="icons">
                        <img src="/images/uk/en/common/icons/flags/${wine.referencedProduct.countryName}.png" class="js-default-image-handler" alt="${wine.referencedProduct.countryName}" title="${wine.referencedProduct.countryName}" />
                    </div>
                    <div class="text">${wine.referencedProduct.countryName}</div>
                </li>`
            ) : '';

            return (
                `
                <div class="item">
                    <div style="display: flex">
                        <div class="image">
                        <img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${wine.referencedProduct.smallImage}" /></div>
                        <div class="detail">
                            <h4>${wine.referencedProduct.name} ${wine.referencedProduct.vintage ? wine.referencedProduct.vintage : ''}</h4>
                            ${rating}
                            ${wine.referencedProduct.productType === 'wine' ? (`<p>x ${wine.quantity} ${wine.quantity > 1 ? 'bottles' : 'bottle' }</p>`) :
                            (`<p>x ${wine.quantity}</p>`) }
                            <ul class="detail-table">
                                ${grapeName}
                                ${countryName}
                            </ul>
                            <p>${wine.referencedProduct.webHeadline}</p>
                        </div>
                    </div>
                </div>
                `
            )
        }).join(' ');

        template = (`
            <div>
                <div class="selection-container">
                    <div id="case-webheadline">${data.parentProduct.webHeadline ? data.parentProduct.webHeadline : ''}</div>
                </div>
                <div id="modal-wines-in-case" class="case-container">            
                    ${winesincase}
                </div> 
            </div>            
        `);

        this.openModal(template, data.parentProduct.name);
    }

    openModal(template, name) {
        this.modal = new Modal({
            title: name,
            body: template,
            containsForm: true,
            showHeader: true,
            showFooter: false,
            largeModal: true,
            id: 'case-contents',
        });
        this.modal.open();
    }

}

export default CaseContents;