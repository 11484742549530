import richTextToHtml from "./richText";

/**
 * Transforms the data from contentful into an easier to consume object
 * 
 * @param {Object} data => The data from contentful
 * @return {Object} => Properties of the mixed case highlights after transformation
 */
const contentfulDataToMixedCaseHighlightsData = (data) => {
  const {
    title,
    content,
    image,
    button
  } = data?.fields || {};
  
  return {
    title,
    content: richTextToHtml(content),
    imageUrl: image ? image.fields.asset.fields.file.url : null,
    imageAlt: image ? image.fields.accessibilityText : null,
    buttonText: button.fields.link.fields.text,
    buttonUrl: button.fields.link.fields.url,
    buttonType: button.fields.buttonType.toLowerCase(),
  };
}

export default contentfulDataToMixedCaseHighlightsData;