import { $ } from "../../utilities/min";
import Component from "../component";
import LoginModal from "../header/login";
import User from "../../models/user/user";
import request from '../../utilities/request';

class NotifyButton extends Component {
	constructor() {
        super('.notify-button-container');
        
        // Notification button for login
        Array.from(document.querySelectorAll('.notify-button')).forEach((btn) => {
            this.addEvent(btn, "click", e => {
                e.preventDefault();
                new LoginModal();
            });			
        });
        
        User.subscribe(
            User.actions.UPDATED_SUCCESSFUL,            
            this.render.bind(this)
        );

        User.fetch();   
             
    }

    handleChange(checkbox, itemCode, infobox) {
        // Remove the notification box if open
        if (
			infobox.querySelector('.notify-confirmation')
		) {
            infobox.querySelector('.notify-confirmation').remove();
		}
        
        
        if (checkbox.target.checked) {   
            this.applyInterest(checkbox, itemCode, infobox);
        }
    }

    applyInterest(checkbox, itemCode, infobox) {
		// make the request
		return request.user.interest.item({itemCode}).then((res) => {
			// decode the JSON
			return res.json();
		}).then((json) => {
			// if the response is in range
			if (json.statusCode >= 400 && json.statusCode < 600) {
				throw json;
            }
            this.success(infobox);

		}).catch((error) => {
            if (error && error.errorResponse.message.includes('already exists in user interests')) {
                this.signedUp(checkbox, infobox);
            } else {
                this.error(checkbox, error, infobox);
            }             
		});
    }
    
    success(infobox) {
        const msg = document.createElement("div");
        msg.classList.add('notify-confirmation', 'alert', 'alert-success');
        msg.innerHTML = 'Thank you';
        infobox.appendChild(msg);

        // Hide the notification after defined timeout
        setTimeout(function(){
            msg.remove();
        }, 5000);
    }

    signedUp(checkbox, infobox) {
        checkbox.target.checked = true;
        checkbox.target.disabled = true;
        console.log(infobox.find('.form-check-label'));
        infobox.find('.form-check-label').innerHTML = 'You are signed up for alerts';
    }

    error(checkbox, error, infobox) {
        const msg = document.createElement("div");
        msg.classList.add('notify-confirmation', 'alert', 'alert-danger');
        msg.innerHTML = error.errorResponse.message;
        infobox.appendChild(msg);

        // Hide the notification after defined timeout
        setTimeout(function(){
            checkbox.target.checked = false;
            msg.remove();
        }, 5000);
    }
    
    render() {
        Array.from(document.querySelectorAll('.info')).forEach((info) => {

            const itemCode = info.dataset.itemcode;

            if (User.status === 'hard') {

                // Hide the login button
                info.closest(".notify-button-container").find(".notify-button").style.display = "none";

                // Build the checkbox
                info.innerHTML = (`
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" name="registerInterest" id="${itemCode}" value="">
                    <label class="form-check-label" for="${itemCode}">Tick here to receive email alerts for future offers on this wine</label>
                </div>
                `)

                this.addEvent(info.find("input[name='registerInterest']"), "change", e => {
                    e.preventDefault();
                    this.handleChange(e, itemCode, info);
                });
            }
        });
    }
}

export default NotifyButton;
