import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';

const defaultOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => `<strong>${text}</strong>`,
    [MARKS.ITALIC]: (text) => `<i>${text}</i>`,
    [MARKS.UNDERLINE]: (text) => `<u>${text}</u>`,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, next) => `<p>${next(node.content)}</p>`,
    [BLOCKS.HEADING_1]: (node, next) => `<h1>${next(node.content)}</h1>`,
    [BLOCKS.HEADING_2]: (node, next) => `<h2>${next(node.content)}</h2>`,
    [BLOCKS.HEADING_3]: (node, next) => `<h3>${next(node.content)}</h3>`,
    [BLOCKS.HEADING_4]: (node, next) => `<h4>${next(node.content)}</h4>`,
    [BLOCKS.HEADING_5]: (node, next) => `<h5>${next(node.content)}</h5>`,
    [BLOCKS.HEADING_6]: (node, next) => `<h6>${next(node.content)}</h6>`,
  },
};

/**
 * Converts a contentful RichText object to a html string
 *
 * @param {RichText} content => RichText from contentful
 * @return {HtmlString} => Html with the styling applied
 */
const richTextToHtml = (content, options = defaultOptions) => {
  return documentToHtmlString(content, options);
};

export default richTextToHtml;
