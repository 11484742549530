import Component from "../../component";
import { $ } from "../../../utilities/min";
import LoginModal from "../login";
import LogoutModal from "../logout";
import User from "../../../models/user/user";
import hoverintent from 'hoverintent';
import getBrand from 'dw-brand-identify';

class MenuView extends Component {
	constructor() {
		super("#main-navigation");
		this._brandObj = getBrand();
		this._touchStartX = 0;
		this.extraNav = [];
		this.truncateList = document.querySelectorAll('ul.truncateList .nav-top-level');
		this.submenu = document.querySelector('#main-navigation.nav-intended .nav-top-level:hover > .submenu');
		// get everything else on the page
		// this._everythingElse = Array.from(document.querySelectorAll('body > :not(.header)')).concat(Array.from(document.querySelectorAll('body > .header > :not(#header-variations)')));
		this._everythingElse = Array.from(
			document.querySelectorAll("body > :not(.header)")
		).concat(
			Array.from(
				document.querySelectorAll(
					"body .push-container > :not(#new-homepage-test-header)"
				)
			)
		);
		this._everythingElseDisplay = [];
		this._currentScroll = 0;

		this.el.find("ul > li .nav-item").forEach((element, i) => {
			element.on("touchstart", ()=> {
				this._touchStartX = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			});
			element.on("touchend",()=> {
				const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
				const distance = this._touchStartX - top;

				// the distance was more than 15px
				// so we're assuming they intended
				// to swipe to scroll the nav and
				// not selecting a nav item.
				if (Math.abs(distance) < 15){
					this.toggleLi(element);
				}
			});
		});
		this.addEvent(this.el.find(".login-link"), "click", e => {
			e.preventDefault();
			const login = new LoginModal();
		});

		this.el
			.find(".nav-top-level:not(.flipper-container) > .nav-item > a")
			.forEach(element => {
				this.addEvent(element, "touchstart", e => {
					if(this._brandObj.id !== 'hsb'){
						this.tabletMenu(e);
					 }
					this.mouseover(e);
				});
				if(this._brandObj.id !== 'hsb'){
					this.addEvent(element, "touchend", e => {
						e.preventDefault();
					});
				}
			});

		this.el
			.find("ul > li.nav-top-level:not(.flipper-container)")
			.forEach((element, i) => {
				element.on("mouseover", this.mouseover.bind(this));
				element.on("mouseout", this.mouseout.bind(this));
			});

		let mainNav = document.getElementById('main-navigation');
		let reduceHoverAggression = hoverintent(mainNav, function(e) {
			mainNav.classList.add('nav-intended');
		}, function(e) {
			mainNav.classList.remove('nav-intended');
		}).options({
			timeout: 400,
			handleFocus: true
		});

		// this.addEvent(this.el.find('.logout-link'), 'click', (e) => {
		// 	e.preventDefault();
		// 	const logout = new LogoutModal();
		// });

		window.addEventListener("resize", ()=> {
			if(this.isOpen() && document.body.offsetWidth > 768) {
				this.close();
				const openMenu = this.el.find('li.nav-top-level.open-nav');
				const openSubMenus = this.el.find('li.open-nav:not(.nav-top-level)');
				if(openSubMenus) {
					if(Array.isArray(openSubMenus)) {
						if(openSubMenus.length > 0) {
							openSubMenus.forEach(elem=> {
								elem.classList.add("close-nav");
								elem.classList.remove("open-nav");
							});
						}
					} else {
						openSubMenus.classList.add("close-nav");
						openSubMenus.classList.remove("open-nav");
					}
				}
				if(openMenu) {
					let anchor = '';
					if(Array.isArray(openMenu)) {
						if(openMenu.length > 0) {
							openMenu.forEach(elem => elem.classList.remove("open-nav"));
							anchor = openMenu[0].querySelector(".nav-item > a");
						}
					} else {
						openMenu.classList.remove("open-nav");
						anchor = openMenu.querySelector(".nav-item > a");
					}
					if(anchor) {
						const event1 = new Event('touchstart');
						const event2 = new Event('touchend');
						anchor.dispatchEvent(event1);
						anchor.dispatchEvent(event2);
					}
				}

			}else if(document.body.offsetWidth < 768 && this.el.find("li.nav-top-level.selected")) {
				this.x && this.x.parentNode && this.x.click();
			}

			this.setHeightForSubmenu();
		});
		if(document.body.offsetWidth > 767 && document.body.offsetWidth < 992){
			this.truncateMenu();
		}
	}

	toggle() {
		this.el.classList.contains("expanded") ? this.close() : this.open();
	}

	toggleLi(element, e) {
		if (document.body.offsetWidth >= 768) {
			return;
		}
		//e.preventDefault();
		const parent = element.closest("li");

		if (parent.classList.contains("open-nav")) {
			parent.classList.add("close-nav");
			parent.classList.remove("open-nav");
			// hide all the child submenus
			// this.element.find('li').forEach(menu => menu.classList.remove('open-nav'));
		} else {
			parent.classList.remove("close-nav");
			parent.classList.add("open-nav");
		}
	}

	isOpen() {
		return this.el.classList.contains("expanded") ? true : false;
	}

	open() {
		// create the overlay and append to the DOM
		this.overlay = document.createElement("div");
		this.overlay.id = "navigation-overlay";
		document.querySelector("#menu").classList.add("open");
		// add an event the close the menu if the overlay is clicked
		this.overlay.addEventListener("click", this.close.bind(this));
		document.body.appendChild(this.overlay);
		// show that the navigation is now expanded
		this.el.classList.add("expanded");
		document.querySelector("header#new-homepage-test-header").classList.add("expanded");
		document.body.style.overflow = 'visible';
		document.body.classList.add("menu-open");
		// set the aria field
		this.el.setAttribute("aria-hidden", true);

		// set everything else on the body to be hidden
		this._everythingElse.forEach((element,i) => {
			// push the current state of the everything else
			if(element.style.display !== this._everythingElseDisplay[i]) {
				this._everythingElseDisplay[i] = element.style.display + "";
			}
			if (window.location.href.indexOf("/gifts") > -1) { //UXE-10480
				document.getElementsByClassName('social-links')[1] !== undefined && (document.getElementsByClassName('social-links')[1].style.display = "none");
				document.getElementsByClassName('content')[0] !== undefined && (document.getElementsByClassName('content')[0].style.display = "none");
				document.getElementsByClassName('footer-copyright')[0] !== undefined && (document.getElementsByClassName('footer-copyright')[0].style.display = "none");
				document.getElementsByClassName('footer-bottom') && (document.getElementsByClassName('footer-bottom')[0].style.display = "none");
				document.getElementsByClassName('footer-nav-links') && (document.getElementsByClassName('footer-nav-links')[0].style.display = "none");
			} else {
				// now set it all to none
				element.style.display = "none";
			}
		});
		
		// set the scroll offset to be 0, taking the user to the top of the page
		// this is what happens on JL, otherwise we have a sticky menu a user
		// can't scroll
		// we set it on a timer so the user doesn't see this happen
		setTimeout(() => {
			// save the scroll position
			const top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			const left = window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0;
			this._currentScroll = [
				top,
				left
			];
			// scroll to the top
			window.scrollTo(0, 0);
		}, 500);
	}

	mouseover(e) {
		if(e.target.innerText === 'Spirits'){
			document.body.offsetWidth < 768 ? window.location.href = '/wines?Ntt=spirits' :
			(this.overlay!=null) ?
				this.overlay.parentNode.removeChild(this.overlay) : '';	
		}
		else{
			// we if already have the overlay ignore it
			if (this.overlay || document.body.offsetWidth < 768) {
				return;
			}
			// create the overlay and append to the DOM
			if(this._brandObj.id != 'hsb'){
				this.overlay = document.createElement("div");
				this.overlay.id = "navigation-overlay";
				document.body.appendChild(this.overlay);
			}
		}
	}

	mouseout(e) {
		// mouseout event shouldn't be fired on mobile, but just in case don't
		// do this.
		if (document.body.offsetWidth < 768) {
			return;
		}

		let node = e.toElement || e.relatedTarget;
		if (!node) {
			if(this.overlay!=null){
				this.overlay.parentNode.removeChild(this.overlay);
			}
			else {}
			document.getElementsByTagName("html")[0].style.overflow = "initial";
			this.overlay = null;
			return;
		}
		if (
			node.classList &&
			(node.classList.contains("front-panel") ||
				node.classList.contains("logo") ||
				node.classList.contains("back-panel"))
		) {
			this.overlay.parentNode.removeChild(this.overlay);
			this.overlay = null;
			return;
		}
		while (node != null) {
			if (node.classList && node.classList.contains("nav-top-level")) {
				// we are in the same menu
				return;
			}
			node = node.parentNode;
		}
		if (this.overlay && this.overlay.parentNode) {
			this.overlay.parentNode.removeChild(this.overlay);
		}
		this.overlay = null;
	}

	close() {
		this.el.classList.remove("expanded");
		this.overlay.parentNode &&
			this.overlay.parentNode.removeChild(this.overlay);
		this.el.setAttribute("aria-hidden", false);
		document.querySelector("#menu").classList.remove("open");
		document
			.querySelector("header#new-homepage-test-header")
			.classList.remove("expanded");
		document.body.classList.remove("menu-open");
		// put everything back to have it was
		this._everythingElse.forEach((element, i) => {
			// now set it all to none
			element.style.display = this._everythingElseDisplay[i];
		});
		if (window.location.href.indexOf("/gifts") > -1) { //UXE-10480
			document.getElementsByClassName('social-links') && (document.getElementsByClassName('social-links')[1].style.display = "block");
			document.getElementsByClassName('content')[0] && (document.getElementsByClassName('content')[0].style.display = "block");
			document.getElementsByClassName('footer-copyright') && (document.getElementsByClassName('footer-copyright')[0].style.display = "block");
			document.getElementsByClassName('footer-bottom') && (document.getElementsByClassName('footer-bottom')[0].style.display = "block");
			document.getElementsByClassName('footer-nav-links') && (document.getElementsByClassName('footer-nav-links')[0].style.display = "block");
		}
		// scroll to where we were
		window.scrollTo(this._currentScroll[1], this._currentScroll[0]);
	}

	toggleLoggedInView() {
		const loginLink = this.el.querySelector(".login-link");
		const loggedinMenu = this.el.querySelector("#mobile-loggedin");

		this.loading();
		User.subscribe(User.actions.LOGIN_SUCCESSFUL, () => {
			this.loading();
			// is the user logged in
			if (User.status) {
				// set the link to include their name
				loggedinMenu.querySelector(
					"span.name"
				).innerText = `Hi ${User.firstName} - My Account`;
				// show the logged in menu
				loggedinMenu.style.display = "block";
				// hide the loggout menu
				loginLink.style.display = "none";
			} else {
				// hide the logged in menu
				loggedinMenu.style.display = "none";
				// show the loggout menu
				loginLink.style.display = "block";
			}
		});
	}

	getSubMenuHeight() {
		let headerHeight = document.querySelector("header#new-homepage-test-header").offsetHeight;
		let banner = document.querySelector(".big-strip");

		if(banner) {
			headerHeight += banner.offsetHeight;
		}

		return  window.innerHeight - headerHeight;
	}

	setHeightForSubmenu() {
		if(document.body.offsetWidth < 768 || document.body.offsetWidth > 1300) {
			return;
		}
		const height = this.getSubMenuHeight();
		if(!height) {
			return;
		}
		let submenuClass = this.el.find("div.submenu");
		if(submenuClass && submenuClass.length > 0) {
			submenuClass.forEach(elem => elem.style.maxHeight = height+'px');
		}
	}

	tabletMenu(e) {
		if (document.body.offsetWidth < 768) {
			return false;
		}
		this.setHeightForSubmenu();
		e.preventDefault();
		let topLevelLinks = this.el.find("li.nav-top-level");
		let clickedTopLevelLink = e.target.parentNode.parentNode;
		let submenuClass = this.el.find("div.submenu");
		// remove all the selected classes
		topLevelLinks.forEach(link => link.classList.remove("selected"));
		submenuClass.forEach(link => link.classList.remove("hidemenu"));
		// add new selected flag
		clickedTopLevelLink.classList.add("selected");
		// create and stick in an 'x' to close the menu
		if (this.x && this.x.parentNode) {
			this.x.parentNode.removeChild(this.x);
		}
		this.x = document.createElement("div");
		this.x.classList.add("tablet-close");
		this.x.innerHTML = `
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 32.281 32.281">
				${document.getElementById("menu-close").innerHTML}
			</svg>
		`;
		// add the 'x' in
		clickedTopLevelLink.appendChild(this.x);
		document.getElementsByTagName("html")[0].style.overflow = "hidden";
		// bind the x
		this.addEvent(this.x, "click", () => {
			// remove all the selected classes
			topLevelLinks.forEach(link => link.classList.remove("selected"));
			this.x.parentNode.removeChild(this.x);
			this.overlay.parentNode && this.overlay.parentNode.removeChild(this.overlay);
			submenuClass.forEach(link => link.classList.add("hidemenu"));
			this.overlay = null;
			document.getElementsByTagName("html")[0].style.overflow = "initial";
			this.mouseout(e);
		});
	}

	truncateMenu(){
		if(this.submenu !== null){
			this.submenu.style.margin = '0 auto';
		}
		let extraNav = [];
		let divExisting = document.querySelector('.showFullLists');
			if(divExisting === null){
				const newDiv = document.createElement("li");
				newDiv.classList.add('nav-top-level', 'showFullLists');
				newDiv.innerHTML = "<div class='nav-item'><div class='front-panel' data-toggle='tooltip' data-placement='right'><a class='showHideFullLists'>More ...</a></div></div><div class='moreList'><ul id='showFullLists'></ul></div>";
				document.querySelector('ul.truncateList')?.appendChild(newDiv);
			}else{
				divExisting.style.display = 'block';
			}
			this.truncateList?.forEach((elem, i) => {
				if(this._brandObj.id !== 'stw' ? i > 7 : i > 6) {
					extraNav.push(elem);
					elem.style.display = "none";
				}
			});
		this.extraNav = extraNav;

		this.addEvent(document.querySelector('.showHideFullLists'), 'click', (e) => {
			e.preventDefault();
			this.showHideFullLists();
		});
	}
	showHideFullLists () {
		var ul = document.getElementById("showFullLists");
		this.extraNav.forEach(elem => {
			ul.style.display = "block";
			elem.style.display = "block";
			elem.style.float = 'none';
			ul.appendChild(elem);
			if(this.submenu !== null){
				this.submenu.style.margin = '82px auto';
			}
		})
	}
	
}

export default MenuView;
