import Cart from '../../../models/cart/cart';
import Component from '../../component';
import Modal from '../../modal/modal';
import getBrand from 'dw-brand-identify';
import { clickEventDL, pageInteractionDl } from 'dw-global/apps/dataLayer';
import request from '../../../utilities/request';

/**
 * # Cart View
 * Create a new cart, this is designed for the new header
 */
class CartView extends Component {
	constructor() {
		super('#cart');
		// bind all the elements
		this._total = this.el.querySelector('.total');
		this._numberOfItems = this.el.querySelector('.number-of-items');
		this._cartPanel = this.el.querySelector('#cart-panel');
		this._list = this.el.querySelector('table');
		this._empty = this.el.querySelector('.empty');
		this._contents = this.el.querySelector('.contents');
		this._savings = this.el.querySelector('.savings');
		this.cartSvg = this.el.querySelector('#cart svg');
		this._brandObj = getBrand();
		this.nonEmptyCart= false;

		// bind the events
		Cart.subscribe(Cart.actions.UPDATED_START, () => {
			this.el.classList.add('loading');
		});
		Cart.subscribe(Cart.actions.UPDATED_SUCCESSFUL, this.render.bind(this));
		Cart.subscribe(Cart.actions.UPDATED_FAILURE, () => {
			this.el.classList.remove('loading');
			this.failure('We are having problems updating your cart at the moment.');
		});
		Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, (itemData) => {
			this.addToCartSuccess();
			if (typeof dataLayer !== 'undefined') {
				this.gaEventCartAdd(itemData);
			};
			document.dispatchEvent(new CustomEvent("event-algolia-converted", {
                bubbles: true,
                cancelable: false,
				detail: itemData
            }));
		});
		Cart.subscribe(Cart.actions.ADD_FAILURE, (e) => {
			// if the failure has a string message, show that
			if (typeof e === 'string') {
				this.failure(e);
			} else {
				/*
				@todo add brand identifier into this to get the differnt numbers
				*/
				this.failure(`Sorry, we are having problems adding your product, please try again or contact the call centre on ${this._brandObj.telephone}`);
			}
		});
		// bind all the buttons
		Array.from(document.querySelectorAll('.js-add-to-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.js-add-multi-items')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});

		Array.from(document.querySelectorAll('.js-quick-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});

		Array.from(document.querySelectorAll('.js-add-xmas-items')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.js-add-bundle-to-cart')).forEach((btn) => {
			this.addEvent(btn, 'click', this.addToCart.bind(this));
		});
		Array.from(document.querySelectorAll('.DLclick')).forEach((btn) => {
			this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
		});

		// list for a custom event
		document.body.addEventListener('add-to-cart', (e) => {
			this.addToCart({
				target: e.detail,
			})
		});

		document.addEventListener("add-cart-successful", (e) => {
			if(e && e.detail) {
				Cart.fetch().then(() => {
					Cart.dispatch(Cart.actions.ADD_SUCCESSFUL, e.detail);
				});
			}
		});

		// fetch the cart
		Cart.fetch();
	}

	getItem(itemcode) {
        return request.product.get({
            itemcode
        }).then((res) => res.json()).then((res) => {
            if (res.statusCode !== 0) {
                throw new Error(res.errorResponse.message);
            }
            return res.response;
        }).catch((error) => {
            console.log(error.message);
        });
    }

	async gaEventCartAdd(itemData) {
		const event = Cart._items.length > 0 ? 'addToCart' : 'addToEmptyCart';
		const ADLevent = Cart._items.length > 0 && this.nonEmptyCart === true ? 'addToCart' : 'InitiateCart';
		itemData.name = ADLevent === 'addToCart' ? 'add to cart' : 'add to empty cart';
		itemData.clickText = 'Add to Basket';
		const productdetails = await this.getItem(itemData.itemcode);
		clickEventDL(ADLevent, itemData, productdetails);
		this.nonEmptyCart = true;
        dataLayer.push({
            'event': event,
            'ecommerce': {
                'currencyCode': 'GBP',
                'add': {
                    'products': [{
                        'name': '',
                        'id': itemData.itemcode,
                        'price': itemData.price,
						'webHeadline': itemData?.webHeadline,
                        'brand': '',
                        'category': '',
                        'quantity': itemData.quantity
                    }]
                }
            }
        });
	}

	pageInteraction(e, element){
		pageInteractionDl(element.currentTarget);
	}
	/**
	 * ## addToCart
	 * Add an item to the cart, this is designed to work with the old school
	 * website.
	 *
	 * @param {Event} e The event object
	 */
	addToCart(e) {
		const btn = e.target;
		let btnText = e.target.innerText;
		const code = btn.getAttribute('data-itemcode');
		// stop the old minicart from doing anything. This works because the old
		// minicart binds all it's actions to the body element
		if (e.stopPropagation) {
			e.stopPropagation();
		}

		btn.classList.add('btn-loading');
		btn.innerText = 'Adding...';

		const finished = () => {
			btn.classList.remove('btn-loading');
			btn.innerText = btnText;
		}

		if (btn.classList.contains('js-add-multi-items') || btn.classList.contains('js-add-bundle-to-cart')) {
			let items = [];
			if (btn.getAttribute('data-items')) {
				// multiple items
				const dataItems = btn.getAttribute('data-items');
				items = dataItems.split(",").map(item => item.trim());
			} else {
				// bulk deals add
				items = Array.from(
					btn
					// well-orderform
					.parentNode
					// col-sm-3
					.parentNode
					// js-container
					.parentNode
					// find all the items
					.querySelectorAll('.js-bulk-item')
				).map((item) => item.getAttribute('data-item'));
			}

			let cartItems = items.map((item) => {
				return {
					'itemCode': item,
					'quantity': '1',
				};
			});

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_MULTI_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_MULTI_FAILURE, finished);

			// add the item to the cart, this will fire a render
			Cart.addMultipleItems(cartItems);

		}
		else {
		if (btn.classList.contains('js-add-xmas-items')) {
			// single item
			const code = btn.getAttribute('data-itemcode');
			const price = btn.getAttribute('data-salesprice');
			const quantity = btn.getAttribute('data-qty');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);

			// add the item to the cart, this will fire a render
			Cart.addItem(code, quantity, price);
		} else if (btn.classList.contains('js-quick-cart')) {	
			// single item
			const code = btn.getAttribute('data-itemcode');
			const price = btn.getAttribute('data-salesprice');
			const quantity = btn.getAttribute('data-qty');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);

			// add the item to the cart, this will fire a render
			Cart.addItem(code, quantity, price);
		} else {
			const submission = btn.getAttribute('data-submission');
			const checkedOption = btn.closest('.js-orderform').querySelector('input[type="radio"]:checked');
			const code = checkedOption.getAttribute('data-itemcode');
			const price = checkedOption.getAttribute('data-salesprice');
			const webHeadline = checkedOption.getAttribute('data-webheadline');
			const stockQty = parseInt(checkedOption.getAttribute('data-count'), 10);
			const stockSummary = checkedOption.getAttribute('data-summary');
			const errorContainer = document.getElementById('error-container');

			// remove the styling and text afterwards
			Cart.subscribe(Cart.actions.ADD_SUCCESSFUL, finished);
			Cart.subscribe(Cart.actions.ADD_FAILURE, finished);

			let quantity, cartQuantity = 0;
			if (submission === 'tiered') {
				quantity = checkedOption.getAttribute('data-qty');
			} else {
				const qty = parseInt(btn.closest('.js-qty').querySelector('input[id=qty]').value, 10);
				if (qty) {
					quantity = qty;
				} else {
					quantity = 1;
				}
			}
			
			Cart.items.forEach((item) => {
				if(item._item.sku.itemCode === code) {
					cartQuantity = parseInt(item._item.quantity, 10);
				}
			})
			if (stockSummary === "low_stock" && (quantity > stockQty || (quantity + cartQuantity) > stockQty)) {
				errorContainer.style.display = "block";
				btn.classList.remove('btn-loading');
				btn.innerText = btnText;
			} else {
				if (errorContainer !== null) {
					errorContainer.style.display = "none";
				}
				// add the item to the cart, this will fire a render
				Cart.addItem(code, parseInt(quantity, 10), price, webHeadline);
			}
		}
	}
	}

	/**
	 * ## addToCartSuccess
	 * When we add an item to the cart we would like an animation to singal
	 * that something has happened.
	 */
	addToCartSuccess() {
		// show a confirmation popuo
		let popup = document.createElement('div');
		popup.classList.add('cart-confirmation-popup');
		popup.innerHTML = `
			<div class="checkmark">
				<div class="checkmark_stem"></div>
				<div class="checkmark_kick"></div>
			</div>
			Added to your basket
		`;
		// add the popup to the cart
		this.el.appendChild(popup);
		// fade out the popup in 3 seconds
		setTimeout(function () {
			popup.classList.add('fadeout');
			setTimeout(function () {
				popup.parentNode.removeChild(popup);
			}, 500);
		}, 2500);


		// make sure we remove any old classes
		this._numberOfItems.classList.remove('added-item');
		// add the new one so we can get an animation
		this._numberOfItems.classList.add('added-item');
		// stop the animation
		this._animate = false;
	}

	/**
	 * ## failure
	 * When something goes wrong we need to inform the user via a modal popup
	 *
	 * @param  {String} msg String, or HTML msg to display in the popup
	 */
	failure(msg) {
		// create the modal
		const modal = new Modal({
			title: 'Shopping Basket',
			body: msg,
			containsError: true,
			showFooter: false,
		});
		// open the modal
		modal.open();
	}

	/**
	 * Render the cart
	 */
	render() {
		let iframeCart = '';
		if (document.querySelector('div[id^=livecommerce-surf]>iframe')) {
			iframeCart = document.querySelector('div[id^=livecommerce-surf]>iframe').contentWindow.document.querySelector('#cart');

			this.bamlsTotal = iframeCart.querySelector('.total');
			this.bamlsNumberOfItems = iframeCart.querySelector('.number-of-items');
			this.bamlsSavings = iframeCart.querySelector('.savings');
			this.bamlsList = iframeCart.querySelector('table');
			this.bamlsEmpty = iframeCart.querySelector('.empty');
			this.bamlsContents = iframeCart.querySelector('.contents');
		} 
		// remove the loading class (if it exists)
		this.el.classList.remove('loading');
		// update the total
		this._total.innerHTML = this._brandObj.id == 'hsb' ? Cart.total + 'pts'  : '&pound;' + Cart.total.toFixed(2);
		this._numberOfItems.innerText = Cart.numBottles;
		if (Cart.savings > 1) {
			this._savings.innerHTML = 'Saving &pound;' + Cart.savings.toFixed(2);
		}
		// empty the list UXE-3491
		this._list.innerHTML = '';
		if (iframeCart !== '') {
			this.bamlsList.innerHTML = '';
		}
		// build up the list of items
		if(this._total.innerText.length > 7){
            this.cartSvg.style.marginLeft = '0px';
            this._numberOfItems.style.left = '20px';
        }
		Cart.items.forEach((item) => {
			// it's a table
			const li = document.createElement('tr');

			var cans = ["4119820", "4121320", "4124220", "4120520", "4121420", "4120820", "4113719", "4120016", "4119919", "2674016","3876220"];
			var boxes = ["4094520", "4113920", "4098721", "4007220", "3607117"];
			var cases = ["4107521"];
			let productText = '';
			const itemCode = item._item.sku.itemCode;

			if (cans.indexOf(item._item.sku.itemCode) !== -1) {
				productText = 'Cans';
			} else if (boxes.indexOf(item._item.sku.itemCode) !== -1) {
				productText = 'Boxes';
			} else if (cases.indexOf(item._item.sku.itemCode) !== -1) {
				productText = 'Cases';
			} else {
				productText = 'Bottles';
			}

			let itemNaming = '';
			if (item.isPresell && itemCode.charAt(0) === 'A') {
				itemNaming = 'Items';
			} else {
				itemNaming = 'Bottles';
			}

			li.innerHTML = `
				<td><div class="cart-thumbnail">
					${item._item.product.mixed ?
						`<img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_case.png';" src="${item.thumbnail}" alt="default case" title="${item.name} ${item.vintage || ''}" alt="${item.name}${item.vintage || ''}" />` 
					: 	`<img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${item.thumbnail}" alt="default bottle" title="${item.name} ${item.vintage || ''}" alt="${item.name}${item.vintage || ''}" />`
					}
				</a></td>
				<td>
					${item.isFreeGift ? item.name : item._item.product.productType !== 'service' ? 
						`<a href="/product/${item.itemcode}">${item.name} ${item.vintage || ''}</a>` : 
						`${item.name} ${item.vintage || ''}`}
					${item.isPresell ? `
						<div class="presell-item">
							<div class="data-tooltip">
								Reservation Item
                      			<span>The price shown is the total for this wine. You will only be charged the reservation payment today.</span>
							</div>
						</div>
					`: ''}
					<div class="row no-gutters">
						<div class="col-sm-6">${itemNaming}: ${item.bottleCount}</div>
						${this._brandObj.id != 'hsb' ? `
						<div class="col-sm-6">Price: ${item.isFreeGift ? 'FREE' : '&pound;' + item.total.toFixed(2)}</div>`:
						`<div class="col-sm-6">Price: ${item.isFreeGift ? 'FREE' : item._item.itemPointsInfo.amount + 'pts'}</div>`
						}
					</div>
				</td>
			`;
			this._list.appendChild(li);
			if (iframeCart !== '') {
				this.bamlsList.appendChild(li);
			}
		});

		if (Cart.items.length > 0) {
			this._contents.style.display = 'block';
			this._empty.style.display = 'none';
		} else {
			this._contents.style.display = 'none';
			this._empty.style.display = 'block';
		}
		if(this._brandObj.id == 'hsb'){
			this._savings.style.display = 'none !important';
		}
		if (iframeCart !== '') {
			this.bamlsTotal.innerHTML = Cart.total.toFixed(2);
			this.bamlsNumberOfItems.innerHTML = Cart.numBottles;
			this.bamlsSavings.innerHTML='Saving &pound;' + Cart.savings.toFixed(2);

			if (Cart.items.length > 0) {
				this.bamlsContents.style.display = 'block';
				this.bamlsEmpty.style.display = 'none';
			} else {
				this.bamlsContents.style.display = 'none';
				this.bamlsEmpty.style.display = 'block';
			}
		}

	}
}

export default CartView;
