import Component from '../component';

class FooterView extends Component {

	constructor() {
		super('.footer-links');

		this.el.find('.nav-item').forEach((element, i) => {
			element.on('click', (e) => {
				e.preventDefault();
				element.closest('.nav-item').find('i.arrow').classList.toggle('up');
				element.closest('li.group').find('ul.submenu').classList.toggle('show');
			});
		});
	}

}

export default FooterView;