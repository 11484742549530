import Component from '../component';
import Template from '../../utilities/template';
import User from '../../models/user/user';

class RecentlyViewed extends Component {

	constructor() {
		super('section.recently-viewed');
		// bind the events
		User.subscribe(User.actions.RECENTLY_VIEWED_START, () => {
			this.el.classList.add('loading');
		});
		User.subscribe(User.actions.RECENTLY_VIEWED_SUCCESSFUL, this.render.bind(this));
		User.subscribe(User.actions.RECENTLY_VIEWED_FAILURE, this.failure.bind(this));
		// fetch the cart
		User.recentlybrowsed();

		this._windows = Array.from(this.el.querySelectorAll('.window'));
		this._tabs = Array.from(this.el.querySelectorAll('.tabs .tab'));
		this._left = this.el.querySelector('.left');
		this._right = this.el.querySelector('.right');
		this._touchStartX, this._touchMoveX, this._moveX, this._index = 0;
		this._sliderWidth = this.el.offsetWidth;
		this._endOfCarousel = this.endOfCarousel.bind(this);

		this.addEvent(this._left, 'click', this.slide.bind(this, +1));
		this.addEvent(this._right, 'click', this.slide.bind(this, -1));

		this._windows.forEach((win) => {
			this.addEvent(win, 'touchstart', this.touchSlideStart.bind(this), { passive: true });
			this.addEvent(win, 'touchmove', this.touchSlideMove.bind(this), { passive: true });
			this.addEvent(win, 'touchend', this.touchSlideEnd.bind(this), { passive: true });
		});
	}

	hideArrows(widthOfContainer, itemWidth, newOffset) {
		//remove half shown item length from total length
		const balance = (widthOfContainer - 40) % itemWidth;
		const winesPerSlide = parseInt((widthOfContainer - balance) / itemWidth);
		const end = this._endOfCarousel(itemWidth, winesPerSlide, newOffset);

		// Disable the right arrow if user reaches
		// the end of the carousel
		if (end && newOffset !== 0) {
			this.el.querySelector(".right").style.display = 'none';
		} else {
			this.el.querySelector(".right").style.display = 'block';
		}

		// Disable the left arrow if user reaches
		// the start of the carousel
		if (newOffset === 0) {
			this.el.querySelector(".left").style.display = 'none';
		} else {
			this.el.querySelector(".left").style.display = 'block';
		}
	}

	endOfCarousel(widthOfElement, winesPerSlide, newOffset) {
		const scrollCount = Math.abs(newOffset) / widthOfElement;
		const itemsVisible = scrollCount + winesPerSlide;
		if (itemsVisible >= User.recentlyViewed.length) {
			return true;
		}
	}

	slide(direction) {
		// whats the currently selected window
		const selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		const ul = selected.querySelector('ul');
		const offset = parseInt(ul.style.transform.replace('translateX(', '').replace('px)', ''), 10) || 0;
		const widthOfElement = selected.querySelector('li').offsetWidth + 20;

		if (offset === 0 && direction > 0) {
			return;
		}

		const transVal = direction < 0 ? offset - widthOfElement : offset + widthOfElement;
		ul.style.transform = `translateX(${transVal}px)`;

		this.hideArrows(selected.offsetWidth, widthOfElement, transVal);
	}

	touchSlideStart(e) {
		this._selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		this._ul = this._selected.querySelector('ul');
		this._elementWidth = this._selected.querySelector('li').offsetWidth + 20;
		this._ul.classList.remove('animate');
		this._touchStartX = e.touches[0].pageX;
	}

	touchSlideMove(e) {
		this._touchMoveX = e.touches[0].pageX;
		this._moveX = (this._index * this._elementWidth) + (this._touchStartX - this._touchMoveX);
		this._ul.style.transform = `translateX(-${this._moveX}px)`;
	}

	touchSlideEnd() {
		const absMove = Math.abs(this._index * this._elementWidth - this._moveX);
		if (absMove > this._elementWidth / 2) {
			if (this._moveX > (this._index * this._elementWidth) && this._index < this._ul.querySelectorAll('li').length - 1) {
				this._index++;
			} else if (this._moveX < (this._index * this._elementWidth) && this._index > 0) {
				this._index--;
			}
		}

		const selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		const newOffset = this._index * this._elementWidth;
		this._ul.classList.add('animate');
		this._ul.style.transform = `translateX(-${newOffset}px)`;
		this.hideArrows(selected.offsetWidth, this._ul.querySelector('li').offsetWidth + 20 , newOffset);
	}
	//Hidden right arrow if wines list less than initial set of slide
	hideArrowAtEnd(){
		const selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		const widthOfContainer = selected.offsetWidth;
		const widthOfElement = selected.querySelector('li').offsetWidth + 20;
		const winesPerSlide = (widthOfContainer / widthOfElement);
		const wines = User.recentlyViewed.length;

		if(wines <= winesPerSlide){
			this.el.querySelector(".right").style.display = 'none';
			this.el.querySelector(".left").style.display = 'none';
		}
		this.el.querySelector(".left").style.display = 'none';
	}

	/**
	 * [failure description]
	 * @param  {[type]} e [description]
	 * @return {[type]}   [description]
	 */
	failure(e) {
		// Remove loading spinner
		this.el.classList.remove('loading');
		const wines = this.el.querySelector(".template");
		let error = document.createElement('div');
		error.innerHTML = `
				No Recently Browsed Wines
			`;

		if (wines) {
			wines.appendChild(error);
		}
	}
	/**
	 * [render description]
	 * @return {[type]} [description]
	 */
	render() {
		// Remove loading spinner
		// and hide class if applied
		this.el.classList.remove('loading', 'hide');

		const wines = User.recentlyViewed;

		if (wines && wines.length > 0) {
			return Template.get('/assets/components/homepage/tmpl/recently-viewed/recently-viewed.handlebars', {
				wines
			}).then((template) => {
				this.el.querySelector('.template').innerHTML = template;
                this.hideArrowAtEnd();
			}).then(() => {
				const noteButton = Array.from(document.querySelectorAll('ul.wines .js-open-tasting-note'));

				noteButton.forEach((btn) => {
					btn.addEventListener("click", function (e) {
						// Create a new event, allow bubbling, and provide any data you want to pass to the "detail" property
						const event = new CustomEvent('open-tasting-note', {
							bubbles: true,
							detail: btn.getAttribute('data-itemcode')
						});
						// Dispatch the event.
						document.dispatchEvent(event);
					});
				});

			}).catch((e) => {
				console.log('TEMPLATE_LOAD_FAILURE:', e);
			});
		}




		// We have no wines so hide section UI
		return this.el.classList.add('hide');
	}
}

export default RecentlyViewed;







