import { $ } from "../../utilities/min";
import Component from "../component";
import Modal from "../modal/modal";
import User from "../../models/user/user";
import request from '../../utilities/request';
import event from '../../utilities/event';
import getBrand from 'dw-brand-identify';

class TastingNote extends Component {
	constructor() {
		super();

		this._item = {};
		this._colourId = '';
		this._brandObj = getBrand();
		this._domain = `https://${this._brandObj.web}`;
		document.body.addEventListener('click', this.handleClickOutside, true);
	}

	handleClickOutside(e) {
		this.modal = new Modal();
		if(e.target.id === "tasting-note-overlay" || e.target.id === "case-contents"){
			this.modal.closeOut();
		}
		document.body.removeEventListener('click', this.handleClickOutside, true);
	}

	getItem(itemcode, page) {
		const self = this;
		return request.product.get({ itemcode })
			.then(res => res.json())
			.then((json) => {
				// if we don't have a valid respone throw an error
				if (!json.response && json.statusCode !== 0) {
					throw new Error(json.statusMessage);
				}
				self._item = json.response;
				self._colourId = self._item.colourId ? self._item.colourId.toLowerCase() : '';
	 
				// Is the item an case of wine?
				if (self._item.mixed) {
					return self.getCase(self._item.itemCode);
				}
	 
				if (page === 'print') {
					self.loadItemToDiv();
				} else {
					self.loadItemToModal(self._colourId);
				}

			}).catch((e) => {
				event('API Failure', 'Get Item for Tasting Note', e);
				console.log('API Failure', e);
			});
	}

	errorTemplate() {
		return (`
			<div>Unfortunatley we are unable to load the details at this time.</div>`
		);
	}

	getCase(itemcode) {
		/**
		 * This will run an await function to get the
		 * details of a case, if it fails it will be caught
		 * in the catch statment in getCaseDetailsAsync
		 * Requires itemcode to be passed in (itemcode)
		 */
		async function getCaseDetailsAsync(itemcode) {
			let response = await request.product.case({
				itemcode,
			});
			let data = await response.json();
			return data.response;
		}


		getCaseDetailsAsync(itemcode)
			.then(data => {
				this.caseContentsTemplate(data);
			})
			.catch((e) => {
				this.modal = new Modal({
					title: 'Case details',
					body: this.errorTemplate(e),
					showFooter: false,
				});
				// Open the modal
				this.modal.open();
				// we've errored
				console.log('Modal Loading Error', e);
			});
	}

	/**
	 * This function will load a modal to display
	 * the contents of a case based on the API ran from
	 * this.getCase();
	 */
	caseContentsTemplate(data) {
		let template = [];
		let winesincase = [];
		let rating = [];

		winesincase = data.contentProducts.map(wine => {
			if (wine.referencedProduct.productType === 'wine') {


				if (wine.referencedProduct.ratingDetails && wine.referencedProduct.ratingDetails.productRating) {
					rating = (
						`<span class="stars stars-small rating-${wine.referencedProduct.ratingDetails.productRating.roundAvgRating}Star"></span>`
					)
				}

				return (
					`
                <div class="item">
                    <div style="display: flex">
                        <div class="image">
                        <img onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${wine.referencedProduct.smallImage}" /></div>
                        <div class="detail">
                            <h4>${wine.referencedProduct.name} ${wine.referencedProduct.vintage}</h4>
                            ${rating}
                            ${wine.referencedProduct.productType === 'wine' ? (`<p>x ${wine.quantity} ${wine.quantity > 1 ? 'bottles' : 'bottle' }</p>`) :
                            (`<p>x ${wine.quantity}</p>`) }
                            <ul class="detail-table">
                                <li>
                                    <div class="icons">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
                                    <g fill="#333" fill-rule="evenodd">
                                        <path d="M12.356 10.355a.959.959 0 0 1-.952-.963c0-.531.427-.963.952-.963.525 0 .953.432.953.963a.959.959 0 0 1-.953.963zm-.938-5.526c-.775.55-1.818.55-2.594 0l-.05-.036c.822-.609 1.844-.613 2.669-.01l.02.014-.045.032zm1.866 2.52a.515.515 0 0 1-.297-.468V4.788h-.002a1.06 1.06 0 0 0-.431-.841l-.338-.247c-1.285-.94-2.942-.933-4.222.016l-.314.233a1.06 1.06 0 0 0 .018 1.712l.368.262a3.549 3.549 0 0 0 3.22.457c.21-.073.43.081.43.306v.2c0 .2-.115.381-.294.465a2.255 2.255 0 0 0-1.283 2.217c.084 1.098.96 1.984 2.046 2.066 1.303.1 2.395-.945 2.395-2.242 0-.906-.532-1.687-1.296-2.044zM9.743 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M12.373 16.848a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207A2.21 2.21 0 0 1 12.373 20M15.097 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M17.445 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207"></path>
                                        <path d="M7.207 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152A2.21 2.21 0 0 1 5 9.34a2.21 2.21 0 0 1 2.207-2.207A2.21 2.21 0 0 1 9.414 9.34a2.21 2.21 0 0 1-2.207 2.207"></path>
                                    </g>
                                    </svg>
                                </div>
                                <div class="text">                                
                                ${wine.referencedProduct.grapeName}
                                </div>
                                </li>
                                <li>
                                <div class="icons">    
                                <img src="/images/uk/en/common/icons/flags/${wine.referencedProduct.countryName}.png" class="js-default-image-handler" alt="${wine.referencedProduct.countryName}" title="${wine.referencedProduct.countryName}"></div>
                                <div class="text">
                                ${wine.referencedProduct.countryName}</div>
                                </li>
                            </ul>
                            <p>${wine.referencedProduct.webHeadline}</p>
                        </div>
                    </div>
                </div>
                `
				)
			}
		}).join(' ');

		template = (`
            <div>
                <div class="selection-container">
                    <div id="case-webheadline">${data.parentProduct.webHeadline ? data.parentProduct.webHeadline : ''}</div>
                </div>
                <div id="modal-wines-in-case" class="case-container">            
                    ${winesincase}
                </div> 
            </div>            
        `);

		this.modal = new Modal({
			title: data.parentProduct.name,
			body: template,
			containsForm: true,
			showFooter: false,
			largeModal: true,
			id: 'case-contents',
		});

		// Open the modal
		this.modal.open();
	}

	values() {
		return {
			name: this._item.name ? this._item.name : '',
			vintage: this._item.vintage ? this._item.vintage : '',
			description: this._item.description ? this._item.description : '',
			longDescription: this._item.longDescription ? this._item.longDescription : '',
			image: this._item.thumbnailImage ? this._item.thumbnailImage : '',
			appellationName: this._item.appellationName ? this._item.appellationName : '',
		}
	}

	tastingNotes() {
		if (this._item.tastingNotes) {
			return (`
				${this._item.tastingNotes.shortTastingNotesTaste ?
				`<div class="sub-heading">Tasting Notes</div>
					<p>${this._item.tastingNotes.shortTastingNotesTaste}</p>`
				: ''}
				${this._item.tastingNotes.shortTastingNotesAroma ? 
					`<div class="sub-heading">Aroma</div>
					<p>${this._item.tastingNotes.shortTastingNotesAroma}</p>`
				: ''}
				${this._item?.allergens?.length > 0 ? 
					`<div class="sub-heading">Allergy information</div>
					<p>Contains <span class="sub-heading">${this._item?.allergens?.map((ingredient) => `${ingredient?.charAt(0)}${ingredient?.slice(1)?.toLowerCase()}`)?.join(', ')}</span></p>`
				: ''}
			`)
		}
		return '';
	}

	moreInformation() {
		return (`
			<div class="sub-heading">More information</div>
			<div class="notes-container">
				<div class="col-lg-6 col-sm-6 col-md-6 no-pad-left info-container">
					<ul class="detail-table">
					${this._item.styleName ? 
					`<li>
						<span class="pull-left icons">
							<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
								<path fill="#333" fill-rule="evenodd" d="M8.153 8.52v-.005l.078-.925a.471.471 0 0 1 .41-.429c.137-.017.274-.026.407-.026.49 0 .972.13 1.519.409l.004.002.147.073c.17.09.337.183.5.274a29.793 29.793 0 0 0 .412.225l.573.284a4.622 4.622 0 0 0 1.946.434c.284 0 .567-.023.845-.066a.475.475 0 0 1 .547.521 3.766 3.766 0 0 1-1.44 2.591 3.692 3.692 0 0 1-2.25.76 3.693 3.693 0 0 1-2.252-.76c-1.013-.77-1.568-2.058-1.446-3.362m7.06-3.95l.215 2.53a.472.472 0 0 1-.367.501 4.287 4.287 0 0 1-.913.098c-.497 0-.946-.1-1.453-.322l-.553-.273a30.708 30.708 0 0 1-.373-.205 26.074 26.074 0 0 0-.538-.293l-.15-.076C10.368 6.168 9.723 6 9.048 6c-.05 0-.099 0-.149.003a.473.473 0 0 1-.49-.513l.078-.92a.473.473 0 0 1 .47-.433h5.785c.246 0 .451.189.472.434m-.426 8.217c1.324-1.005 2.048-2.68 1.891-4.37l-.414-4.897A.568.568 0 0 0 15.7 3H8.001a.568.568 0 0 0-.566.52L7.02 8.417c-.157 1.69.568 3.365 1.89 4.37.527.4 1.114.683 1.737.841.37.095.634.422.634.805v3.574c0 .457-.37.827-.828.827H9.171a.58.58 0 0 0-.587.558.568.568 0 0 0 .568.579h5.315a.58.58 0 0 0 .587-.558.568.568 0 0 0-.568-.579h-1.24a.828.828 0 0 1-.828-.827v-3.574c0-.383.264-.71.634-.805a4.838 4.838 0 0 0 1.736-.841"/>
							</svg>
						</span>
						<div class="detail-text">${this._item.styleName}</div>
						</li>`
					: ''}
					${this._item.grapeName ?
						`<li>
						<span class="pull-left icons">
							<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
								<g fill="#333" fill-rule="evenodd">
									<path d="M12.356 10.355a.959.959 0 0 1-.952-.963c0-.531.427-.963.952-.963.525 0 .953.432.953.963a.959.959 0 0 1-.953.963zm-.938-5.526c-.775.55-1.818.55-2.594 0l-.05-.036c.822-.609 1.844-.613 2.669-.01l.02.014-.045.032zm1.866 2.52a.515.515 0 0 1-.297-.468V4.788h-.002a1.06 1.06 0 0 0-.431-.841l-.338-.247c-1.285-.94-2.942-.933-4.222.016l-.314.233a1.06 1.06 0 0 0 .018 1.712l.368.262a3.549 3.549 0 0 0 3.22.457c.21-.073.43.081.43.306v.2c0 .2-.115.381-.294.465a2.255 2.255 0 0 0-1.283 2.217c.084 1.098.96 1.984 2.046 2.066 1.303.1 2.395-.945 2.395-2.242 0-.906-.532-1.687-1.296-2.044zM9.743 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M12.373 16.848a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207A2.21 2.21 0 0 1 12.373 20M15.097 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M17.445 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207"/>
									<path d="M7.207 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152A2.21 2.21 0 0 1 5 9.34a2.21 2.21 0 0 1 2.207-2.207A2.21 2.21 0 0 1 9.414 9.34a2.21 2.21 0 0 1-2.207 2.207"/>
								</g>
							</svg>
						</span>
						<div class="detail-text">
						<a class="emp" href="/wines?Ntt=${this._item.grapeName}">${this._item.grapeName}</a></div>
						</li>`
					: ''}
					${this._item.countryName ?
						`<li>
						
						<span class="pull-left icons flags">
							<img src="/images/uk/en/common/icons/flags/${this._item.countryName}.svg" />
							</span>
						<div class="detail-text">
							<a class="emp" href="/wines?Ntt=${this._item.countryName}">${this._item.countryName}</a></div>
						</li>`
					: ''}
					${this._item.tastingNotes.shortTastingNotesColor ?
						`<li>
						<span class="pull-left icons">
							<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
								<path fill="#333" fill-rule="evenodd" d="M12.375 3c-2.454 2.076-4.773 4.81-5.867 7.855-.677 1.883-.744 3.928.209 5.734A6.446 6.446 0 0 0 12.375 20a6.446 6.446 0 0 0 5.658-3.41c.953-1.807.886-3.852.21-5.735C17.148 7.81 14.83 5.075 12.374 3zm3.774 12.6a4.31 4.31 0 0 1-3.774 2.275 4.29 4.29 0 0 1-2.53-.841c.22.029.443.044.667.044a5.386 5.386 0 0 0 4.716-2.843c.746-1.415.682-2.886.397-4.056.242.467.448.933.613 1.393.376 1.047.666 2.596-.09 4.028z"/>
							</svg>
						</span>
						<div class="detail-text">${this._item.tastingNotes.shortTastingNotesColor}</div>
						</li>`
					: ''}
					</ul>
				</div>
				<div class="col-lg-6 col-sm-6 col-md-6 no-pad info-container">
					<ul class="detail-table">
					${this._item.alcoholPercent ?
						`<li>
							<span class="pull-left icons">
								<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
								<path fill="#333" fill-rule="evenodd" d="M18.864 15.367l1.13-9.81c.025-.145-.026-.266-.126-.387a.542.542 0 0 0-.376-.17H5.508a.458.458 0 0 0-.376.17.437.437 0 0 0-.126.387l1.13 9.81.377 3.197a.507.507 0 0 0 .502.436H18.01a.507.507 0 0 0 .502-.436l.352-3.197zm-.929-.533H7.09l-1.03-8.84h12.88l-1.005 8.84z"/>
								</svg>
							</span>
							<div class="detail-text">${this._item.alcoholPercent}% ABV</div>
						</li>`
					: ''}
					${this._item.alcoholUnits ?
						`<li>
							<span class="pull-left icons">
								<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
									<path fill="#333" fill-rule="evenodd" d="M20.477 9.163l-16.988.034v5.606h17.022l-.034-5.64zm-16.21.81h.877v1.014h.81V9.974h.811V12h.81V9.974h.811v1.013h.81V9.974h.811v1.013h.811V9.974h.81V12h.743V9.974h.811v1.013h.81V9.974h.811v1.013h.81V9.974h.811V12h.81V9.974h.811v1.013h.743V9.974h.878v3.985L4.3 13.993l-.034-4.02z"/>
								</svg>
							</span>
							<div class="detail-text">${this._item.alcoholUnits} Units</div>
						</li>`
					: ''}
					${this._item.bottleSize ?
						`<li>
							<span class="pull-left icons">
								<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="100%" viewBox="0 0 24 24">
									<defs>
										<path id="a" d="M9 4h5.746v16.957H9z"/>
									</defs>
									<g fill="none" fill-rule="evenodd">
										<mask id="b" fill="#fff">
											<use xlink:href="#a"/>
										</mask>
										<path fill="#333" d="M10.101 19.548c.207.126.834.308 1.772.308.937 0 1.564-.183 1.771-.308 0-.729-.008-6.844-.016-7.864-.007-.893-.353-1.344-.724-1.697-.583-.555-.62-1.332-.623-1.419V6.133c0-.166.04-.328.12-.485v-.263c-.04-.079-.07-.16-.09-.241a2.054 2.054 0 0 0-.438-.043c-.19 0-.339.02-.438.043a1.12 1.12 0 0 1-.091.241v.263c.08.157.12.32.12.484V8.57c-.002.086-.04.864-.622 1.418-.372.354-.717.805-.724 1.697-.008 1.02-.016 7.13-.017 7.864zm1.772 1.41c-.675 0-1.317-.086-1.807-.24C9.185 20.442 9 20 9 19.678v-.06c0-.068.008-6.859.017-7.942.01-1.314.575-2.02 1.066-2.487.243-.23.278-.61.28-.647V6.149a.69.69 0 0 1-.12-.392v-.48a.69.69 0 0 1 .12-.392v-.034c0-.176.073-.347.205-.479.335-.336 1.024-.372 1.305-.372.944 0 1.508.318 1.508.851v.033a.69.69 0 0 1 .121.393v.48a.69.69 0 0 1-.12.392v2.39c.003.05.038.42.28.65.491.467 1.056 1.171 1.067 2.486.008 1.083.016 7.874.017 7.943v.06c0 .322-.186.764-1.067 1.04-.49.154-1.132.24-1.806.24z" mask="url(#b)"/>
									</g>
								</svg>
							</span>
							<div class="detail-text">${this._item.bottleSize}ml</div>
						</li>`
					: ''}
					${this._item.drinkByDateAsString ?
						`<li>
							<span class="pull-left icons">
								<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
									<path fill="#333" fill-rule="nonzero" stroke="#333" stroke-width=".3" d="M17.188 4h-.938v-.5c0-.276-.21-.5-.469-.5s-.469.224-.469.5V4H9.689v-.5c0-.276-.21-.5-.47-.5-.258 0-.468.224-.468.5V4h-.938C6.26 4 5 5.343 5 7v10c0 1.657 1.26 3 2.813 3h9.375C18.74 20 20 18.657 20 17V7c0-1.657-1.26-3-2.813-3zm1.875 13c-.002 1.104-.84 1.999-1.875 2H7.813c-1.036-.001-1.874-.896-1.875-2V9h13.125v8zm0-9H5.938V7c0-1.104.84-1.999 1.875-2h.937v.5c0 .276.21.5.469.5s.469-.224.469-.5V5h5.624v.5c0 .276.21.5.47.5.258 0 .468-.224.468-.5V5h.938c1.035.001 1.873.896 1.875 2v1z"/>
								</svg>
							</span>
							<div class="detail-text">${this._item.drinkByDateAsString}</div>
						</li>`
					: ''}
					</ul>
				</div>
			</div>
		`)
	}

	getFavourite(itemCode) {
		return request.user.favourites.list().then(res => res.json()).then((json) => {
			/**
			 * If we don't have a valid respone throw an error
			 */
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}

			const findFavourite = json.response.userItems.find(favourite => favourite.product.itemCode === itemCode);

			const btn_add = document.querySelectorAll('button.js-add-favourite');
			const btn_remove = document.querySelectorAll('button.js-remove-favourite');
			if (findFavourite) {
				// Already a favourite
				for (var i = 0; i < btn_add.length; i++) {
					btn_add[i].style.display = "none";
				}
				for (var i = 0; i < btn_remove.length; i++) {
					btn_remove[i].style.display = "block";
				}
			} else {
				for (var i = 0; i < btn_remove.length; i++) {
					btn_remove[i].style.display = "none";
				}
				for (var i = 0; i < btn_add.length; i++) {
					btn_add[i].style.display = "block";
				}
			}
		}).catch((e) => {
			event('API Failure', 'Get Favourites', e);
		});
	}

	addFavourite(itemCode) {
		return request.user.favourites.add({
			itemCode
		}).then(res => res.json()).then((json) => {
			// if we don't have a valid respone throw an error
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}
			this.getFavourite(itemCode);
		}).catch((e) => {
			event('API Failure', 'Add Favourite', e);
		});
	}

	removeFavourite(itemCode) {
		return request.user.favourites.delete({
			itemCode
		}).then(res => res.json()).then((json) => {
			// if we don't have a valid respone throw an error
			if (!json.response && json.statusCode !== 0) {
				throw json.statusMessage;
			}
			this.getFavourite(itemCode);

		}).catch((e) => {
			event('API Failure', 'Remove Favourite', e);
		});
	}

	getStars(avgRating) {
		// total number of stars
		const starTotal = 5;

		const ratings = {
			rating_a: avgRating,
		};

		for (const rating in ratings) {
			const starPercentage = (ratings[rating] / starTotal) * 100;
			const starPercentageRounded = `${(Math.round(starPercentage / 10) * 10 + 2)}%`;
			document.querySelector(`.${rating} .stars-inner`).style.width = starPercentageRounded;
		}
	}

	mobileTaskBar() {
		return (
			`<div class="text-center pad-10-top hidden-sm hidden-md hidden-lg cta-btn-bottom">
					<ul class="cta-panel-mobile">
						<li class="favourite-container">
							<button class="js-remove-favourite" data-itemcode="${this._item.itemCode}" style="display: none"></button>
							<button class="js-add-favourite" data-itemcode="${this._item.itemCode}" style="display: none"></button>
						</li>
						<li class="share-button">
							<a href="#" class="js-view-share-link bottom"><img title="Share" alt="Share"src="/images/uk/en/common/icons/share.svg"/></a>
						</li>
						<li class="buythiswine">
							<a href="#" class="js-buy-wine" data-itemcode="${this._item.itemCode}"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 95.8 73.1"><path d="M80 64.4c-0.9 0-1.7-0.6-1.9-1.5 -0.4-1.6-2.9-1.6-2.9-1.5h-34c-4.2 0.2-6.2-2.3-6.6-3.8L23.3 14.5c0-0.1 0-0.2-0.1-0.3 -0.9-7.7-3.9-9.4-6.5-10 -2.4-0.5-8.8-0.2-11 0 -0.4 0-1.4 1.1-2 2C3.2 7.2 1.9 7.6 1 7 0 6.5-0.3 5.2 0.3 4.3c0.4-0.6 2.3-3.7 5-4 1.4-0.1 8.9-0.7 12.3 0 5.6 1.3 8.7 5.6 9.6 13.3l11.2 42.7c0.1 0.2 0.7 1.1 2.7 1l34.1 0c2.1 0 5.9 0.9 6.8 4.6 0.3 1.1-0.4 2.2-1.5 2.4C80.3 64.4 80.2 64.4 80 64.4z"/><path d="M43.6 73.1c-3.9 0-7-3.1-7-7 0-3.9 3.1-7 7-7s7 3.1 7 7C50.6 70 47.5 73.1 43.6 73.1zM43.6 63.1c-1.7 0-3 1.4-3 3 0 1.7 1.4 3 3 3s3-1.4 3-3C46.6 64.5 45.3 63.1 43.6 63.1z"/><path d="M76.6 73.1c-3.9 0-7-3.1-7-7 0-3.9 3.1-7 7-7 3.9 0 7 3.1 7 7C83.6 70 80.5 73.1 76.6 73.1zM76.6 63.1c-1.7 0-3 1.4-3 3 0 1.7 1.4 3 3 3 1.7 0 3-1.4 3-3C79.6 64.5 78.3 63.1 76.6 63.1z"/><path d="M65.6 51.4c-6.6 0-16.4 0-31.1-0.1 -1.1 0-2-0.9-2-2 0-1.1 0.9-2 2-2 16.7 0.1 42 0.1 44.1 0 1.1-0.2 1.5-0.6 1.6-0.7l11.5-30.1c0.1-0.3 0.1-0.6 0.1-0.7 -0.1 0-0.2 0-0.4 0H25.7c-1.1 0-2-0.9-2-2s0.9-2 2-2h65.6c1-0.1 2.5 0 3.5 1.1 1 1.1 1.3 2.6 0.8 4.7 0 0.1 0 0.2-0.1 0.2L83.9 48.3c-0.3 0.6-1.2 2.3-4.6 3C79.1 51.3 78.7 51.4 65.6 51.4z"/><path d="M89.7 27H28.8c-1.1 0-2-0.9-2-2s0.9-2 2-2h60.9c1.1 0 2 0.9 2 2S90.8 27 89.7 27z"/><path d="M85.2 39.7H31.5c-1.1 0-2-0.9-2-2s0.9-2 2-2h53.6c1.1 0 2 0.9 2 2S86.3 39.7 85.2 39.7z"/><path d="M49.5 51.4c-0.9 0-1.8-0.7-2-1.6l-6.8-35.1c-0.2-1.1 0.5-2.1 1.6-2.3 1.1-0.2 2.1 0.5 2.3 1.6L51.5 49c0.2 1.1-0.5 2.1-1.6 2.3C49.8 51.3 49.7 51.4 49.5 51.4z"/><path d="M67.6 51.4c-0.1 0-0.2 0-0.2 0 -1.1-0.1-1.9-1.1-1.7-2.2L69.8 14c0.1-1.1 1.1-1.9 2.2-1.7 1.1 0.1 1.9 1.1 1.7 2.2l-4.2 35.1C69.4 50.6 68.6 51.4 67.6 51.4z"/></svg></a>
						</li>
					</ul>
					<ul class="cta-panel-mobilelink">
						<li>
						<a onclick="window.open('http://www.facebook.com/sharer.php?u=${this._domain}/product/tasting-notes/${this.itemCode}','popup','width=800,height=600'); return false;">
							<span class="fa fa-facebook-square icons"></span>
						</a>
						</li>
						<li>
						<a onclick="window.open('https://twitter.com/intent/tweet?url=${this._domain}/product/tasting-notes/${this.itemCode}','popup','width=800,height=600'); return false;">
							<span class="fa fa-twitter-square icons"></span>
						</a>
						</li>
						<li>
						<a href="mailto: ?subject=I thought you may be interested in this &body=${this.name} ${this._domain}/product/${this.itemCode}"><span class="fa fa-envelope-o icons"></span></a>
						</li>
						<li class="copy-link">
						<a class="js-copy-link" href="#">
							<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 577.545 577.545" style="enable-background:new 0 0 577.545 577.545;" xml:space="preserve"> <g><g>
							<path d="M245.531,245.532c4.893-4.896,11.42-7.589,18.375-7.589s13.482,2.696,18.375,7.589l49.734,49.734 c1.723,1.72,4.058,2.689,6.49,2.689s4.771-0.967,6.49-2.689l49.733-49.734c1.724-1.72,2.69-4.058,2.69-6.49 c0-2.433-0.967-4.771-2.69-6.49l-49.733-49.734c-21.668-21.662-50.469-33.589-81.093-33.589s-59.425,11.928-81.093,33.586 L33.602,332.022C11.934,353.69,0,382.494,0,413.128c0,30.637,11.934,59.432,33.605,81.084l49.731,49.73 c21.65,21.668,50.447,33.603,81.081,33.603s59.438-11.935,81.108-33.603l84.083-84.082c2.705-2.705,3.448-6.803,1.869-10.285 c-1.496-3.295-4.776-5.386-8.356-5.386c-0.205,0-0.407,0.007-0.615,0.021c-2.959,0.199-5.958,0.297-8.917,0.297 c-23.354,0-46.322-6.208-66.417-17.956c-1.444-0.844-3.042-1.254-4.629-1.254c-2.375,0-4.725,0.921-6.494,2.689l-53.238,53.238 c-4.902,4.901-11.426,7.604-18.372,7.604c-6.949,0-13.479-2.699-18.381-7.604l-49.734-49.734 c-4.908-4.896-7.61-11.411-7.616-18.348c-0.003-6.953,2.699-13.489,7.616-18.406L245.531,245.532z"/>
							<path d="M543.942,83.324L494.208,33.59C472.556,11.931,443.762,0,413.128,0s-59.438,11.928-81.105,33.587l-84.086,84.119 c-2.705,2.705-3.448,6.806-1.867,10.288c1.497,3.292,4.777,5.382,8.354,5.382c0.205,0,0.413-0.006,0.621-0.021 c2.987-0.202,6.013-0.303,9-0.303c23.4,0,46.316,6.206,66.274,17.947c1.45,0.854,3.057,1.267,4.65,1.267 c2.375,0,4.725-0.921,6.494-2.689l53.274-53.274c4.893-4.896,11.42-7.589,18.375-7.589s13.482,2.696,18.375,7.589l49.734,49.734 c10.123,10.135,10.123,26.634-0.003,36.775L332.017,332.014c-4.894,4.905-11.408,7.604-18.348,7.604 c-6.956,0-13.495-2.702-18.415-7.61l-49.723-49.725c-1.723-1.72-4.057-2.69-6.49-2.69c-2.433,0-4.771,0.967-6.49,2.69 l-49.734,49.734c-3.586,3.586-3.586,9.397,0,12.983l49.734,49.734c21.668,21.668,50.469,33.602,81.093,33.602 c30.625,0,59.426-11.934,81.094-33.602l149.205-149.206c21.668-21.658,33.603-50.462,33.603-81.102S565.61,104.983,543.942,83.324 z"/>
							</svg>
						</a>
						</li>
					</ul>
				</div>`
		)
	}

	get template() {
		let values = this.values();
		let tastingNotes = this.tastingNotes();
		let moreInformation = this.moreInformation();

		let mapImageFile = [];
		let otherImageFile = [];
		// Find a quote in the awards and extract the first one
		let quote = [];
		let ratings = [];
		let accolades = [];
		let accoladeMap = [];
		let mobileTaskBar = this.mobileTaskBar();
		let pioneer = false;
		let pioneerIcon = [];

		/**
		 * Setup the tasting note UI
		 */
		if (this._item.tastingNotes && this._item.tastingNotes.mapImageFile) {
			mapImageFile = (`
				<div class="text-center">
					<img class="img-responsive img-thumbnail" src="/images/uk/en/law/tastingnote/map/${this._item.tastingNotes.mapImageFile}.jpg" style="max-height: 300px" onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/no-tasting-image.jpg';"/>
				</div>
			`)
		}

		if (this._item.tastingNotes && this._item.tastingNotes.otherImageFile) {
			otherImageFile = (`
				<div class="text-center">
					<img class="img-responsive img-thumbnail" src="/images/uk/en/law/tastingnote/information/${this._item.tastingNotes.otherImageFile}.jpg" style="max-height: 300px;" onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/no-tasting-image.jpg';"/>
				</div>
			`)
		}

		/**
		 * Prepare the rating stars
		 */
		if (this._item.ratingDetails && this._item.ratingDetails.productRating) {
			ratings = (`
				<div class="rating-container">	
					<span class="rating-avg">${this._item.ratingDetails.productRating.avgRating} / 5</span> (<a href="#" class="js-review-wine noofreviews" data-itemcode="${this._item.itemCode}">${this._item.ratingDetails.productRating.numberOfReviews} reviews</a>)</div>
					<div class="review-rating"><a class="js-review-wine reviewthiswine" data-itemcode="${this._item.itemCode}">Review this wine</a></div>		   
			`)
		}

		/**
		 * Check the item for accolades and prepare UI if
		 * there is
		 */
		if (this._item.accolades && this._item.accolades.length > 0) {

			accoladeMap = this._item.accolades.map((accolade) => {
				if(accolade.accoladeNameText === 'GREEN PIONEER'){
					pioneer = true;
					return (`<div class="pioneer-flag">
					<img title='Pioneer icon' alt="green pioneer flag" style="margin-left: -16px;" class="rb-reward-img no-pad-right" src="/images/uk/en/common/icons/pioneer_icon.svg">
					Our "Packaging Pioneers" are those wines which come in low-carbon formats, light glass, or are bottled close to markets. Important steps in reducing the carbon footprint of our wine.
				</div>`)
				}else{
					if(accolade.accoladeCategoryText !== 'QUOTE'){
						if (accolade.levelTypeDesc !== undefined) {
							return (`<li>${accolade.accoladeDescription && !accolade.levelTypeDesc.includes(accolade.accoladeDescription) ? (accolade.accoladeDescription + ',' + (accolade.accoladeNameText ? accolade.accoladeNameText : '')) :
								accolade.levelTypeDesc ? accolade.levelTypeDesc + ',' + (accolade.accoladeNameText ? accolade.accoladeNameText : '') :
									(accolade.accoladeNameText ? accolade.accoladeNameText : '')}</li>`)
						} else if (accolade.accoladeDescription !== undefined) {
							return (`<li>${(accolade.accoladeDescription + ',' + (accolade.accoladeNameText ? accolade.accoladeNameText : ''))}</li>`)
						} else {
							return (`<li>${accolade.accoladeNameText ? accolade.accoladeNameText : ''}</li>`)
						}
                    }
				}
			}).join(' ');
			accolades = (`
				<div class="accolades-container">
					${accoladeMap}
				</div>
			`);
			quote = this._item.accolades.map((accolade) => {
				if (accolade.accoladeCategoryText === 'QUOTE') {
					return (`						
						<div class="quote-container">
							<span class="quote">${accolade.accoladeDescription}</span>
							<span class="person">${accolade.accoladePerson}</span>
						</div>
					`)
				}
			}).join(' ');
		}

		return (`
			<div class="tasting-notes-print-page">
				<div class="row marg-15-bottom tasting-notes-title">
					<div class="col-xs-12 text-center">
					<div class="page-title">Beyond the Label</div>
					</div>
					<p class="marg-15-top marg-15-bottom">&nbsp;</p>
				</div>
				<div class="col-xs-12 text-center prod-image-holder-mobile hidden-sm hidden-md hidden-lg">
					<img id="bottle-image" class="img-responsive prod-img large js-default-bottle-image-handler"
					src="${values.image}" alt="${values.name} ${values.vintage}"
					title="${values.name} ${values.vintage}" />
				</div>

				<div class="col-xs-12 col-sm-8 col-md-8 col-lg-9 no-pad product-left-panel">
					<h1>${values.name} ${values.vintage}</h1>
					<h3 class="appel-name">${values.appellationName}</h3>
					<div class="review-rating review-rating-container">				
						<div class="rating_a">
							<div class="stars-outer">
								<div class="stars-inner"></div>
							</div>
						</div>
						${ratings}
					</div>

					<div class="clearfix"></div>

					<div id="accolades" style="display: none">
						<a href="#" class="${pioneer ? 'award-link js-view-awards pioneer-award' : 'award-link js-view-awards'}">View awards</a>
						<div id="js-list-awards" style="display: none" class="award-panel">
							<ul>
								${accolades}
							</ul>
						</div>
					</div>
					
					<div class="clearfix"></div>
					<p class="web-description pad-25-top hidden-xs">${values.description}</p>
				</div>

				<div class="col-xs-12 col-sm-4 col-md-4 col-lg-3 rightnav-panel">
					<div class="hidden-xs">
						<ul class="cta-panel">
							<li class="favourite-container">
								<button class="js-remove-favourite" data-itemcode="${this._item.itemCode}" style="display: none"><span class="fa fa-lg fa-heart pull-left icons text-center"></span> Favourite</button>
								<button class="js-add-favourite" data-itemcode="${this._item.itemCode}" style="display: none"><span class="fa fa-lg fa-heart fa-heart-o pull-left icons text-center"></span> Favourite</button>
							</li>
							<li>
								<a href="/jsp/templates/page_templates/uk/common/print_template.jsp?page=product&itemcode=${this._item.itemCode}" target="_blank"><span class="pull-left icons fa fa-print fa-2x text-center"></span></a>
								<div class="detail-text"><a href="/jsp/templates/page_templates/uk/common/print_template.jsp?page=product&itemcode=${this._item.itemCode}" target="_blank">Print</a></div>
							</li>
							<li>
								<a href="#"><span class="pull-left icons fa fa-share-alt fa-2x text-center"></span></a>
								<div class="detail-text"><a data-toggle="collapse" href="#sharelinks" role="button" aria-expanded="false" aria-controls="sharelinks" class="share collapsed">Share</a></div>
								<div class="collapse sharelink-table" id="sharelinks">
									<div class="card card-body">
									<ul class="share-links">
										<li><a onclick="window.open('http://www.facebook.com/sharer.php?u=${this._domain}/product/tasting-notes/${this._item.itemCode}','popup','width=800,height=600'); return false;"><span class="fa fa-facebook-square icons"></span></a></li> 
										<li><a onclick="window.open('https://twitter.com/intent/tweet?url=${this._domain}/product/tasting-notes/${this._item.itemCode}','popup','width=800,height=600'); return false;"><span class="fa fa-twitter-square icons"></span></a></li>
										<li>
											<a href="mailto: ?subject=I thought you may be interested in this &body=${values.name} %0D%0A ${this._domain}/product/${this._item.itemCode}"><span class="fa fa-envelope-o icons"></span></a></li>
										<li>
											<a href="#" class="js-copy-link">
											<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 577.545 577.545" style="enable-background:new 0 0 577.545 577.545;" xml:space="preserve"> <g><g>
												<path d="M245.531,245.532c4.893-4.896,11.42-7.589,18.375-7.589s13.482,2.696,18.375,7.589l49.734,49.734 c1.723,1.72,4.058,2.689,6.49,2.689s4.771-0.967,6.49-2.689l49.733-49.734c1.724-1.72,2.69-4.058,2.69-6.49 c0-2.433-0.967-4.771-2.69-6.49l-49.733-49.734c-21.668-21.662-50.469-33.589-81.093-33.589s-59.425,11.928-81.093,33.586 L33.602,332.022C11.934,353.69,0,382.494,0,413.128c0,30.637,11.934,59.432,33.605,81.084l49.731,49.73 c21.65,21.668,50.447,33.603,81.081,33.603s59.438-11.935,81.108-33.603l84.083-84.082c2.705-2.705,3.448-6.803,1.869-10.285 c-1.496-3.295-4.776-5.386-8.356-5.386c-0.205,0-0.407,0.007-0.615,0.021c-2.959,0.199-5.958,0.297-8.917,0.297 c-23.354,0-46.322-6.208-66.417-17.956c-1.444-0.844-3.042-1.254-4.629-1.254c-2.375,0-4.725,0.921-6.494,2.689l-53.238,53.238 c-4.902,4.901-11.426,7.604-18.372,7.604c-6.949,0-13.479-2.699-18.381-7.604l-49.734-49.734 c-4.908-4.896-7.61-11.411-7.616-18.348c-0.003-6.953,2.699-13.489,7.616-18.406L245.531,245.532z"/>
												<path d="M543.942,83.324L494.208,33.59C472.556,11.931,443.762,0,413.128,0s-59.438,11.928-81.105,33.587l-84.086,84.119 c-2.705,2.705-3.448,6.806-1.867,10.288c1.497,3.292,4.777,5.382,8.354,5.382c0.205,0,0.413-0.006,0.621-0.021 c2.987-0.202,6.013-0.303,9-0.303c23.4,0,46.316,6.206,66.274,17.947c1.45,0.854,3.057,1.267,4.65,1.267 c2.375,0,4.725-0.921,6.494-2.689l53.274-53.274c4.893-4.896,11.42-7.589,18.375-7.589s13.482,2.696,18.375,7.589l49.734,49.734 c10.123,10.135,10.123,26.634-0.003,36.775L332.017,332.014c-4.894,4.905-11.408,7.604-18.348,7.604 c-6.956,0-13.495-2.702-18.415-7.61l-49.723-49.725c-1.723-1.72-4.057-2.69-6.49-2.69c-2.433,0-4.771,0.967-6.49,2.69 l-49.734,49.734c-3.586,3.586-3.586,9.397,0,12.983l49.734,49.734c21.668,21.668,50.469,33.602,81.093,33.602 c30.625,0,59.426-11.934,81.094-33.602l149.205-149.206c21.668-21.658,33.603-50.462,33.603-81.102S565.61,104.983,543.942,83.324 z"/>
											</svg>
											</a>
										</li>
									</ul>
									</div>
								</div>
							</li>
						</ul>
						<div>
							<a class="js-buy-wine btn btn-primary btn-block outline" href="#" data-itemcode="${this._item.itemCode}">Buy this wine</a>
						</div>
					</div>
					<div id="contentcopied"></div>
				</div>

					${mobileTaskBar}

				</div>

				<p class="no-pad-top product-left-panel hidden-sm hidden-md hidden-lg">
					${values.description}
				</p>

				<div class="col-xs-12 col-sm-12 wine-file-section">
					<div class="no-pad col-lg-3 col-sm-3 col-md-3 col_1">
						${tastingNotes}
					</div>	
					<div class="col-lg-3 col-sm-3 col-md-3 prod-image-holder hidden-xs">
						<img src="${values.image}" style="max-height: 260px;max-width:100%" />
					</div>	
					<div class="no-pad col-lg-6 col-sm-6 col-md-6">
						${moreInformation}
					</div>
				</div>

				<hr />

				<div class="row"">
					<div class="col-xs-12">
						<div class="long-description">${values.longDescription}</div>
					</div>
				</div>				

				<div class="grid-tasting">
					${(quote.length > 0) ?
					`<div class="wine-file-quote-section ${this._colourId}">	
						${quote}
					</div>`
					 : ''}
					<div class="tasting-note-image-section">
						${mapImageFile}
					</div>
					<div class="tasting-note-image-section">
						${otherImageFile}
					</div>			 	
				</div>

			${mobileTaskBar}
		`);
	}

	loadCaseToModal() {

	}

	loadItemToModal(colourId) {
		this.modal = new Modal({
			title: 'Beyond the Label',
			body: this.template,
			containsForm: true,
			showFooter: false,
			largeModal: true,
			id: 'tasting-note-overlay',
			colourId,
		});

		/**
		 * Open the modal
		 */
		this.modal.open();

		if (this._item.ratingDetails && this._item.ratingDetails.productRating) {
			this.getStars(this._item.ratingDetails.productRating.avgRating);
		}

		/**
		 * Display the awards block if the item contains accolades 
		 * and the first accolade is not a QUOTE
		 */
		if (this._item.accolades && this._item.accolades.length > 0) {
			if (this._item.accolades[0].accoladeCategoryText !== 'QUOTE') {
				document.getElementById('accolades').style.display = "block";
			}
		}

		/**
		 * Check to see if the item is a favourite
		 */
		this.getFavourite(this._item.itemCode);

		/**
		 * Add click events for element in the modal
		 */

		const reviewButton = Array.from(document.querySelectorAll('.js-review-wine'));
		reviewButton.forEach((btn) => {
			btn.addEventListener("click", (e) => {
				e.preventDefault();
				this.modal.close();
				const itemCode = e.target.getAttribute('data-itemcode');
				window.location.href = `/product/${itemCode}#ReviewsApp`;
			});
		});

		const buyWineButton = Array.from(document.querySelectorAll('.js-buy-wine'));
		buyWineButton.forEach((btn) => {
			const itemCode = btn.getAttribute('data-itemcode');
			btn.addEventListener("click", (e) => {
				e.preventDefault();
				// Close the modal if the overlay is for the same product
				// we are looking at, else redirect to item page
				if (window.location.href.indexOf(`/${itemCode}`) > -1) {
					this.modal.close();
				} else {
					window.location.href = `/product/${itemCode}`;
				}
			});
		});

		const favaddButton = Array.from(document.querySelectorAll('.js-add-favourite'));
		favaddButton.forEach((btn) => {
			btn.addEventListener("click", (e) => {
				e.preventDefault();
				if (User.status === 'soft' || User.status === 'hard') {
					this.addFavourite(e.target.getAttribute('data-itemcode'));
				} else {
					window.location.href = "/jsp/registration/common/account_login.jsp";
				}
			});
		});

		const favremvButton = Array.from(document.querySelectorAll('.js-remove-favourite'));
		favremvButton.forEach((btn) => {
			btn.addEventListener("click", (e) => { e.preventDefault(); this.removeFavourite(e.target.getAttribute('data-itemcode')); });
		});

		const copyButton = Array.from(document.querySelectorAll('a.js-copy-link'));
		copyButton.forEach((btn) => {
			btn.addEventListener("click", function (e) {
				const url = window.location.href;
				if (typeof (navigator.clipboard) == 'undefined') {
					var textArea = document.createElement("textarea");
					textArea.value = url;
					textArea.style.position = "fixed";
					document.body.appendChild(textArea);
					textArea.focus();
					textArea.select();
					var successful = document.execCommand('copy');
					document.body.removeChild(textArea)
					// return;
				}
				else {
					navigator.clipboard.writeText(url).then(function () {
						document.execCommand('copy');
					});
				}
				const popup = document.createElement("div");
				popup.classList.add('copied-confirmation-popup');
				popup.innerHTML = "<div class='checkmark'> <div class='checkmark_stem'></div> <div class='checkmark_kick'></div> </div> Link Copied";
				$("#contentcopied").append(popup);
				setTimeout(function () {
					popup.classList.add('fadeout');
					setTimeout(function () {
						popup.parentNode.removeChild(popup);
					}, 500);
				}, 2500);
				this._animate = false;
			});
		});

		const shareButton = Array.from(document.querySelectorAll('a.js-view-share-link'));
		shareButton.forEach((btn) => {
			btn.addEventListener("click", function (e) {
				e.preventDefault();
				if (navigator.share) {
					navigator.share({
						title: document.querySelector('h1').textContent,
						url: document.querySelector('link[rel=canonical]') && document.querySelector('link[rel=canonical]').href || window.location.href
					});
				}
				else {
					if (this.parentNode.parentNode.nextElementSibling.style.display === "block") {
						this.parentNode.parentNode.nextElementSibling.style.display = "none";
					} else {
						this.parentNode.parentNode.nextElementSibling.style.display = "block";
					}
				}
			});
		});

		const awardsButton = Array.from(document.querySelectorAll('a.js-view-awards'));
		awardsButton.forEach((btn) => {
			btn.addEventListener("click", function (e) {
				e.preventDefault();
				const container = btn.closest('#accolades');
				const target = container.find('#js-list-awards');
				if (target.style.display === "none") {
					target.style.display = "block";
					btn.classList.add('open');
				} else {
					target.style.display = "none";
					btn.classList.remove('open');
				}
			});
		});
	}

	loadItemToDiv() {
		let PrintPage = document.getElementById("tastingNotes-printSection");
		PrintPage.innerHTML = this.template;
	}
}
export default TastingNote;