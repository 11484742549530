import { $ } from "../../utilities/min";
import Component from "../component";
import MenuView from "./menu/menu";
import Modal from "../modal/modal";
import LoginModal from "./login";
import LogoutModal from "./logout";
import User from "../../models/user/user";
import SearchView from "../search/search";
import CartView from "./cart/cart";
import Cart from "../../models/cart/cart";
import getBrand from 'dw-brand-identify';
import { removeCookie } from '../upsell/cookie';
import { pageInteractionDl, clickEventDL } from 'dw-global/apps/dataLayer';

class HeaderView extends Component {
	constructor() {
		super("header#new-homepage-test-header");
		this._brandObj = getBrand();
		this.menuButton = $("#menu");
		this.menuComponent = new MenuView();
		this.navigationOffset = this.el.find("#main-navigation").offsetTop + this.el.offsetTop;
		this.navigationOffsetMobile = this.el.offsetTop;
		this.headerHeight = this.el.clientHeight + this.el.offsetTop;

		this.menuButton.on("click", () => {
			if (document.querySelector('.searchOpen')) {
				this.search.toggle();
			}
			this.menuComponent.toggle();
		});

		this.checkoutButton = this.el.find('.checkout-link');

		// bind for the login dialog to appear
		this.addEvent(this.el.find("#account a.login"), "click", e => {
			e.preventDefault();
			const login = new LoginModal();
		});

		this.addEvent(this.el.find("#user-not-logged-in svg"), "click", e => {
			const login = new LoginModal();
		});

		// add an event listener for login modal to appear
		document.addEventListener("login-modal", (e) => {
			const login = new LoginModal();
		});

		if (this._brandObj.id === 'hsb') {
			this.addEvent(this.el.find("#user-not-logged-in-hsb svg"), "click", e => {
				const login = new LoginModal();
			});
		}

		this.addEvent(this.el.find('#user-logged-in a.name'), 'touchend', this.tabletAccountMenu.bind(this));
		let tabletSvg = this.el.find('#accountSvg');
		if (tabletSvg.length !== 0) {
			this.addEvent(tabletSvg, 'touchend', this.tabletAccountMenu.bind(this));
		}

		if (
			document.querySelector("#main-navigation a.js-mobile-signout-link")
		) {
			this.addEvent(
				this.el.find("a.js-mobile-signout-link"),
				"click",
				e => {
					e.preventDefault();
					window.location = document.querySelector(".soft-logout-link").href;
				}
			);
		}

		if (
			document.querySelector("#main-navigation a.js-mobile-notyou-link")
		) {
			this.addEvent(
				this.el.find("a.js-mobile-notyou-link"),
				"click",
				e => {
					e.preventDefault();
					window.location = document.querySelector(".hard-logout-link").href;
					removeCookie('UpsellRejectionCount');
				}
			);
		}

		if (
			document.querySelector(".hard-logout-link")
		) {
			this.addEvent(
				this.el.find(".hard-logout-link"),
				"click",
				e => {
					e.preventDefault();
					removeCookie('UpsellRejectionCount');
					window.location = document.querySelector(".hard-logout-link").href;
				}
			);
		}

		// add an event to keep a sticky header
		window.addEventListener("scroll", this.sticky.bind(this));

		User.subscribe(User.actions.UPDATED_START, () => {
			this.el.find("#account #user-not-logged-in").classList.add("loading");
			if (this._brandObj.id === 'hsb') {
				this.el.find("#account #user-not-logged-in-hsb").classList.add("loading");
			}
		});

		User.subscribe(
			User.actions.UPDATED_SUCCESSFUL,
			this.toggleLoggedInView.bind(this)
		);
		User.subscribe(
			User.actions.UPDATED_FAILURE,
			this.toggleLoggedInView.bind(this)
		);

		Cart.subscribe(
			Cart.actions.UPDATED_SUCCESSFUL,
			() => {
				if (Cart.items.length > 0) {
					this.checkoutButton.classList.remove('disabled');
				} else {
					this.checkoutButton.classList.add('disabled');
				}
			}
		)

		User.subscribe(User.actions.PAYMENT_OPTIONS_SUCCESSFUL, (data) => {
			const res = data.response;
			if (!res && res.length <= 0) {
				return;
			}
			const hasGooglePay = res.find(i => i.paymentMode === 'googlePay' && !!i.paymentProvider);
			const hasApplyPay = res.find(i => i.paymentMode === 'applePay' && !!i.paymentProvider);
			if (hasApplyPay) {
				let icons = document.querySelectorAll('.apple-pay-svg');
				if (icons && icons.length > 0) {
					icons.forEach(i => i.style.display = "block");
				}
			}

			if (hasGooglePay) {
				let icons = document.querySelectorAll('.gpay-svg');
				if (icons && icons.length > 0) {
					icons.forEach(i => i.style.display = "block");
				}
			}
		});

		this.search = new SearchView();
		
		this.addEvent(this.el.find("#search"), "click", () => {
			this.search.toggle();
			this.search.focus();
		});

		document.addEventListener('togglesearch', () => {
			this.search.toggle();
		});
		document.addEventListener('focussearch', () => {
			this.search.focus();
		})

		User.fetch();

		// hide the cart and the checkout button if we are on the shopping cart page
		if (window.location.toString().indexOf("shoppingcart.jsp") === -1) {
			// create the cart
			this.cart = new CartView();
		} else {
			document.querySelector("#cart").style.display = "none";
			document.querySelector("#checkout-link").style.cssText = 'display:none !important';
			// add the adobedatalayer event tracker 
			Array.from(document.querySelectorAll('.DLclick')).forEach((btn) => {
				this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
			});
			 // to trigger the basket product
			document.addEventListener("event-add-voucher", (e) => {
				clickEventDL(e.detail.event, e.detail);
			});
		}

		var element = document.querySelector('#autocomplete');


	}

	/*
	..######..########.####..######..##....##.##....##
	.##....##....##.....##..##....##.##...##...##..##.
	.##..........##.....##..##.......##..##.....####..
	..######.....##.....##..##.......#####.......##...
	.......##....##.....##..##.......##..##......##...
	.##....##....##.....##..##....##.##...##.....##...
	..######.....##....####..######..##....##....##...
	*/

	sticky(e) {
		const stripBanner = document.querySelector(".stripbannerhide");
		const deviceWidth = window.innerWidth;
		const contentDiv = document.getElementsByClassName('content');
		if (window.scrollY > (deviceWidth < 768 ? this.navigationOffsetMobile : this.navigationOffset) && !this.menuComponent.isOpen()) {
			this.el.classList.add("is-sticky");
			if (deviceWidth < 768) {
				document.body.style.paddingTop = this.el.clientHeight + this.el.offsetTop + "px";
				contentDiv.style.paddingTop = this.el.clientHeight + this.el.offsetTop + "px";
			} else {
				document.body.style.paddingTop = this.headerHeight + "px";
			}

			if (stripBanner) {
				stripBanner.style.display = "none";
			}

		} else {
			this.el.classList.remove("is-sticky");
			document.body.style.paddingTop = "0";
			if (stripBanner && !this.menuComponent.isOpen()) {
				stripBanner.style.display = "block";
			}
		}
	}

	updateCart(json) {
		const total = this.el.querySelector("#basket .total");
		const number = this.el.querySelector("#basket .number-of-items");

		number.innerHTML = json.numLineItems;
		total.innerHTML = (this._brandObj.id != 'hsb') ? "&pound;" + json.orderPriceInfo.total : json.orderPointsInfo.total + "pts";
	}

	truncate(max, value) {
		if (value.length <= max) {
			return value;
		}
		return value.substr(0, max) + "\u2026";
	}

	tabletAccountMenu(e) {
		if (document.body.offsetWidth < 768) {
			// we are a mobile device
			return false;
		}

		e.preventDefault();
		e.stopPropagation();

		const submenu = this.el.find('#account .dropdown');
		const userLoggedIn = this.el.find('#account #user-logged-in');
		const userLoggedInHsb = this.el.find('#account #user-logged-in-hsb');

		// create and stick in an 'x' to close the menu
		if (this.x && this.x.parentNode) {
			this.x.parentNode.removeChild(this.x);
		}
		this.x = document.createElement("div");
		this.x.classList.add("tablet-close");
		this.x.innerHTML = `
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 32.281 32.281">
				${document.getElementById("menu-close").innerHTML}
			</svg>
		`;
		// add the 'x' in
		submenu.appendChild(this.x);
		submenu.style.display = 'block';
		userLoggedIn.classList.add('hover');
		// bind the x
		this.addEvent(this.x, "click", () => {
			this.x.parentNode.removeChild(this.x);
			submenu.style.display = 'none';
			userLoggedIn.classList.remove('hover');
		});
		if (this._brandObj.id === 'hsb') {
			userLoggedInHsb.classList.add('hover');
			// bind the x
			this.addEvent(this.x, "click", () => {
				this.x.parentNode.removeChild(this.x);
				submenu.style.display = 'none';
				userLoggedInHsb.classList.remove('hover');
			});
		}
	}

	toggleLoggedInView(user) {
		const account = this.el.find("#account");
		const loginLink = this.el.find(".login-link");
		const loggedOutUser = this.el.find("#user-not-logged-in");
		const loggedOutUserHsb = this.el.find("#user-not-logged-in-hsb");
		const loggedInUser = this.el.find("#user-logged-in");
		const loggedInUserHsb = this.el.find("#user-logged-in-hsb");
		const mobileLoggedIn = this.el.find("#mobile-loggedin");
		// Log out links
		const notYouLink = this.el.find(".js-notyou-link");
		const signOutLink = this.el.find(".js-signout-link");
		const mobileNotYouLink = this.el.find(".js-mobile-notyou-link");
		const mobileSignOutLink = this.el.find(".js-mobile-signout-link");

		this.el.find("#account #user-not-logged-in").classList.remove("loading");
		if (this._brandObj.id === 'hsb') {
			this.el.find("#account #user-not-logged-in-hsb").classList.remove("loading");
		}
		// update the checkout link to go to the login page
		this.checkoutButton.href = "/jsp/checkout/common/shoppingcart.jsp";

		if (User.status === "soft") {
			//notYouLink.style.display = notYouLink.classList.contains("inline") ? "inline" : "block";
			mobileNotYouLink.style.display = "block";
			//signOutLink.style.display = "none";
			mobileSignOutLink.style.display = "none";
		}

		if (User.status === "hard") {
			//notYouLink.style.display = "none";
			mobileNotYouLink.style.display = "none";
			//signOutLink.style.display = signOutLink.classList.contains("inline") ? "inline" : "block";
			mobileSignOutLink.style.display = "block";
			// update the checkout link to the basket
			this.checkoutButton.href = "/jsp/checkout/common/shoppingcart.jsp";
		}

		// is the user logged in
		if (User.status === "soft" || User.status === "hard") {
			const truncName = this.truncate(8, User.firstName);
			// set the link to include their name
			loggedInUser.querySelector("a.name").innerText = `${truncName}`;
			mobileLoggedIn.querySelector("a.name").innerText = `${truncName}`;
			// show the logged in menu
			loggedInUser.style.display = "block";
			if (this._brandObj.id === 'hsb') {
				loggedInUserHsb.style.display = "block";
			}
			// hide the loggout menu
			loggedOutUser.style.display = "none";
			if (this._brandObj.id === 'hsb') {
				loggedOutUserHsb.style.display = "none";
			}
			// show the mobile nav
			mobileLoggedIn.style.display = "block";
			loginLink.style.display = "none";
			account.classList.remove("loggedout");
			// show reward balance if they have one
			User.savingAccount();
			User.paymentOptions();
		} else {
			// hide the logged in menu
			loggedInUser.style.display = "none";
			if (this._brandObj.id === 'hsb') {
				loggedInUserHsb.style.display = "none";
			}
			mobileLoggedIn.style.display = "none";
			// show the loggout menu
			loggedOutUser.style.display = "block";
			if (this._brandObj.id === 'hsb') {
				loggedOutUserHsb.style.display = "block";
			}
			loginLink.style.display = "block";
			loggedOutUser.querySelector(".login").innerText = "Login";
			// collaspe the submenu
			document.querySelector(".nav-login-item .submenu").style.display =
				"none";
			account?.classList?.add("loggedout");
		}
	}
	
	pageInteraction(element){
		pageInteractionDl(element);
	}
}

export default HeaderView;
