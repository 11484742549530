import classnames from 'classnames';

/**
 * Takes the data passed and creates the individual cell
 *
 * @return {String} => The html string for the individual one by two cell
 */
const oneByOne = ({
  dataSection,
  dataOfferImage,
  dataOfferButton,
  backgroundColour,
  title,
  content,
  imageUrl,
  imageAlt,
  buttonType,
  buttonUrl,
  buttonText,
}) => {
  const hasImage = !!imageUrl;
  const hasBackgroundColor = !!backgroundColour;
  const isEmpty = !hasImage && !hasBackgroundColor;

  const cellClasses = classnames({
    'block-square': hasBackgroundColor && !hasImage,
    'block-image-button': !(hasBackgroundColor && !hasImage),
    'cell-content--empty': isEmpty,
  }, 'cell-content');

  return `
    <div class="${cellClasses}">
      ${
        backgroundColour && !imageUrl
          ? `
        <div class="text ${backgroundColour}">
          ${title}
          ${content}
          <a class="btn btn-${buttonType} transparent"
            href="${buttonUrl}"
            data-section="${dataSection}"
            data-offer="${dataOfferButton}">
            ${buttonText}
          </a>
        </div>
        `
          : ''
      }
      ${
        imageUrl
          ? `
        <a href="${buttonUrl}" class="DLclick" data-section="${dataSection}" data-offer="${dataOfferImage}">
          <span class="text">
            <span class="btn btn-${buttonType} ${backgroundColour} cell-button">
              ${buttonText}
            </span>
          </span>
          <img src="${imageUrl}" class="DLclick" data-section="${dataSection}" data-action="navigation" data-name="home sections click" data-text="${buttonText}" alt="${imageAlt}">
        </a>
        `
          : ''
      }
    </div>
  `;
};

export default oneByOne;
