import Component from '../component';
import Template from '../../utilities/template';
import User from '../../models/user/user';
import getBrand from 'dw-brand-identify';

class RecommendedView extends Component {

	constructor() {
		super('section.recommended');

		User.subscribe(User.actions.RECOMMENDED_WINES_START, () => {
			this.el.classList.add('loading');
		});
		User.subscribe(User.actions.RECOMMENDED_WINES_SUCCESSFUL, this.render.bind(this));
		User.subscribe(User.actions.RECOMMENDED_WINES_FAILURE, this.failure.bind(this));
		// fetch the cart
		User.recommendedwines();

		this._windows = Array.from(this.el.querySelectorAll('.window'));
		this._tabs = Array.from(this.el.querySelectorAll('.tabs .tab'));
		this._sliderDots = this.el.querySelector('.slider-dots');
		this._left = this.el.querySelector('.scroll.left');
		this._right = this.el.querySelector('.scroll.right');
		this._touchStartX, this._touchMoveX, this._moveX, this._index = 0;
		this._sliderWidth = this.el.offsetWidth;
		this._endOfCarousel = this.endOfCarousel.bind(this);
		this._hideArrowAtEnd = this.hideArrowAtEnd.bind(this);
		this._template = this.el.querySelector('.template');

		this._tabs.forEach((tab) => {
			this.addEvent(tab, 'click', this.toggleView.bind(this));
		});

		this.addEvent(this._left, 'click', this.slide.bind(this, +1));
		this.addEvent(this._right, 'click', this.slide.bind(this, -1));

		this._windows.forEach((win) => {
			this.addEvent(win, 'touchstart', this.touchSlideStart.bind(this), {passive: true});
			this.addEvent(win, 'touchmove', this.touchSlideMove.bind(this), {passive: true});
			this.addEvent(win, 'touchend', this.touchSlideEnd.bind(this), {passive: true});
			onResize(win, this.handleSectionResize.bind(this));
		});
	}

	handleSectionResize() {
		this.productWidth();
		this.createsliderDots();
	}

	toggleView(e) {
		const tab = e.target.classList.contains('tab') ? e.target : e.target.closest('.tab');
		// find the tab in the index
		const location = this._tabs.indexOf(tab);

		// if the current one selected?
		if (tab.classList.contains('selected')) {
			// if we are on mobile show the dropdown
			this._tabs.forEach((t) => {
				t.classList.remove('d-none');
			});
			return;
		}

		this._tabs.forEach((t, i) => {
			if (tab === t) {
				t.classList.add('selected');
				this._windows[i].style.display = 'block';
				return;
			}
			t.classList.remove('selected');
			t.classList.add('d-none');
			this._windows[i].style.display = 'none';
		});

		this.setup();
	}

	productWidth() {
		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
		//Mobile view implementation.
		if (User._securityStatus === 0) {
			if(this._left.style.bottom !== '5.5%' && screenWidth <= 768) {
				this._right.style.bottom = this._left.style.bottom = "5.5%";
			}else if(this._left.style.bottom === '5.5%' && screenWidth > 768) {
				this._right.style.bottom = this._left.style.bottom = '';
			}
		}

		//perform width adjustment, when screen size is lessthan 768px.
		if(screenWidth > 767) {
			this._elementWidth = 220 //fixed width for destop view includes margin width
			return;
		}

		this._selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		const maxProductItemWidth = (this._selected.offsetWidth / 2) - 20; //Removeing 20px for margin b/w the products
		this._ul = this._selected.querySelector('ul');
		if(!this._ul) {
			return;
		}
		const productItems = this._selected.querySelectorAll('li');
		productItems.forEach(elem => {elem.style.width = `${maxProductItemWidth}px`;});

		//the margin adjust for small devices
		if(screenWidth <= 350) {
			this._elementWidth = maxProductItemWidth + 16.5;
		}else {
			this._elementWidth = maxProductItemWidth + 20;
		}
	}

	winesPerSlide() {
		this._selected =  this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		const widthOfContainer = this._selected.offsetWidth;
		const balance = widthOfContainer % this._elementWidth;
		const winesPerSlide = parseInt((widthOfContainer - balance) / this._elementWidth);
		return winesPerSlide;
	}

	hideArrows(widthOfContainer, itemWidth, newOffset) {
		const end = this._endOfCarousel(itemWidth, this.winesPerSlide(), newOffset);

		// Disable the right arrow if user reaches
		// the end of the carousel
		const right = this.el.querySelector(".scroll.right");
		if (end) {
			right.classList.add('disabled');
		} else if(right.classList.contains('disabled')) {
			right.classList.remove('disabled');
		}

		// Disable the left arrow if user reaches
		// the start of the carousel
		const left = this.el.querySelector(".scroll.left");
		if (newOffset === 0) {
			left.classList.add('disabled');
		} else if(left.classList.contains("disabled")) {
			left.classList.remove('disabled');
		}
	}

	endOfCarousel(widthOfElement, winesPerSlide, newOffset) {
		const scrollCount = Math.abs(newOffset) / widthOfElement;
		const itemsVisible = scrollCount + winesPerSlide;
		if (itemsVisible >= User._recommendedWinesArr.length) {
			return true;
		}
	}

	//Remove ability to scroll right if no other wines available
	hideArrowAtEnd(){
		const winesPerSlide = this.winesPerSlide();
		const winesReturned = User._recommendedWinesArr.length;

		if(winesReturned <= winesPerSlide){
			this.el.querySelector(".scroll.right").style.display = 'none';
			this.el.querySelector(".scroll.left").style.display = 'none';
		}

		this.el.querySelector(".scroll.left").classList.toggle('disabled');
	}

	cleanupNewOffset(offset, direction) {
		let firstVisibleIndex = Math.abs(offset) / this._elementWidth;
		const winesReturned = User._recommendedWinesArr.length;
		const unScrolledItems = winesReturned - firstVisibleIndex;

		if(unScrolledItems < this.winesPerSlide() && direction < 0) {
			const reduceScrollOffset = (this.winesPerSlide() - unScrolledItems) * this._elementWidth;
			offset += reduceScrollOffset;
		} else if(direction > 0 && offset > 0) {
			offset = 0;
		}
		firstVisibleIndex = Math.abs(offset) / this._elementWidth;
		this._index = firstVisibleIndex;

		return offset;
	}

	slide(direction) {
		const offset = parseInt(this._ul.style.transform.replace('translateX(', '').replace('px)', ''), 10) || 0;
		const scrollWidth = this.winesPerSlide() * this._elementWidth;

		if (offset === 0 && direction > 0) {
			return;
		}

		let newOffet = direction < 0 ? offset - scrollWidth : offset + scrollWidth;
		newOffet = this.cleanupNewOffset(newOffet, direction);

		this._ul.style.transform = `translateX(${newOffet}px)`;

		this.hideArrows(this._selected.offsetWidth, this._elementWidth, newOffet);
		this.updateActiveDot(Math.ceil(Math.abs(newOffet / scrollWidth)));
	}

	touchSlideStart(e) {
		this._moveX = null;
		this._ul.classList.remove('animate');
		this._touchStartX = e.touches[0].pageX;
	}

	touchSlideMove(e) {
		this._touchMoveX = e.touches[0].pageX;
		this._moveX = (this._index*this._elementWidth) + (this._touchStartX - this._touchMoveX);
	}

	touchSlideEnd() {
		if(!this._moveX) {
			return;
		}
		const absMove = Math.abs(this._index * this._elementWidth - this._moveX);
		let direction = 0;
		if (absMove > this._elementWidth/2) {
			if (this._moveX > (this._index * this._elementWidth) && this._index < this._ul.querySelectorAll('li').length -1) {
				this._index++;
				direction = -1;
			} else if (this._moveX < (this._index * this._elementWidth) && this._index > 0) {
				this._index--;
				direction = 1;
			}
		}

		this._ul.classList.add('animate');

		if(direction !== 0) {
			this.slide(direction);
		}
	}

	failure(e) {
		// Remove loading spinner
		this.el.classList.remove('loading');
		const wines = this.el.querySelector(".template");
		let error = document.createElement('li');
			error.classList.add('text-center');
			error.innerHTML = `
				No Recommended Wines
			`;
		wines.appendChild(error);
	}

	truncate(max, value) {
		return value.substr(0, max) + "\u2026";
	}

	updateActiveDot(index) {
		const currentElem = this._sliderDots.querySelector('.active');
		if (currentElem) {
			currentElem.classList.remove('active');
		}
		if(this._sliderDots.children[index]) {
			this._sliderDots.children[index].classList.add('active');
		}
	}

	onDotSelect(index,event) {
		if(event) {
			event.stopPropagation();
		}
		const currentOffset = parseInt(this._ul.style.transform.replace('translateX(', '').replace('px)', ''), 10) || 0;
		const scrollWidth = this.winesPerSlide() * this._elementWidth;
		let newOffset = -index * scrollWidth;
		const direction = currentOffset > newOffset ?  -1 : 1;
		if(newOffset === direction) {
			return;
		}
		newOffset = this.cleanupNewOffset(newOffset, direction);

		this._ul.style.transform = `translateX(${newOffset}px)`;

		this.hideArrows(this._selected.offsetWidth, this._elementWidth , newOffset);
		this.updateActiveDot(index);
	}

	createsliderDots() {
		this._sliderDots = this.el.querySelector('.slider-dots');
		const winesPerSlide = this.winesPerSlide();
		const winesReturned = User._recommendedWinesArr.length;

		if(winesReturned < winesPerSlide || !this._sliderDots) {
			return;
		}

		const currentChildCount = this._sliderDots.children.length;
		let sliderCount = Math.ceil(winesReturned / winesPerSlide);

		if(currentChildCount > sliderCount) {
			let diff = currentChildCount - sliderCount;

			for (const elem of Array.from(this._sliderDots.children).reverse()) {
				if(diff > 0) {
					this._sliderDots.removeChild(elem);
					diff--;
					continue;
				}
				break;
			}
		} else  if(currentChildCount < sliderCount) {
			sliderCount -= currentChildCount; 
			const sliderArray = Array.from(Array(sliderCount).keys());
			sliderArray.forEach(i => {
				const el = document.createElement('span');
				const slideNo = currentChildCount + i;
				el.setAttribute('data-slide', slideNo);
				this._sliderDots.appendChild(el);

				this.addEvent(this._sliderDots.children[slideNo], 'click', this.onDotSelect.bind(this, slideNo));
				this.addEvent(this._sliderDots.children[slideNo], 'touchstart', this.onDotSelect.bind(this, slideNo), {passive: true});
				this.addEvent(this._sliderDots.children[slideNo], 'touchmove', this.onDotSelect.bind(this, slideNo), {passive: true});
				this.addEvent(this._sliderDots.children[slideNo], 'touchend', this.onDotSelect.bind(this, slideNo), {passive: true});
			})	
		}
	}

	activeDotSlider() {
		this.createsliderDots();
		const winesPerSlide = this.winesPerSlide();
		const winesReturned = User._recommendedWinesArr.length;
		this._brandObj = getBrand();

		if(winesReturned > winesPerSlide && (this._brandObj.id === 'law' || this._brandObj.id === 'stw')) {
			this._sliderDots.style.display = 'block';
		}

		this.updateActiveDot(0);
	}

	setup() {
		this._selected = this._windows.find((win) => { return win.style.display === 'block' }) || this._windows[0];
		this._ul = this._selected.querySelector('ul');
		const productItem = this._selected.querySelector('li');
		this._elementWidth = productItem.getBoundingClientRect ? productItem.getBoundingClientRect().width : productItem.offsetWidth;

		const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;		
		//the margin adjust for small devices
		if(screenWidth <= 350) {
			this._elementWidth += 16.5;
		}else {
			this._elementWidth += 20;
		}
		this._hideArrowAtEnd();
		this.activeDotSlider();
		// work out the offset to centre the items
		// if (document.body.offsetWidth <= 576) {
		// 	this._ul.style.paddingLeft = `${(this._template.offsetWidth - this._selected.querySelector('li').offsetWidth)/2}px`;
		// }
	}

	render() {
		// Remove loading spinner
		this.el.classList.remove('loading');

		// Hide render if no wines have yet to be browsed
		if (User._recommendedWinesArr && User._recommendedWinesArr.length === 0) {
			return this.el.classList.add('hide');
		}

		let displayRecommendationsLink = true;

		if (User._securityStatus === 0) {
			displayRecommendationsLink = false;
		}

		let wines = User._recommendedWinesArr.map((item) => {
			// Lets render out the rating stars
			let userRating = [];
			let ratingStars;
			let itemSaving;
			let pricePerBottle;
			let name = item.product.name;
			let truncName;
			let thumbnailImage = item.product.thumbnailImage;
			let itemCode = item.product.itemCode;

			if (item.ratingDetails && item.ratingDetails.productRating && item.ratingDetails.productRating.roundAvgRating) {
				let userRating = [];
				Array(item.ratingDetails.productRating.roundAvgRating).fill().forEach(_ => {
					  let starfont = "<i class=\"fa fa-star\"></i>";
					  userRating.push(starfont);
				});
				ratingStars = userRating.join('');
			}

			// Calculate the saving
			if (item.product.skus[0].listPrice > item.product.skus[0].salePricePerBottle) {
				itemSaving = (item.product.skus[0].listPrice - item.product.skus[0].salePricePerBottle).toFixed(2);
			}

			// Price per bottle
			if (item.product.skus && item.product.skus[0].vppApplier && item.product.skus[0].vppPrice) {
				pricePerBottle = item.product.skus[0].vppPrice.toFixed(2);
			} else {
				pricePerBottle = item.product.skus[0].salePricePerBottle.toFixed(2);
			}

			// Display name
			name = item.product.name;
			if (item.product.name && item.product.vintage) {
				name = `${item.product.name} ${item.product.vintage}`;
			}

			// Create the truncated item name for display
			// Remove the vintage if item is defined
			// as a mixed case
			let joinname = `${item.product.name} ${item.product.vintage}`;
			if (item.product && item.product.mixed) {
				joinname = `${item.product.name}`;
			}

			truncName = this.truncate(22, joinname);

			return {
				ratingStars,
				// itemSaving,
				pricePerBottle,
				name,
				truncName,
				thumbnailImage,
				itemCode,
			};
		});

		// filter out any which don't have a thumbnailImage
		wines = wines.filter(w => w.thumbnailImage );

		let data = {
			displayRecommendationsLink,
			wines
		}

		Template.get('/assets/components/homepage/tmpl/recommended/recommended.handlebars', {
			data
		}).then((template) => {
			this._template.innerHTML = template;
			this.setup();
		}).catch((e) => {
			console.log('TEMPLATE_LOAD_FAILURE:', e);
		});
	}
}

/**
 * [onResize description]
 *
 * @param   {DOMElement}  element   Pass the element to listen the size change
 * @param   {Function}  callback  Callback function, which is need to be called in size change
 *
 * @return  {Void}            
 * 
 * This function that will call you back 
 * when the size of the provided element changes 
 * as a result of either of those events:
 */
var onResize = function(element, callback) {
	if (!onResize.watchedElementData) {
	  // First time we are called, create a list of watched elements
	  // and hook up the event listeners.
	  onResize.watchedElementData = [];
  
	  var checkForChanges = function() {
		onResize.watchedElementData.forEach(function(data) {
		  if (data.element.offsetWidth !== data.offsetWidth ||
			  data.element.offsetHeight !== data.offsetHeight) {
			data.offsetWidth = data.element.offsetWidth;
			data.offsetHeight = data.element.offsetHeight;
			data.callback();
		  }
		});
	  };
  
	  // Listen to the window's size changes
	  window.addEventListener('resize', checkForChanges);
  
	  // Listen to changes on the elements in the page that affect layout 
	  var observer = new MutationObserver(checkForChanges);
	  observer.observe(document.body, { 
		attributes: true,
		childList: true,
		characterData: true,
		subtree: true 
	  });
	}
  
	// Save the element we are watching
	onResize.watchedElementData.push({
	  element: element,
	  offsetWidth: element.offsetWidth,
	  offsetHeight: element.offsetHeight,
	  callback: callback
	});
  };

export default RecommendedView;
