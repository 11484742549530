import getBrand from 'dw-brand-identify';

export const templateForHeroWine = (upsellType) => {
    let signUpButtonContainer = [];

    if (upsellType === 'upsellonly') {
        signUpButtonContainer = `<div class="signup-button-container upsellonly-button-container">
        <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>
        <div class="">                        
            <div>
                <button id="signUpUpsellOnly" class="btn btn-primary">
                    Yes, I’d like 20% off this order
                </button>
            </div>  
            <div class="no-thanks">
                <button class="cancel btn btn-primary outline">
                    No thanks
                </button>
            </div>
        </div>
    </div>`
    } else {
        signUpButtonContainer = `<div class="signup-button-container">
        <div class="radio">
            <label class="styled-form-radio">
                <input type="radio" checked="checked">
                Add 6 bottles (a case) to basket
                <span class="checkmark"></span>
            </label>
        </div>

        <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>
        <div class="buttons">
            <div class="no-thanks">
                <button class="cancel btn btn-primary outline">
                    No thanks
                </button>
            </div>
            <div>
                <button id="signUp" class="btn btn-primary">
                    Yes, sign me up for 20% off this order
                </button>
            </div> 
        </div>
    </div>`
    }

    return (`
            <div> 
                <div class="row upsell-content">
                    <div class="col-sm-12">
                        <p>
                            <strong>Save an extra 20% today and join thousands of fellow fans in taking a case every 8 weeks</strong>
                        </p>
                        <ul>
                            <li>Extra 20% off your order today</li>
                            <li>20% ongoing savings</li>
                            <li>Cancel anytime</li>
                            <li>Never miss out!</li>
                        </ul>
                        <p>
                            <a id="termsLink">Terms &amp; Conditions apply</a><br>
                            <span>Note: Maximum discount £60</span>
                        </p>
                    </div>
                </div> 
                ${signUpButtonContainer}
            </div>
        `)
}

export const templateForNonHeroWine = (wineplan, upsellType) => {
    const caseName = wineplan?.displayName;
    const badgeName = wineplan?.displayName?.toUpperCase();
    let displayBottles = '';

    if (upsellType !== 'upsellonly') {
        displayBottles = `<div class="radio">
        <label class="styled-form-radio">
            <input type="radio" checked="checked">
            Add 6 bottles (a case) to basket
            <span class="checkmark"></span>
        </label>
    </div>`

    }

    return (`
            <div>
                <div class="row upsell-content">
                    <div class="col-xs-12 col-sm-7">
                        <p><img class="img-responsive" src="/images/uk/en/upsell/20percentoff.svg" width="142" height="33" /></p>
                        <p>
                            <div class="sub-header">You've made great choices ... here's another one!</div>
                            <p>Did you know thousands of customers with similiar tastes are signed up to receive a case of wines just like this every 8 weeks?</p>
                        </p>
                        <ul>
                            <li>Enjoy an extra 20% off your order today</li>
                            <li>Ongoing savings of at least 20%</li>
                            <li>Never miss out on your favourite style</li>
                            <li>Swap wines, skip or cancel anytime</li>
                        </ul>
                        <p>
                            <a id="termsLink">Terms &amp; Conditions apply</a><br>
                            <span>Note: Maximum discount £60</span>
                        </p>
                    </div>
                    <div class="col-xs-12 col-sm-5">
                        <div class="row">
                            <div class="col-xs-12 text-center">
                                <div class="plan-badge">${badgeName}</div>
                            </div>
                            <div class="col-xs-12">
                                <div class="sub-header">About ${caseName}</div>
                                <p>${wineplan.webHeadline}</p>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="signup-button-container">
                    ${displayBottles}
                    <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>
                    <div class="buttons">                        
                         
                        <div class="no-thanks">
                            <button class="cancel btn btn-primary outline">
                                No thanks
                            </button>
                        </div>    
                        <div>
                            <button id="signUp" class="btn btn-primary">
                                Yes, sign me up for 20% off today
                            </button>
                        </div>                     
                    </div>
                </div>
            </div>
        `)
}

export const templateForNonHeroWineRightPanel = (wineplan) => {
    const caseName = wineplan?.displayName;
    const badgeName = wineplan?.displayName?.toUpperCase();

    return (`
            <div>
                <div class="row upsell-content">
                    <div class="col-xs-12">
                        <p>
                            <div class="sub-header">You've made great choices ... here's another one!</div>
                            <p>We think you may like one of our great wine plans.</p>
                        </p>
                        <ul>
                            <li>Enjoy an extra 20% off your order today</li>
                            <li>Ongoing savings of at least 20%</li>
                            <li>Never miss out on your favourite style</li>
                            <li>Swap wines, skip or cancel anytime</li>
                        </ul>
                        <p>
                            <a id="termsLink">Terms &amp; Conditions apply</a><br>
                            <span>Note: Maximum discount £60</span>
                        </p>
                    </div>
                    <div class="col-xs-12">
                        <div class="row">
                            <div class="col-xs-12">
                                <div class="plan-badge">${badgeName}</div>
                            </div>
                            <div class="col-xs-12">
                                <div class="sub-header">About ${caseName}</div>
                                <p>${wineplan.webHeadline}</p>
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="signup-button-container">
                    <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>                                              
                    <div>
                        <button id="signUp" class="btn btn-primary btn-block">
                            Yes, sign me up for 20% off today
                        </button>
                    </div>   
                    <div class="no-thanks">
                        <button class="cancel btn btn-primary btn-block outline">
                            No thanks
                        </button>
                    </div>  
                </div>
            </div>
        `)
}

export const templateForPostUpsellWinePlan = (wineplan, itemData) => {
    const caseName = wineplan?.displayName;
    let caseImageColour = itemData.colourName ? itemData.colourName.toLowerCase() : 'red';

    return (`
        <div>
            <div class="row upsell-content">
            <div class="col-xs-12">
            <h3>Thousands of customers have already signed up to receive a case of wines just like you've bought every 8 weeks.</h3>
            </div>
                <div class="col-xs-12 col-sm-7">
                    <ul class="list-benefits">
                        <li>Enjoy an extra 20% off your first case</li>
                        <li>Ongoing savings of at least 20%</li>
                        <li>Never miss out on your favourite style</li>
                        <li>Swap wines, skip or cancel anytime</li>
                    </ul>
                    <div class="sub-header marg-top-25">About ${caseName}</div>
                        <p>${wineplan.webHeadline}</p>
                        <p>Sign up now, then sit back and enjoy</p>
                        <p>
                            <a id="termsLink">Terms &amp; Conditions apply</a><br>
                            <span>Note: Maximum discount £60</span>
                        </p>

                        <div>
                            <input type="checkbox" id="futurePayments" name="futurePayments">
                            <label for="futurePayments">Yes, please securely store my card details for future wine plan payments.</label>
                        </div>

                </div>
                <div class="col-xs-12 col-sm-5 align-center">
                    <img class="case-image" src="/images/uk/en/upsell/${caseImageColour}.svg" />                      
                </div>
            </div>
            <div class="signup-button-container">

                <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>

                <div class="buttons">  
                    <div class="no-thanks">
                        <button class="btn btn-link cancel" id="noThanks">
                            Not this time
                        </button>
                    </div>    
                    <div>
                        <button id="signUp" class="btn btn-primary">
                            Yes, sign me up for 20% off today
                        </button>
                    </div>                     
                </div>
            </div>
        </div>
    `)
}

export const templateForUnlimited = (total, salePrice) => {
    const brandObj = getBrand();
    const transactionTotal = (dataLayer[0].transactionTotal !== undefined && dataLayer[0].transactionDiscount !== undefined) ? (dataLayer[0].transactionTotal - dataLayer[0].transactionDiscount) : total;
    const CartTotal = brandObj.id !== 'hsb' ? "£" + transactionTotal : transactionTotal + 'pts';
    const savings = (dataLayer[0].transactionShipping !== undefined && dataLayer[0].transactionShipping !== 0) ? dataLayer[0].transactionShipping : 7.99;
    const offerPrice = (dataLayer[0].transactionShipping == undefined || dataLayer[0].transactionShipping !== 0) ? "£" + (salePrice - parseFloat(savings)).toFixed(2) : "£" + salePrice;
    const deliveryPrice = (dataLayer[0].transactionShipping == undefined || dataLayer[0].transactionShipping !== 0) ?
        `<div class="bg-pale col-lg-12">
            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                <p class="disclaimer-text savings-text">Less today's delivery charge </p>
            </div>
            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad">
                <p class="disclaimer-text savings-text pull-right">
                    -£${savings}
                </>
            </div>
        </div>` : '';
    let svgImg = `<svg class="guarantee-delivery clearfix" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><path class="cls-1" d="M0,43.8v66.4H88V43.8ZM37.9,95.1H26.8V58.9h5.4V90.4h5.7Zm21.1,0H52.9l-1.5-17c-.2-2.1-.4-3.7-.5-5.5-.1,1.7-.3,3.4-.5,5.5l-1.6,17H42.9L38.4,58.9h5.4L45.7,78l.3,3.5.3-3.5,1.8-19.1h5.6L55.6,78l.3,3.6.3-3.6,1.9-19.1h5.2Z"></path><path class="cls-1" d="M128.4,133.5H105.2a12.4,12.4,0,0,1,23.2,0Z"></path><path class="cls-1" d="M123,58.9H95.4v74.6h5a16.9,16.9,0,0,1,32.8,0h11.2V95.1ZM102.3,88V66.6h16.4l12.7,21.5Z"></path><path class="cls-1" d="M41.8,133.5H18.6a12.4,12.4,0,0,1,23.2,0Z"></path><path class="cls-1" d="M128.4,133.5H105.2a12.4,12.4,0,0,1,23.2,0Z"></path><path class="cls-1" d="M150,117.2v16.3H133.2a16.9,16.9,0,0,0-32.8,0H46.6a16.9,16.9,0,0,0-32.8,0H0V117.2Z"></path><path class="cls-1" d="M42.5,137.7a12.3,12.3,0,0,1-24.6,0,14.2,14.2,0,0,1,.7-4.2,12.4,12.4,0,0,1,23.2,0A11.6,11.6,0,0,1,42.5,137.7Z"></path><path class="cls-1" d="M129.1,137.7a12.3,12.3,0,1,1-24.6,0,14.2,14.2,0,0,1,.7-4.2,12.4,12.4,0,0,1,23.2,0A11.6,11.6,0,0,1,129.1,137.7Z"></path></svg>`
    if (brandObj.id === 'law'){
        svgImg = `<img class="guarantee-delivery clearfix" src="/images/uk/en/checkout/unlimited-logo.png" alt="unlimitedLogo" />`
    }
    if (brandObj.id === 'avy' || brandObj.id === 'stw') {
        svgImg = `<svg class="guarantee-delivery-AVY" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 200 100" style="
        z-index: -1;
        margin-left: -30px;
    ">
                        <defs>
                        <clipPath id="clip-path-truck">
                            <rect id="Rectangle_534" data-name="Rectangle 534" width="55.335" height="55.335" transform="translate(0 0)" class="color-red"></rect>
                        </clipPath>
                        </defs>
                        <g id="Group_1276" data-name="Group 1276" transform="translate(-544 -1616)">
                        <circle id="Ellipse_33" data-name="Ellipse 33" cx="64.5" cy="64.5" r="64.5" transform="translate(544 1616)" fill="#fff"></circle>
                        <g id="Group_1219" data-name="Group 1219" transform="translate(-736.566 466.897)">
                            <rect id="Rectangle_533" data-name="Rectangle 533" width="32.481" height="24.512" transform="translate(1317.567 1199.248)" class="color-red"></rect>
                            <g id="Group_1212" data-name="Group 1212" transform="translate(1317.566 1183.103)" clip-path="url(#clip-path-truck)">
                            <path id="Path_1510" data-name="Path 1510" d="M1687.99,761.343h-8.54a4.537,4.537,0,0,1,8.54,0" transform="translate(-1640.625 -712.083)" class="color-red"></path>
                            <path id="Path_1511" data-name="Path 1511" d="M1691.779,732.916v14.174h-4.113a6.244,6.244,0,0,0-12.1,0h-1.844V719.543h10.174Z" transform="translate(-1638.519 -697.829)" class="color-red"></path>
                            <path id="Path_1512" data-name="Path 1512" d="M1677.728,731.934v-7.906h6.038l4.682,7.938Z" transform="translate(-1639.993 -699.477)" class="color-red"></path>
                            <path id="Path_1513" data-name="Path 1513" d="M1637.491,761.343h-8.541a4.537,4.537,0,0,1,8.541,0" transform="translate(-1622.072 -712.083)" class="color-red"></path>
                            <path id="Path_1514" data-name="Path 1514" d="M1687.99,761.343h-8.54a4.537,4.537,0,0,1,8.54,0" transform="translate(-1640.625 -712.083)" class="color-red"></path>
                            <path id="Path_1515" data-name="Path 1515" d="M1673.41,753.587V759.6h-6.187a6.243,6.243,0,0,0-12.1,0h-19.843a6.244,6.244,0,0,0-12.1,0h-5.1v-6.009Z" transform="translate(-1618.076 -710.337)" class="color-red"></path>
                            <path id="Path_1516" data-name="Path 1516" d="M1637.6,762.877a4.54,4.54,0,1,1-9.079,0,4.409,4.409,0,0,1,.27-1.534,4.537,4.537,0,0,1,8.541,0,4.419,4.419,0,0,1,.269,1.534" transform="translate(-1621.915 -712.083)" class="color-red"></path>
                            <path id="Path_1517" data-name="Path 1517" d="M1688.1,762.877a4.539,4.539,0,1,1-9.079,0,4.422,4.422,0,0,1,.269-1.534,4.538,4.538,0,0,1,8.541,0,4.428,4.428,0,0,1,.269,1.534" transform="translate(-1640.469 -712.083)" class="color-red"></path>
                            </g>
                            <path id="Path_1518" data-name="Path 1518" d="M.237.48H7.47L13.1,9.987H.266Z" transform="translate(1354.369 1206.12)" fill="#f8f5ef"></path>
                        </g>
                        </g>
                        </svg>`
    }
    return (`
        <div>
            <div class="row upsell-content">
                <div class="upsell-body">
                    <div class="col-xs-12" style="z-index: 1;">
                        ${(offerPrice === '£0.00') ? 
                            `<h3>Get fast, Unlimited delivery for a year - for FREE! Choose from standard.
                            named day or next day delivery. Any number of bottles - even just one!
                            Limited time only.</h3>` :
                            `<h3>Get fast, Unlimited delivery for a year. Any number of bottles. Delivered anywhere. Whenever you want. And start saving today.</h3>`
                        }
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center no-pad-right-desk">
                        ${svgImg}
                        
                        <p class="text-left">
                            <a id="termsLink">Terms and Conditions apply</a>
                        </p>
                        <div class="check-box"><input type="checkbox" id="futurePayments" name="futurePayments"></div>
                        <label for="futurePayments" class="disclaimer-text text-left mt-10">Please securely store my card details for future Unlimited subscription payments.<br class="hidden-xs" /> <a href="#" id="learnMore" class="link">Learn More.</a></label>
                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                        <div class="bg-pale total-items col-lg-12">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p><strong>ORDER TOTAL </strong></p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad">
                                <span class="detail pull-right">
                                    <strong>${CartTotal}</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix visible-xs"></div>
                    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    ${(offerPrice === '£0.00') ? 
                        `<p class="unlimited-header">Claim FREE Unlimited Delivery</p>`
                    :
                        `<p class="unlimited-header">Add Unlimited</p>`
                    }

                        <div class="bg-pale col-lg-12">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p class="disclaimer-text text-normal">Annual Unlimited Fee ${(offerPrice === '£0.00') ? '(usually £29.99)' : '' }</p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad">
                                <p class="disclaimer-text text-normal pull-right">
                                    £${salePrice}
                                </p>
                            </div>
                        </div>
                        ${deliveryPrice}
                        <div class="bg-pale col-lg-12 offer-total  ${(salePrice == '9.99') ? 'savings-text' : ''}">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                            ${(salePrice == '9.99') ? `<p><strong>REFUND </strong></p>`
                            :`<p><strong>OFFER TOTAL </strong></p>`}
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad">
                                <span class="detail pull-right">
                                    <strong>${offerPrice}</strong>
                                </span>
                            </div>
                        </div>
                        <small class="">Additional info 
                            <div class="tooltip" data-toggle="tooltip" data-placement="right">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="13" height="13" viewBox="0 0 13 13">
                                    <defs>
                                    <clipPath id="clip-path">
                                        <rect width="13" height="13" fill="none"/>
                                    </clipPath>
                                    </defs>
                                    <g id="info_icon" data-name="info icon" clip-path="url(#clip-path)">
                                    <g id="info_icon-2" data-name="info icon" transform="translate(-91 -866)">
                                        <g id="Group_3880" data-name="Group 3880" transform="translate(-770 -7)">
                                        <g id="Group_448" data-name="Group 448" transform="translate(861 873)">
                                            <path id="Fill_1" data-name="Fill 1" d="M6.5,13A6.5,6.5,0,1,1,13,6.5,6.507,6.507,0,0,1,6.5,13Zm0-12A5.5,5.5,0,1,0,12,6.5,5.508,5.508,0,0,0,6.5,1Z" transform="translate(0 0)" fill="#333"/>
                                            <path id="Fill_4" data-name="Fill 4" d="M.5,4.849a.5.5,0,0,1-.5-.5V.5a.5.5,0,0,1,1,0V4.35a.5.5,0,0,1-.5.5" transform="translate(6.035 4.95)" fill="#333"/>
                                        </g>
                                        <path id="Fill_6" data-name="Fill 6" d="M1.014.507A.507.507,0,1,1,.507,0a.507.507,0,0,1,.507.507" transform="translate(867.035 876.201)" fill="#333"/>
                                        </g>
                                    </g>
                                    </g>
                                </svg>
                                <span class="tooltiptext">
                                    We haven’t processed your initial payment of  ${CartTotal} yet, we will amend it for you and add ${offerPrice} to your current order if you’d accept this top up offer now.
                                </span>
                            </div>
                        </small><br/>
                        <small>By clicking accept, you consent to using the same payment card for 
                        the amount below.</small>
                    </div>
                </div>
                <div class="button-container clearfix col-lg-12 col-sm-12 col-md-12 col-xs-12">
                    <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>
                    <a href="#" class="no-thanks cancel col-lg-6 col-md-6 col-sm-6 col-xs-12 hidden-xs text-left" id="noThanks">No thanks. Not interested</a>
                    <button class="btn btn-primary paynow col-lg-6 col-sm-6 col-md-6 col-xs-12 ${(offerPrice === '£0.00') ? 'buttonpadding' : ''}" id="signUp" data-salesprice="16.01">
                    ${(offerPrice === '£0.00') ? `Claim FREE Unlimited delivery for a year` :`Yes please. Add Unlimited to my order`}
                    </button>
                    <a href="#" class="no-thanks cancel col-xs-12 visible-xs" id="noThanks-mob">No thanks. Not interested</a>
                </div>
            </div>
        </div>
    `)
}

export var templateForFTC = (offerItems, availableSpaces) => {
    const brandObj = getBrand();
    const transactionTotal = (dataLayer[0].transactionTotal !== undefined && dataLayer[0].transactionDiscount !== undefined) ? (dataLayer[0].transactionTotal - dataLayer[0].transactionDiscount) : 0;
    const CartTotal = brandObj.id !== 'hsb' ? "£"+transactionTotal : transactionTotal+'pts';
    let offerWines, salePrice, selectedWines, bannerImage = [];
    var extraPay = 0;
    var wineListToShow = [];
    offerWines = offerItems.map((wine) => {
        if((wine.ratingDetails && wine.ratingDetails.productRating)){
            let avgRating = wine.ratingDetails.productRating.avgRating;
            var rating = '';
            let isFloat = (Number(avgRating) === avgRating && avgRating % 1 !== 0);
            for (var i = 1; i <= parseInt(avgRating); i++) {
                rating += '<i class="fa fa-star"></i>';
            }
            if (isFloat){
                rating += '<i class="fa fa-star fa-star-half-full"></i>'
            }
            for(var j = parseInt(avgRating) ; j<4 ; j++){
                rating += '<i class="fa fa-star fa-star-o"></i>';
            }
            var div = document.createElement('div');
            div.innerHTML = rating+=` <span>${wine.ratingDetails.productRating.avgRating}/5 </span>`
        }
        
        wine.skus.map(price => {
            price.vppPrice ?
            salePrice = (price.vppPrice < price.listPrice) ? 
            (`<strike>£${price.listPrice}</strike> <span><strong>£${price.vppPrice}</strong> each</span>`) :
           (`<span><strong>£${price.vppPrice}</strong> each</span>`)
            :
            salePrice = (price.salePrice < price.listPrice) ? 
             (`<strike>£${price.listPrice}</strike> <span><strong>£${price.salePrice}</strong> each</span>`) :
            (`<span><strong>£${price.salePrice}</strong> each</span>`)
        });
        return(`
            <div class="col-md-4 col-xs-6 offer-list">
                <div class="offer-wine">
                    <div class="wine-header">
                        <p>${wine.name} <span>${wine.vintage}</span></p> 
                        ${rating !== undefined ? rating :''}
                    </div>
                    <div class="white-container">
                        <img alt="offer-image" class="offer-bottle" onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${wine.smallImage}" />
                        <div class="wine-details">
                            ${salePrice}<br />
                            <span>${wine.styleName}</span>
                            <span>${wine.grapeName}</span>
                            <span>${wine.countryName}</span>
                            <div class="quantity-section">
                                <input aria-label="quanity" type="tel" pattern="^[0-9]\d*$" 
                                class="form-control text-center quantity" onkeypress="return /[0-9]d*$/i.test(event.key)" maxlength="2" id="qty" data-item="${wine.itemCode}"
                                value="${wine.qty}" onpaste="return false" onfocus="if (this.value == '0') {this.value = '';}" onblur="if (this.value=='') {this.value = '0';}">
                                <span>Quantity</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `);
    }).join(' ');
    offerItems.map((wine) => {
        let itemDetails ={};
        if(wine.qty > 0){
            itemDetails["wine"] = wine;
            itemDetails["quantity"] = wine.qty;
            wineListToShow.push(itemDetails);
        }
    });
    selectedWines = wineListToShow.map((wine) => {
        var wineList = wine.wine;
        var qty = wine.quantity;
        wineList.skus = wineList.skus.filter((item) => wine.wine.itemCode === item.itemCode);
        wineList.skus.map(price => {
            extraPay+= (price.vppPrice) ? parseFloat(price.vppPrice*qty) : parseFloat(price.salePrice*qty) ;
        });
        return (`
            <div class="bg-pale col-lg-12 wineList">
                <div class="col-xs-9 col-sm-9 col-lg-9 col-md-9 no-pad">
                    <p class="wine-text">${wineList.name} ${wineList.vintage}</p>
                    ${wineList.skus.map(price => {
                        if(price.vppPrice ? price.vppPrice < price.listPrice : price.salePrice < price.listPrice ){
                            return (`<strike class="wine-text pull-left">£${price.listPrice}</strike><span class="pull-left">&nbsp;</span><span class="wine-text pull-left">Qty: ${qty}`)
                        }else{
                            return(`<span class="wine-text pull-left">Qty: ${qty}`)
                        }
                    })}
                </div>
                <div class="col-xs-3 col-sm-3 col-lg-3 col-md-3 no-pad">
                    ${wineList.skus.map(price => {
                        return(`<p class="wine-text pull-right">£${price.vppPrice ? (price.vppPrice*qty).toFixed(2) : (price.salePrice*qty).toFixed(2) }</p>`)
                    })}
                </div>
            </div>
        `)
    }).join(' ');
    extraPay = extraPay.toFixed(2);

    bannerImage = offerItems.map((wine) => {
        if(wine.qty > 0 ){
            return (
                `<img alt="offer-image" onerror="this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" class="offer-bottle" src="${wine.smallImage}" />`)
        }
    }).join(' ');

    return (`
        <div>
            <div class="row no-pad">
                <div class="col-xs-12">
                    <p class="deal-text">Great news! ${availableSpaces === 1 ? 'There is a spare space in your case' : 'There are still some empty spaces in your case'} - <br/>
                    we’ve selected ${availableSpaces === 1 ? 'a popular bottle' : 'some popular bottles'} below with an additional discount already included.</p>
                    <p class="deal-text">Top up now to enjoy these extra savings:</p>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 text-center">
                    <div class="banner-badges">
                        <div class="circular-div">
                            <strong>+ ${availableSpaces}</strong> ${availableSpaces == 1 ? 'bottle' : 'bottles'}
                        </div>
                    </div>
                    ${bannerImage}
                </div>
                <div class="selected-items active hidden-xs col-lg-6 col-md-6 col-xs-6 no-pad-left">
                    <div class="col-md-12 col-xs-12 no-pad-left">
                        <div class="bg-pale total-items col-lg-12">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p>CURRENT ORDER </p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad text-right">
                                <span class="detail">
                                    ${CartTotal}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 no-pad-left">
                        <p class="unlimited-header">Top up Offer</p>
                        ${selectedWines}

                        <div class="bg-pale col-lg-12 offer-total">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p>EXTRA TO PAY </p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad text-right">
                                <span class="detail">
                                    £${extraPay}
                                </span>
                            </div>
                        </div>
                    </div>
                    <small class="col-xs-12 mt-10">Additional info 
                        <div class="tooltip" data-toggle="tooltip" data-placement="right">
                            <span class="info-circle"><i class="fa fa-info fa-lg"></i></span>
                            <span class="tooltiptext">
                                We haven’t processed your initial payment of  ${CartTotal} yet. If you accept this bonus deal, we will automatically add £${extraPay} to the total of your current order.
                            </span>
                        </div>
                    </small><br />
                </div>
                <div class="clearfix"></div>
                <div class="preferences">
                    <button class="accordion">Would you like to swap to different wines? <i class="fa fa-chevron-down pull-right" aria-hidden="true"></i></button>
                    <div class="panel col-md-12 col-xs-12 no-pad">
                        <p class="col-xs-12 col-lg-12 accord-text">Please select ${availableSpaces === 1 ? '1 bottle' : `between 1 and ${availableSpaces} new bottles`}. Save when you are happy with your selection.</p>
                        ${offerWines}
                        <div class="col-xs-12 col-md-8 col-md-offset-2 text-center btn-section">
                            <button class="btn btn-primary col-xs-12 col-md-12" id="saveWines">Save wines</button>
                            <a href="#" class="text-center" id="discard">Discard wines</a>
                        </div>
                    </div> 
                </div>
                <div class="clearfix"></div>
                <div class="selected-items active only-mobile-view">
                    <div class="col-md-12 col-xs-12">
                        <div class="bg-pale total-items col-lg-12">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p>CURRENT ORDER </p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad text-right">
                                <span class="detail">
                                    ${CartTotal}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12">
                        <p class="unlimited-header">Top up Offer</p>
                        ${selectedWines}

                        <div class="bg-pale col-lg-12 offer-total">
                            <div class="col-xs-9 col-sm-8 col-lg-8 col-md-8 no-pad">
                                <p>EXTRA TO PAY </p>
                            </div>
                            <div class="col-xs-3 col-sm-4 col-lg-4 col-md-4 no-pad text-right">
                                <span class="detail">
                                    £${extraPay}
                                </span>
                            </div>
                        </div>
                        <small class="">Additional info 
                            <div class="tooltip" data-toggle="tooltip" data-placement="right">
                                <span class="info-circle"><i class="fa fa-info fa-lg"></i></span>
                                <span class="tooltiptext">
                                We haven’t processed your initial payment of  ${CartTotal} yet. If you accept this bonus deal, we will automatically add £${extraPay} to the total of your current order.
                                </span>
                            </div>
                        </small><br />
                    </div>
                </div>
                <div class="col-md-12 col-xs-12 no-pad button-div">
                    <div class="col-lg-7 col-lg-offset-5 col-sm-offset-5 col-sm-7 col-sm-offset-5 col-md-7 col-xs-12 mt-15">
                        <span class="check-box"><input type="checkbox" id="futurePayments" name="futurePayments"></span>
                        <label for="futurePayments" class="text-left mt-10"><small>By clicking accept, you consent to using the same payment card</small></label>
                    </div>
                    <div class="button-container clearfix col-lg-12 col-sm-12 col-md-12 col-xs-12">
                        <div id="error-container" class="alert alert-danger" role="alert" style="display:none"></div>
                        <a href="#" class="no-thanks cancel col-lg-7 col-md-7 col-sm-6 col-xs-12 hidden-xs text-right" id="noThanks">No thanks, I'll keep my current order</a>
                        <button class="btn btn-primary paynow col-lg-5 col-sm-6 col-md-5 col-xs-12" id="signUp" data-salesprice="${extraPay}">
                            I accept the offer. Pay £${extraPay} now
                        </button>
                        <a href="#" class="no-thanks cancel col-xs-12 visible-xs" id="noThanks-mob">No thanks, I'll keep my current order</a>
                    </div>
                </div>
            </div>
        </div>
    `)
}
export const UnlimitedLearnMore = () => {
    const _brandObj = getBrand(); // Fetching brand object inside the component
    const termsLink = _brandObj.id === 'law' || _brandObj.id === 'bbc'
                        ? '/customer-services/customer-terms-and-conditions-of-sale-and-use#terms-and-conditions'
                        : '/customer-services/terms-and-conditions-of-sale-and-use#terms-and-conditions';

    return (`
        <div class="learn-more">
            <p>You are agreeing to us storing your credit or debit card</p>
            <p>We will only use the stored card details to make it easier for you to buy our products or services in the future; and to allow us to charge your card for your future Wine Plan or subscription payments.</p>
            <div class="table-responsive">
                <table class="table table-striped table-condensed">
                <thead>
                    <tr>
                        <th>Your Subscription </th>
                        <th>Initial Payment</th>
                        <th>Next Payment</th>
                        <th>Next Delivery Price</th>
                        <th>Frequency</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Unlimited</td>
                        <td>£29.99</td>
                        <td>£29.99</td>
                        <td>£0.00</td>
                        <td>12 Months</td>
                    </tr>
                </tbody>
                </table>
            </div>
            <p>You can cancel your subscription at any time and, in the case of a wine plan, you can miss a delivery and not be charged. All our products are covered by our 100% money back quality guarantee. You can find out more at <a href="${termsLink}" target="_blank">laithwaites.co.uk/terms</a>.</p>
            <p>We shall let you know if we make any amendments to the cost or frequency of your Wine Plan or subscription. This agreement will expire at the time that you choose to cancel your Wine Plan or subscription with us. If the card you have stored with us expires, this agreement will apply to any replacement card. We will notify you by email or post of any major changes to this agreement.</p>
            <p>We are Direct Wines Limited (trading as Laithwaites) and are located at One Waterside Drive, Arlington Business Park, Theale, RG7 4SW.</p>
        </div>
    `)
}