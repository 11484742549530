import Component from "../component";
import request from '../../utilities/request';
import Modal from "../modal/modal";

class ChristmasOffer extends Component {
    constructor(itemcode, openModal, qualifyingItems) {
        super();
        const qualifyingItemArr = qualifyingItems.split(",").map(item => item.trim());
        const itemsCodesForAPI = {
            ids: qualifyingItemArr
        }
        this.allCaseData = [];
        this.getAllQualifyingCase(itemsCodesForAPI);
        setTimeout(() => {
            this.getCase(itemcode, openModal);
        },100);
    }
    getCase(itemcode, openModal) {
        async function getUserAsync(itemcode) {
            let response = await request.product.case({
                itemcode,
            });
            let data = await response.json()
            return data;
        }

        getUserAsync(itemcode)
            .then(data => {
                this.caseContentsTemplate(data.response, openModal);
            })
            .catch((e) => {
                // we've errored
                console.log('error', e);
            });
    }

    getAllQualifyingCase(qualifyingItems){
        const getWines = async ({ ids }) => {
            // Loop over each item in the array and
            // and fetch the data from the API
            const wines = ids.map(async id => {
                const data = await request.product.case({
                    itemcode: id
                }).then(res => res.json()).then((json) => {
                    return json;
                }).then((json) => {
                    // if the response is in range
                    if (json.statusCode >= 400 && json.statusCode < 600) {
                        throw json;
                    }
                    return json.response;
                }).catch((e) => {
                    // we've errored
                    console.log('error', e.errorResponse.message, `for item ${id}`);
                    return {
                        error: true,
                        msg: e.errorResponse.message
                    };
                });
                return data;
            });

            // Once the loop is finshed resolve the promise
            return Promise.all(wines);
        }

        // Start a async function to get all the API data
        // passed in from our array
        (async () => {
            const wineDataForUI = await getWines(qualifyingItems);
            // Update the recruit wines array with all the data
            // from the API
            this.allCaseData = wineDataForUI;
        })();
    }

    caseContentsTemplate(data, openModal) {
        let template = [];
        let winesincase = [];
        let rating = [];
        let parentItemCode =  data?.parentProduct?.itemCode;
        let parentName = data?.parentProduct?.name;
        winesincase = data.contentProducts.map(wine => {
            if (wine.referencedProduct.ratingDetails && wine.referencedProduct.ratingDetails.productRating) {
                rating = (
                    `<span class="stars stars-small rating-${wine.referencedProduct.ratingDetails.productRating.roundAvgRating}Star"></span>`
                )
            }
            
            const grapeName = wine.referencedProduct.grapeName ? (
                `<li>
                    <div class="icons">
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 24 24">
                            <g fill="#333" fill-rule="evenodd">
                                <path d="M12.356 10.355a.959.959 0 0 1-.952-.963c0-.531.427-.963.952-.963.525 0 .953.432.953.963a.959.959 0 0 1-.953.963zm-.938-5.526c-.775.55-1.818.55-2.594 0l-.05-.036c.822-.609 1.844-.613 2.669-.01l.02.014-.045.032zm1.866 2.52a.515.515 0 0 1-.297-.468V4.788h-.002a1.06 1.06 0 0 0-.431-.841l-.338-.247c-1.285-.94-2.942-.933-4.222.016l-.314.233a1.06 1.06 0 0 0 .018 1.712l.368.262a3.549 3.549 0 0 0 3.22.457c.21-.073.43.081.43.306v.2c0 .2-.115.381-.294.465a2.255 2.255 0 0 0-1.283 2.217c.084 1.098.96 1.984 2.046 2.066 1.303.1 2.395-.945 2.395-2.242 0-.906-.532-1.687-1.296-2.044zM9.743 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M12.373 16.848a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207A2.21 2.21 0 0 1 12.373 20M15.097 12.621a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207M17.445 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152a2.21 2.21 0 0 1-2.207-2.207 2.21 2.21 0 0 1 2.207-2.207 2.21 2.21 0 0 1 2.207 2.207 2.21 2.21 0 0 1-2.207 2.207"></path>
                                <path d="M7.207 8.395a.946.946 0 0 0 0 1.89.946.946 0 0 0 0-1.89m0 3.152A2.21 2.21 0 0 1 5 9.34a2.21 2.21 0 0 1 2.207-2.207A2.21 2.21 0 0 1 9.414 9.34a2.21 2.21 0 0 1-2.207 2.207"></path>
                            </g>
                        </svg>
                    </div>
                    <div class="text">${wine.referencedProduct.grapeName}</div>
                </li>`
            ) : '';

            const countryName = wine.referencedProduct.countryName ? (
                `<li>
                    <div class="icons">
                        <img src="/images/uk/en/common/icons/flags/${wine.referencedProduct.countryName}.png" class="js-default-image-handler" alt="${wine.referencedProduct.countryName}" title="${wine.referencedProduct.countryName}" />
                    </div>
                    <div class="text">${wine.referencedProduct.countryName}</div>
                </li>`
            ) : '';

            return (
                `
                <div class="item">
                    <div style="display: flex">
                        <div class="image">
                        <img onerror=this.onerror=null;this.src='/images/uk/en/law/default_images/default_bottle.png';" src="${wine.referencedProduct.smallImage}" /></div>
                        <div class="detail">
                            <h4>${wine.referencedProduct.name} ${wine.referencedProduct.vintage ? wine.referencedProduct.vintage : ''}</h4>
                            ${rating}
                            ${wine.referencedProduct.productType === 'wine' ? (`<p>x ${wine.quantity} ${wine.quantity > 1 ? 'bottles' : 'bottle' }</p>`) :
                            (`<p>x ${wine.quantity}</p>`) }
                            <ul class="detail-table">
                                ${grapeName}
                                ${countryName}
                            </ul>
                            <p>${wine.referencedProduct.webHeadline}</p>
                        </div>
                    </div>
                </div>
                `
            )
        }).join(' ');
        let buttonTabs = this.allCaseData?.map(item => {
            if(item.parentProduct.itemCode !== parentItemCode){
                return (`<li class="col-sm-4 col-xs-12 "><a aria-label="deal your case" data-itemcode="${item.parentProduct.itemCode}" data-name=${item.parentProduct.name} name="case-deal" class="btn nav-item nav-link deals" aria-controls="home" aria-selected="true">
                    ${item.parentProduct.name.includes('Essential') ? 'Christmas Essentials' :
                        item.parentProduct.name.includes('Collection') ? 'Christmas Collections' :
                        item.parentProduct.name.includes('Showcase') ? 'Christmas Showcase' :  item.parentProduct.name
                    }
                </a></li>`)
            }
        }).join(' ');
        template = (`
            <div>
             <div class="selection-container">
                    <div class="pick-white-section">
                        <ul class="nav nav-tabs" id="myTab" role="tablist">
                            <li class="col-sm-4 col-xs-12 no-pad-left">
                            ${parentItemCode ?
                                `<a aria-label="deal your case" data-itemcode="${parentItemCode}" name="case-deal" class="btn nav-item nav-link active deals" aria-controls="home" aria-selected="true">
                                ${parentName.includes('Essential') ? 'Christmas Essentials' :
                                    parentName.includes('Collection') ? 'Christmas Collections' :
                                    parentName.includes('Showcase') ? 'Christmas Showcase' : parentName
                                }
                                </a>` : '' }
                            </li>
                                ${buttonTabs}
                        </ul>
                        <div class="clear"></div>
                    </div>
                </div> 
                <div id="nav-tabContent">
                    <div id="modal-wines-in-case" class="case-container tab-pane fade active in" role="tabpanel" aria-labelledby="nav-case-tab">
                        ${winesincase}
                    </div>
                </div>
            </div>
        `);

        // Trigger the modal or update the modal
        // based on the 'openModal' param
        if (openModal) {
            this.modal = new Modal({
                title: "CASE CONTENTS",
                body: template,
                containsForm: true,
                showFooter: false,
                largeModal: true,
                id: 'christmas-case-contents',
            });

            // Open the modal
            this.modal.open();
            this.modalActions(this.modal);
        } else {
            this.modal.el.find('.modal-body').classList.add('loading');
            // this.modal.el.find('.modal-title').innerHTML = data.parentProduct.name;
            this.modal.el.find('#case-webheadline').innerHTML = data.parentProduct.webHeadline ? data.parentProduct.webHeadline : '';
            this.modal.el.find('#modal-wines-in-case').innerHTML = winesincase;
        }
    }
    modalActions(modal)  {
         // Lets set the correct case to the selected option
        Array.from(document.querySelectorAll('.deals')).forEach((btn) => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                const itemCode = e.target.getAttribute('data-itemcode');
                $('.deals').removeClass('active');
                e.target.classList.add('active');
                const data = this.allCaseData?.filter(item =>{
                    return item.parentProduct.itemCode === itemCode;
                });
                this.caseContentsTemplate(data[0], false);
                modal.el.find('.modal-body').classList.remove('loading');
            });
        });
    }
}
export default ChristmasOffer;