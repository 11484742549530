import cellDataToTetrisData from '../../../utilities/contentful/tetrisCellTransformer';
import cellTwoByOne from '../cell/cellTwoByOne';

class TetrisLayoutThree {
  constructor(elementId, cellData) {
    this.id = elementId;
    this.data = cellData;
  }

  /**
   * Organises the tetris cells into the correct blocks for this layout
   *
   * @return {String} => The html string which will be placed into the tetris element
   */
  render() {
    const cellCount = this.data.length;
    if(cellCount !== 4) {
      console.warn(`tetrisLayoutThree.js: Incorrect number of cells. Expected 4 but received ${cellCount}`);
    }

    const [cellOne, cellTwo, cellThree, cellFour] = this.data.map((cellData) =>
      cellDataToTetrisData(this.id, cellData)
    );

    return `
      <div class="col-12 col-md-3 pr-md-3 pb-3 pb-md-0 mb-4 cell-container">
        ${cellTwoByOne({ ...cellOne })}
      </div>
      <div class="col-12 col-md-3 pr-md-3 pb-3 pb-md-0 mb-4 cell-container">
        ${cellTwoByOne({ ...cellTwo })}
      </div>
      <div class="col-12 col-md-3 pr-md-3 pb-3 pb-md-0 mb-4 cell-container">
        ${cellTwoByOne({ ...cellThree })}
      </div>
      <div class="col-12 col-md-3 pb-3 pb-md-0 mb-4 cell-container">
        ${cellTwoByOne({ ...cellFour })}
      </div>
    `;
  }
}

export default TetrisLayoutThree;
