import Component from '../component';
import TetrisLayoutOne from './layouts/tetrisLayoutOne';
import TetrisLayoutTwo from './layouts/tetrisLayoutTwo';
import TetrisLayoutThree from './layouts/tetrisLayoutThree';
import TetrisLayoutFour from './layouts/tetrisLayoutFour';
import { pageInteractionDl } from 'dw-global/apps/dataLayer';

class Tetris extends Component {
  constructor(elementId, tetrisData) {
    super();
    this.render(elementId, tetrisData);

    Array.from(document.querySelectorAll('.DLclick')).forEach((btn) => {
      this.addEvent(btn, 'click', this.pageInteraction.bind(this, btn));
    });
  }

  pageInteraction(element){
		pageInteractionDl(element);
	}

  /**
   * Initialises the layout of the tetris and changes
   * the inner HTML of any tetris component
   *
   * @param {String} elementId => The id of the tetris block to change
   * @param {Object} data => The data from contentful
   */
  render(elementId, data) {
    if (!data) {
      console.warn('Tetris.js: No data supplied.');
      return;
    };

    const {
      title,
      tetrisCells: cellData,
      layout: layoutType,
    } = data;

    const tetrisLayout = parseInt(layoutType.match(/(?:Layout )(\d)/)[1]);

    let layout;
    switch(tetrisLayout) {
      case 1: {
        layout = new TetrisLayoutOne(elementId, cellData);
        break;
      }
      case 2: {
        layout = new TetrisLayoutTwo(elementId, cellData);
        break;
      }
      case 3: {
        layout = new TetrisLayoutThree(elementId, cellData);
        break;
      }
      case 4: {
        layout = new TetrisLayoutFour(elementId, cellData);
				break;
      }
      default: {
        console.warn('Tetris.js: Non-standard layout passed through: Layout', tetrisLayout);
        break;
      }
    }

    
    if (!layout) return;
    
    const tetrisFormation = layout.render();

    const topTetrisId = 'tetris-top-section';
    const topTetrisClass = 'top-tetris';
    const isTopTetris = topTetrisId == elementId ? topTetrisClass : '';

		/**
		 * If a tetris formation turns out to be an empty string
		 * because the content creator didn't include enough cells
		 * the tetris section won't display on the page
		 */
		if (tetrisFormation) {
        const tetris = `
          <section class="media-content ${isTopTetris}">
            <div class="container p-md-0">
              <div class="row">
                <div class="col-12">
                  <h2>${title}</h2>
                </div>
              </div>
              <div class="row no-gutters content">
                ${tetrisFormation}
              </div>
            </div>
          </section>
        `;
        const element = document.getElementById(elementId);
        element.innerHTML = tetris;
      };
		};
  };

export default Tetris;
