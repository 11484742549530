import Model from "../model";
import { createClient } from "contentful";

const API_SPACE_ID = process.env.CONTENTFUL_PRODUCTION_SPACE_ID;
const API_TOKEN = process.env.CONTENTFUL_PRODUCTION_ACCESS_TOKEN;
const API_ENV = process.env.CONTENTFUL_PRODUCTION_ENVIRONMENT;
const API_PREVIEW_TOKEN = process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN;

/**
 * @class Integration with Contentful
 */
class ContentfulModel extends Model {
  /**
   * List of actions associated with this model. They can be accessed via
   * `Contentful.actions.....`.
   *
   * @return {Object}
   */
   get actions() {
    return {
      FETCH_START: "contentful::fetch-start",
      FETCH_SUCCESSFUL: "contentful::fetch-successful",
      FETCH_FAILURE: "contentful::fetch-failure",
      FETCH_START_BY_QUERY: "contentful::fetch-start-by-query",
      FETCH_SUCCESSFUL_BY_QUERY: "contentful::fetch-successful-by-query",
      FETCH_FAILURE_BY_QUERY: "contentful::fetch-failure-by-query",
    };
  }

  getContentfulClient() {
    return createClient({
      space: API_SPACE_ID,
      accessToken: API_TOKEN,
      environment: API_ENV || 'master',
    });
  }

  getContentfulPreviewClient() {
	return createClient({
		space: API_SPACE_ID,
		accessToken: API_PREVIEW_TOKEN,
		environment: API_ENV,
		host: 'preview.contentful.com',
	})
  }

  fetchData(id) {
    const {
      actions: { FETCH_START, FETCH_SUCCESSFUL, FETCH_FAILURE },
    } = this;

    const query = {
      include: 6,
      "fields.brand": id,
      content_type: "homePage",
    };

    this.dispatch(FETCH_START);
    this.getContentfulClient()
    .getEntries(query)
    .then((entries) => {
      const pages = entries.items;
      const page = pages.reduce((latestPage, currentPage) => latestPage.sys.updatedAt > currentPage.sys.updatedAt ? latestPage : currentPage );

      this.dispatch(FETCH_SUCCESSFUL, page.fields);
    })
    .catch((err) => this.dispatch(FETCH_FAILURE, err));
  }

  fetchPreview(id) {
	const {
		actions: { FETCH_START, FETCH_SUCCESSFUL, FETCH_FAILURE },
	} = this;

	const query = {
		include: 6,
		"sys.id": id,
	};

	this.dispatch(FETCH_START);
    this.getContentfulPreviewClient()
    .getEntries(query)
    .then((entries) => {
      const pages = entries.items;
      const page = pages.reduce((latestPage, currentPage) => latestPage.sys.updatedAt > currentPage.sys.updatedAt ? latestPage : currentPage );

      this.dispatch(FETCH_SUCCESSFUL, page.fields);
    })
    .catch((err) => this.dispatch(FETCH_FAILURE, err));
  }

  fetchByQuery(query) {
    const {
      actions: { FETCH_START_BY_QUERY, FETCH_SUCCESSFUL_BY_QUERY, FETCH_FAILURE_BY_QUERY },
    } = this;

    this.dispatch(FETCH_START_BY_QUERY);
    this.getContentfulClient()
      .getEntries(query)
      .then((entries) =>
        this.dispatch(FETCH_SUCCESSFUL_BY_QUERY, entries)
      )
      .catch((err) => this.dispatch(FETCH_FAILURE_BY_QUERY, err));
  }
}
export default new ContentfulModel();
