
import request from '../../utilities/request';
import event from "../../utilities/event";
import Cart from '../../models/cart/cart';

export const addUpsellAndBottlesToCart = async (upsellData, itemData) => {
    let cartItems = [{
        itemCode: upsellData.itemCode,
        quantity: 1,
        upsellOfferDetails: {
            type: upsellData.type,
            rule: upsellData.rule
        },
    }, {
        itemCode: itemData.itemCode,
        quantity: itemData.mixed ? 1 : 6,
    }];
    // Call the Cart modal to submit the items to basket
    Cart.addBatchItems(cartItems);
}

export const addUpsellOnlyToCart = async (upsellData) => {
    let cartItems = [{
        itemCode: upsellData.itemCode,
        quantity: 1,
        upsellOfferDetails: {
            type: upsellData.type,
            rule: upsellData.rule
        },
    }];

    // Call the Cart modal to submit the items to basket
    Cart.addBatchItems(cartItems);
}

export const addUpsellToCart = async (upsellData) => {
    let cartItems = [{
        itemCode: upsellData.itemCode,
        quantity: 1,
        upsellOfferDetails: {
            type: upsellData.type,
            rule: upsellData.rule
        },
    }];

    return request.cart.batch({
        cartItems
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        window.location.reload();
    }).catch((e) => {
        console.log(e.message);
        event('Errors', 'Cart Upsell', e.message);
        const errorContainer = document.getElementById('error-container');
        errorContainer.innerHTML = e.message;
        errorContainer.style.display = "block";
    });
}

export const getWinePlanSchemeData = async (schemeId) => {
    return request.wineplan.getScheme({
        schemeId
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        return res.response.response;
    }).catch((error) => {
        console.log(error.message);
        event('Errors', 'Cart Upsell', error.message);
    });
}

export const getItem = async (itemcode) => {
    return request.product.get({
        itemcode
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        if (res.response.skus === undefined || res.response.skus.length === 0) {
            throw new Error(`Upsell: No skus have been setup for the required upsell item - ${itemcode}`);
        }
        return res.response;
    }).catch((error) => {
        console.log(error.message);
        event('Errors', 'Upsell', error.message);
    });
}

export const getFillTheCaseItem = async (itemcode) => {
    return request.product.get({
        itemcode
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        return res.response;
    }).catch((error) => {
        console.log(error.message);
        event('Errors', 'Upsell', error.message);
    });
}

export const getUpsellForProduct = async (itemcode) => {
    return request.offer.productUpsell({
        itemcode
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        if (res && res.response && res.response?.UpsellOffers?.offerItems) {
            return res.response.UpsellOffers.offerItems;
        }
    }).catch((error) => {
        console.log('Error', error);
        event('Errors', 'Upsell', error);
    });
}

// Post Upsell requests
export const getUpsellForCart = async () => {
    return request.offer.cartUpsell().then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        if (res && res.response && res.response?.UpsellOffers?.offerItems) {
            return res.response.UpsellOffers.offerItems;
        }
    }).catch((error) => {
        console.log('Error', error);
        event('Errors', 'Cart Upsell', error);
    });
}

export const rejectUpsellToOrder = async (transactionId) => {
    let cartItems = {
        orderId: transactionId,
        action: "REJECT"
    }

    return request.order.amendAndCommit(cartItems).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        window.location.reload();
    }).catch((e) => {
        console.log(e.message);
        event('Errors', 'Post Upsell', e.message);
        const errorContainer = document.getElementById('error-container');
        errorContainer.innerHTML = e.message;
        errorContainer.style.display = "block";
    });
}

export const addUpsellToOrder = async (transactionId, upsellData) => {
    let cartItems = {
        orderId: transactionId,
        action: "ACCEPT",
        itemDetails: [
            {
                itemCode: upsellData.itemCode,
                quantity: 1,
                upsellOfferDetails: {
                    type: upsellData.type,
                    rule: upsellData.rule
                }
            }
        ],
        cardPermission: {
            category: "PAYMENT",
            state: "GRANTED",
            code: "COF"
        }
    }

    return request.order.amendAndCommit(cartItems).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        window.location.reload();
        sessionStorage.setItem('PostUpsellRejected', 'true');
    }).catch((e) => {
        console.log(e.message);
        event('Errors', 'Post Upsell', e.message);
        const errorContainer = document.getElementById('error-container');
        errorContainer.innerHTML = e.message;
        errorContainer.style.display = "block";
    });
}

export const addFTCToOrder = async (transactionId, upsellData) => {
    let cartItems = {
        orderId: transactionId,
        action: "ACCEPT",
        itemDetails: upsellData,
        cardPermission: {
            category: "PAYMENT",
            state: "GRANTED",
            code: "COF"
        }
    }

    return request.order.amendAndCommit(cartItems).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            event('Pop-ups', 'Post Upsell FTC', 'Post Upsell FTC added to order');
            throw new Error(res.errorResponse.message);
        }
        window.location.reload();
    }).catch((e) => {
        console.log(e.message);
        event('Errors', 'Post Upsell', e.message);
        const errorContainer = document.getElementById('error-container');
        errorContainer.innerHTML = e.message;
        errorContainer.style.display = "block";
    });
}

export const getUpsellForConfirmationPage = async (transactionId) => {
    return request.order.offer({
        orderId: transactionId
    }).then((res) => res.json()).then((res) => {
        if (res.statusCode !== 0) {
            throw new Error(res.errorResponse.message);
        }
        if (res && res.response && res.response?.UpsellOffers?.offerItems) {
            return res;
        }
    }).catch((error) => {
        console.log('Error', error);
        event('Errors', 'Cart Upsell', error);
    });

    // const res = {
    //     "statusMessage": "successful",
    //     "statusCode": 0,
    //     "response": {
    //         "UpsellOffers": {
    //             "offerItems": [{
    //                 "type": "SUBSCRIPTION",
    //                 "itemCode": "00088SV",
    //                 "rule": "FreeShip"
    //               }]
    //         }
    //     }
    // }
    // return res;
}