import Component from '../component';
import Template from "./template.js";
import User from '../../models/user/user';

class HsbcOverlay extends Component {
	constructor() {
		super();
			this._hsbcOverlay = new Template();

			User.subscribe(User.actions.HSBC_COOKIECHECK_START, () => {
				// this.el.classList.add('loading');
			});
			User.subscribe(User.actions.HSBC_COOKIE_AVAILABLE,() => {
				this._hsbcOverlay.loadItemToModal();
			});
			User.subscribe(User.actions.HSBC_COOKIE_FAILURE, () => {
				console.log("HSBC_COOKIE_FAILURE");
			});
			User.hsbcCard();
	}
}
export default HsbcOverlay;







