import Component from "../component";
import deliveryPromiseText from 'dw-uk-common/src/components/deliveryOptions/deliveryMessage';


class StripBannerDeliveryMessage extends Component {
    constructor() {
        super('div.big-strip');

        this._template = this.el;
        this.bannerMessage = this._template.innerHTML;
        this.deliveryMsg = '';
        this.getdeliveryPromiseText();
    }

    async getdeliveryPromiseText() {
        let deliveryPromise =  await deliveryPromiseText().then(data => data);
        if (deliveryPromise && deliveryPromise.deliveryPromiseText) {
            this.deliveryMsg = deliveryPromise;
        } 
        this._template.innerHTML = (`
            <h3>${this.deliveryMsg !== '' ? this.deliveryMsg.deliveryPromiseText : this.bannerMessage}</h3>
        `)
        return deliveryPromise;
    }
}

export default StripBannerDeliveryMessage;